import React from 'react';
import './index.css';
import ADDRESS_INFO from './address_info';

function PARSE_FIRST_LETTER() {
    let result = [];
    ADDRESS_INFO.forEach(item => {
        result.push(item.firstLetter);
    });
    result.unshift("热门城市");
    return Array.from(new Set(result));
}

const FIRST_LETTER = PARSE_FIRST_LETTER();

export default class AddressChooser extends React.Component {
    static STATE_STRUCT = () => {
        return {
            currentSelectLetter: "热门城市",
        }
    };

    state = AddressChooser.STATE_STRUCT();

    getCitiesByLetter = () => {
        let result = [];
        ADDRESS_INFO.forEach((item) => {
            if (item.firstLetter === this.state.currentSelectLetter || (item.hotLevel && this.state.currentSelectLetter === "热门城市"))
                result.push(item);
        });
        if (this.state.currentSelectLetter === "热门城市")
            result.sort((a, b) => {
                if (a.hotLevel !== b.hotLevel)
                    return a.hotLevel > b.hotLevel ? 1 : -1;
                else
                    return parseInt(a.code) > parseInt(b.code) ? 1 : -1;
            });
        return result;
    };

    getSearchedCities = (keyWord) => {
        let result = [];
        if (keyWord === "")
            return result;
        ADDRESS_INFO.forEach((item) => {
            if (item.name.indexOf(keyWord) !== -1 || item.provinceName.indexOf(keyWord) !== -1)
                result.push(item);
        });
        return result;
    };

    render() {
        const ROOT_STYLE = {};
        let keyWord = this.props.keyWord;
        if (!keyWord)
            keyWord = "";
        console.log(keyWord);
        Object.assign(ROOT_STYLE, this.props.style, {
            width: 957,
            height: 271,
            backgroundColor: "rgba(249,249,249,1)",
            border: "1px solid rgba(37,168,160,1)"
        });
        return (
            <div style={ROOT_STYLE}>
                {/*<input placeholder="直接输入可搜索城市" value={this.state.keyWord} onChange={(e) => this.setState({keyWord: e.target.value})} style={{width: 723, margin: "0 auto", outline: "none", display: "block", marginTop: 23, fontSize: 16, paddingTop: 8, paddingBottom: 8, paddingLeft: 20, paddingRight: 20,*/}
                {/*    border: "none", borderLeft: "1px solid rgba(240,240,240,1)", borderRight: "1px solid rgba(240,240,240,1)", borderTop: "1px solid rgba(240,240,240,1)", borderBottom: this.state.keyWord === "" ? "none" : "1px solid rgba(240,240,240,1)"}}/>*/}
                <div className="letterSelect" style={{
                    width: 893,
                    margin: "0 auto",
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    background: "white",
                    position: "absolute",
                    left: 32,
                    top: 20,
                    border: "1px solid rgba(240,240,240,1)",
                    fontSize: 18,
                    display: keyWord === "" ? "block" : "none"
                }}>
                    {
                        FIRST_LETTER.map((item) => <span onClick={(e) => {
                            this.setState({currentSelectLetter: item});
                        }} style={{
                            backgroundColor: this.state.currentSelectLetter === item ? "#25A8A0" : "white",
                            color: this.state.currentSelectLetter === item ? "white" : "#181A1A"
                        }}>{item}</span>)
                    }
                </div>
                <div className="address" style={{
                    width: 893,
                    margin: "0 auto",
                    marginTop: 20,
                    display: keyWord === "" ? "block" : "none",
                    position: "absolute",
                    left: 32,
                    top: 55
                }}>
                    {
                        this.getCitiesByLetter().map(item => <span
                            onClick={(e) => this.props.handleData ? this.props.handleData(item) : null} style={{
                            display: "block",
                            float: "left",
                            padding: "5px 10px",
                            cursor: "pointer",
                            fontSize: 15
                        }}>{item.name}</span>)
                    }
                </div>
                <div className="address"
                     style={{width: 893, margin: "0 auto", marginTop: 20, display: keyWord !== "" ? "block" : "none"}}>
                    {
                        this.getSearchedCities(keyWord).map(item => <span
                            onClick={(e) => this.props.handleData ? this.props.handleData(item) : null} style={{
                            display: "block",
                            float: "left",
                            padding: "5px 10px",
                            cursor: "pointer",
                            fontSize: 15
                        }}>{item.name}</span>)
                    }
                </div>
            </div>
        );
    }
}
