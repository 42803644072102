import React from 'react';

import Head from "./component/head/head";

class AppV3 extends React.Component {
    static STATE_STRUCT = () => {
        return {
            currentPageIndex: 1,
            showSenderAddressChooser: false,
            senderAddress: {},
            showReceiverAddressChooser: false,
            receiveAddress: {},
            phone: "",
            showLoading: false,
            showModal: false,
            searchValueSender: "",
            searchValueReceive: "",
            currentFrontPage: 0,
            currentSafePage: 0,
            frontPageTimer: null,
            realTimeOrderTimer: null,
            realTimeOrderLeftData: [],
            realTimeOrderRightData: [],
            realTimeOrderLeftIndex: 0,
            realTimeOrderRightIndex: 0,
        }
    };

    state = AppV3.STATE_STRUCT();


    getRandomTime = () => {
        let number = this.getRandomNumberByRange(1, 10);
        return number <= 5 ? this.getRandomNumberByRange(1, 23) + "小时前" : this.getRandomNumberByRange(1, 59) + "分钟前";
    };

    getRandomNumberByRange = (start, end) => {
        return Math.floor(Math.random() * (end - start) + start)
    };

    render() {
        const APP_URL_ANDROID = "http://apk.app.carticletech.com/shipper/carticle-shipper-release.apk";
        const APP_URL_IOS = "https://apps.apple.com/cn/app/粒子运车-货主/id1471585819";

        return (
            <div>
                <div style={{width: 1920, marginLeft: "auto", marginRight: "auto"}}>
                    <Head></Head>
                </div>
            </div>
        )
    }
}

export default AppV3;
