import {api, getData} from "../request";


export function get_front_page_cases_2(url,callBack) {
    getData(url, {index: 0, size: 1000}).then(res => {
        if (res.code === 200) {
            let datas = res.data.data;
            let objs = []
            datas.forEach(data => {
                let obj = {};
                obj.title = data.title;
                obj.pageTitle = data.pageTitle;
                obj.meta = data.meta;
                obj.desc = data.desc;
                obj.content = data.content;
                obj.image = data.cover;
                obj.id = data.id;
                objs.push(obj);
            })
            callBack(true, "获取成功", objs);
        } else {
            callBack(false,)
        }
    })
}

export function get_front_page_cases(callBack) {
    getData(api.service_case_list, {index: 0, size: 1000}).then(res => {
        if (res.code === 200) {
            let datas = res.data.data;
            let objs = []
            datas.forEach(data => {
                let obj = {};
                obj.title = data.title;
                obj.pageTitle = data.pageTitle;
                obj.meta = data.meta;
                obj.desc = data.desc;
                obj.content = data.content;
                obj.image = data.cover;
                obj.id = data.id;
                objs.push(obj);
            })
            callBack(true, "获取成功", objs);
        } else {
            callBack(false,)
        }
    })
    //TODO:暂时返回假数据
    // callBack(true, "返回成功",
    //     [
    //         {
    //             image: 'https://car1.autoimg.cn/upload/spec/13452/1024x0_1_q95_autohomecar__20120723095341589264.jpg',
    //             title: '标题1',
    //             content: '内容1',
    //         },
    //         {
    //             image: 'https://m0.autoimg.cn/cardfs/upload/spec/11852/800x0_q87_autohomecar__y_20120127095328825264.jpg',
    //             title: '标题2',
    //             content: '内容2',
    //         },
    //         {
    //             image: 'https://m0.autoimg.cn/cardfs/upload/spec/8377/800x0_q87_autohomecar__y_20100822201620280264.jpg',
    //             title: '标题3',
    //             content: '内容3',
    //         },
    //         {
    //             image: 'http://img.08087.cc/uploads/20190312/17/1552384329-tEoYsFhxcB.jpg',
    //             title: '标题4',
    //             content: '内容4',
    //         },
    //     ]
    // );
}
