import Promise from 'es6-promise';
import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
import apiConfig from './api';

const TIMEOUTLIMIT = 120000;

function getUrlencodedStr(obj) {
    return queryString.stringify(obj);
}

const embedFetch = (requestPromise, timeout = TIMEOUTLIMIT) => {
    let timeoutAction = null;
    const timerPromise = new Promise((resolve, reject) => {
        timeoutAction = () => {
            reject(new Error('请求超时'));
        };
    });
    setTimeout(() => {
        timeoutAction();
    }, timeout);
    return Promise.race([requestPromise, timerPromise]);
};

function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else if (response.status === 401) { // 没权限，跳转登录页面
        window.location.href = '#login';
    }
    return Promise.reject(new Error(response.statusText));
}

function json(response) {
    return response.json();
}

export function getData(url, queryObj = {}) {
    const reqHeader = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json, text/plain, */*',
            clientInfo: 'Manage',
            Authorization: localStorage.getItem('Authorization')
        },
    };
    const myFetch = fetch(`${url}?${queryString.stringify(queryObj)}`, reqHeader);
    return new Promise((resolve, reject) => {
        embedFetch(myFetch, TIMEOUTLIMIT)
            .then(status)
            .then(json)
            .then((responseData) => {
                resolve(responseData);
            })
            .catch((error) => {
                console.warn('服务器连接错误1，请稍后再试');
                reject(error);
            });
    });
}

export function postJsonData(url, queryObj = {}) {
    const reqJson = {
        method: 'POST',
        body: JSON.stringify(queryObj),
        mode: 'cors',
        credentials: 'include',
        headers: {
            Accept: 'application/json, text/plain, */*',
            clientInfo: 'Manage',
            Authorization: localStorage.getItem('Authorization')
        },
    };
    reqJson.headers['Content-Type'] = 'application/json; charset=utf-8';

    const myFetch = fetch(url, reqJson);

    return new Promise((resolve, reject) => {
        embedFetch(myFetch, TIMEOUTLIMIT)
            .then(status)
            .then(json)
            .then((responseData) => {
                resolve(responseData);
            })
            .catch((error) => {
                console.warn('服务器连接错误2，请稍后再试');
                reject(error);
            });
    });
}

/**
 * POST 请求
 * Content-Type application/x-www-form-urlencoded
 * */
export function postUrlencodedData(url, queryObj = {}) {
    const reqJson = {
        method: 'POST',
        body: getUrlencodedStr(queryObj),
        mode: 'cors',
        credentials: 'include',
        headers: {
            Accept: '*/*',
            clientInfo: 'Manage'
        },
    };
    reqJson.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    const myFetch = fetch(url, reqJson);
    // const myFetch = fetch(`${url}?${queryString.stringify(queryObj)}`, reqJson);
    return new Promise((resolve, reject) => {
        embedFetch(myFetch, TIMEOUTLIMIT)
            .then(status)
            .then(json)
            .then((responseData) => {
                resolve(responseData);
            })
            .catch((error) => {
                console.warn('服务器连接错误3，请稍后再试');
                reject(error);
            });
    });
}

/**
 * 文件上传
 * */
export function fileUpload(formElement, cbk) {
    const xhr = new XMLHttpRequest();
    const formData = new FormData(formElement);
    xhr.upload.addEventListener('progress', (e) => {
        if (e.lengthComputable) {
            const percentage = Math.round((e.loaded * 100) / e.total);
            console.log('p: ', percentage);
        }
    }, false);

    xhr.upload.addEventListener('load', (e) => {
    }, false);
    xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
            let res;
            try {
                res = JSON.parse(xhr.responseText);
                cbk(res);
            } catch (err) {
                console.error(err);
            }
        }
    });
    xhr.open('POST', apiConfig.uploadFile);
    xhr.setRequestHeader('clientInfo', 'Manage');
    xhr.send(formData);
}

/**
 * 图片上传
 * */
export function uploadImg(fileEle, cbk, useFormData, fileFormData) {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    if (useFormData) {
        formData.append('image', fileFormData);
    } else {
        const {files} = fileEle;
        for (let i = 0, len = files.length; i < len; i++) {
            formData.append('image', files[i]);
        }
    }
    xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
            let res;
            try {
                res = JSON.parse(xhr.responseText);
                cbk(res);
            } catch (err) {
                console.error(err);
            }
        }
    });
    xhr.open('POST', apiConfig.uploadImage);
    xhr.setRequestHeader('clientInfo', 'Manage');
    xhr.send(formData);
}

/**
 * delete
 * */
export function xhrDelete(url, params) {
    let i;
    let first = true;
    /* eslint guard-for-in: 0 */
    for (i in params) {
        if (first) {
            url += '?';
            first = false;
        } else {
            url += '&';
        }
        url += `${i}=${params[i]}`;
    }
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('readystatechange', () => {
            if (xhr.readyState === 4) {
                let res;
                try {
                    res = JSON.parse(xhr.responseText);
                    resolve(res);
                } catch (err) {
                    reject(err);
                    console.error(err);
                }
            }
        });
        xhr.open('DELETE', url);
        xhr.setRequestHeader('clientInfo', 'Manage');
        xhr.send();
    });
}

export function clearRequestParam(param) {
    for (let i in param) {
        if (typeof param[i] === 'undefined')
            delete param[i];
        else if (typeof param[i] === 'string' && param[i] === '') {
            delete param[i];
        }
    }
    return param;
}

export function getUrlWithPathParam(url, pathParams) {
    pathParams.forEach((item) => {
        url = url.replace('{' + item.key + '}', item.value);
    });
    return url;
}

function getApi() {
    return apiConfig;
}

export const api = getApi();
