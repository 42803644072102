import * as React from "react";

require('./partner.css')

export default class Partner extends React.Component {
    render() {
        return <div style={{
            height: 432,
            width: 1072,
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
        }}>

            <div className="partaner" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 106,
                // backgroundImage: `url(${EIGHTEEN})`
            }}></div>
            <div className="partaner1" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 324,
                // backgroundImage: `url("${ELEVEN}")`
            }}></div>
            <div className="partaner2" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 539,
                // backgroundImage: `url(${SIXTEEN})`
            }}></div>
            <div className="partaner3" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 754,
                // backgroundImage: `url(${FOURTEEN})`
            }}></div>


            <div className="partaner4" style={{
                width: 212,
                height: 212,
                position: "absolute",
                top: 107,
                // backgroundImage: `url("${EIGHT}")`
            }}></div>

            <div className="partaner5" style={{
                width: 212,
                height: 212,
                position: "absolute",
                top: 107,
                left: 215,
                // backgroundImage: `url("${SEVEN}")`
            }}></div>

            <div className="partaner6" style={{
                width: 212,
                height: 212,
                position: "absolute",
                top: 107,
                left: 430,
                // backgroundImage: `url("${TWELVW}")`
            }}></div>

            <div className="partaner7" style={{
                width: 212,
                height: 212,
                position: "absolute",
                top: 107,
                left: 645,
                // backgroundImage: `url("${THIRTEEN}")`
            }}></div>

            <div className="partaner8" style={{
                width: 212,
                height: 212,
                position: "absolute",
                top: 107,
                left: 860,
                // backgroundImage: `url("${FIFTEEN}")`
            }}></div>


            <div className="partaner9" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 106,
                top: 215,
                // backgroundImage: `url(${SEVENTEEN})`
            }}></div>
            <div className="partaner10" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 324,
                top: 215,
                // backgroundImage: `url("${TEN}")`
            }}></div>
            <div className="partaner11" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 539,
                top: 215,
                // backgroundImage: `url("${NINE}")`
            }}></div>
            <div className="partaner12" style={{
                width: 212,
                height: 212,
                position: "absolute",
                left: 754,
                top: 215,
                // backgroundImage: `url("${SIX}")`
            }}></div>
        </div>
    }
}