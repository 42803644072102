import * as React from "react";
import ServiceScope from "../service_scope/service_scope";
import ContentProcess from "../content_process/content_process";
import ServiceAdvantage from "../service_advantage/service_advantage";
import Head from "../head/head";
import {message} from "antd";
import {getVerifyCode, place_order, place_order_withVerifyCode} from "../../action/order_action";
import IMG_ICON_CAR_GRAY from "../../assets/img/icon/icon_car_gray.png";
import IMG_ICON_ARROW_DOWN_GRAY from "../../assets/img/icon/icon_arrow_down_gray.png";
import IMG_ICON_POINTER_RECEIVE_GRAY from "../../assets/img/icon/icon_pointer_receive_gray.png";
import IMG_ICON_PHONE_GRAY from "../../assets/img/icon/icon_phone_gray.png";
import IMG_KEY from "../../assets/img/img_key.png";
import ICON_LOADING_GREEN from "../../assets/img/icon/icon_loading.png";
import AddressChooser from "../address_chooser";
import BACKGROUND from "../../assets/img/banner/banner2.png";
import ServiceCase from "../service_case/service_case";
import * as Scroll from "react-scroll";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";


require("./service_introduce.css")

class ServiceIntroduce extends React.Component {
    static STATE_STRUCT = () => {
        return {
            verifyCodeFlag: true,
            verifyCodeCount: 0,
            currentPageIndex: 1,
            showSenderAddressChooser: false,
            senderAddress: {},
            showReceiverAddressChooser: false,
            receiveAddress: {},
            phone: "",
            verifyCode: "",
            showLoading: false,
            showModal: false,
            searchValueSender: "",
            searchValueReceive: "",
            currentFrontPage: 0,
            currentSafePage: 0,

            frontPageTimer: null,
            realTimeOrderTimer: null,
            realTimeOrderLeftData: [],
            realTimeOrderRightData: [],
            realTimeOrderLeftIndex: 0,
            realTimeOrderRightIndex: 0,

            caseData: [],
            currentCaseIndex: 0,

            currentMustKnowNewsIndex: 0,
            mustKnowData: [],
            newsData: [],
            caseId: '',
        }
    };

    state = ServiceIntroduce.STATE_STRUCT();

    constructor(props) {
        super(props);
        console.log(props.match)
        console.log(props.location.search)
        let pathIndex = '';
        if (props.location.search) {
            pathIndex = props.location.search.split("=")[1];
        }
        let show1 = false;
        let show2 = false;
        let show3 = false;
        let show4 = false;
        let caseId = "";
        let title = "";
        let pageTitle = '';
        let meta = '';
        if (props.match.params.id === undefined) {

            show1 = true;
            show2 = false;
            show3 = false;
            show4 = false;
        } else {
            show1 = props.match.params.id === "1";
            show2 = props.match.params.id === "2";
            show3 = props.match.params.id === "3";
            show4 = props.match.params.id === "4";
            if (show1) {
                meta = "服务范围"
                pageTitle = "服务范围 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好"
            }
            if (show2) {
                meta = "服务流程"
                pageTitle = "服务流程 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好"
            }
            if (show3) {
                meta = "服务优势"
                pageTitle = "服务优势 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好"
            }
            if (show4) {
                if (!props.match.params.title) {
                    meta = "服务案例"
                    pageTitle = "服务案例 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好"
                }
                title = props.match.params.title;
                show1 = false;
                show2 = false;
                show3 = false;
                show4 = true;
            }
        }
        this.state = {
            pathIndex: pathIndex,
            show1: show1,
            show2: show2,
            show3: show3,
            show4: show4,
            verifyCodeFlag: true,
            verifyCodeCount: 0,
            currentPageIndex: 1,
            showSenderAddressChooser: false,
            senderAddress: {},
            showReceiverAddressChooser: false,
            receiveAddress: {},
            phone: "",
            verifyCode: "",
            showLoading: false,
            showModal: false,
            searchValueSender: "",
            searchValueReceive: "",
            currentFrontPage: 0,
            currentSafePage: 0,

            frontPageTimer: null,
            realTimeOrderTimer: null,
            realTimeOrderLeftData: [],
            realTimeOrderRightData: [],
            realTimeOrderLeftIndex: 0,
            realTimeOrderRightIndex: 0,

            caseData: [],
            currentCaseIndex: 0,

            currentMustKnowNewsIndex: 0,
            mustKnowData: [],
            newsData: [],
            caseId: caseId,
            title: title,
            pageTitle: pageTitle,
            meta: meta,
            // pageTitle: props.location.state === undefined ? '' : props.location.state.pageTitle,
            // meta: props.location.state === undefined ? '' : props.location.state.meta,
        }
    }

    createContentFrontPage = () => (<div style={{
        paddingTop: "18%",
        position: "relative",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto"
    }}>
        <div style={{position: "absolute", right: 100, width: 172, cursor: "pointer"}} onClick={(e) => {
            if (!this.state.senderAddress ||
                !this.state.senderAddress.code ||
                this.state.senderAddress.name !== this.state.searchValueSender) {
                message.error("请选择发车地!");
            } else if (!this.state.receiveAddress ||
                !this.state.receiveAddress.code ||
                this.state.receiveAddress.name !== this.state.searchValueReceive) {
                message.error("请选择收车地!");
            } else if (!this.state.phone) {
                message.error("请输入联系人!");
            } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
                message.error("联系人请输入正确的手机号!");
            } else {
                this.setState({showLoading: true});
                place_order(this.state.senderAddress.provinceName, this.state.senderAddress.provinceCode, this.state.senderAddress.name, this.state.senderAddress.code, this.state.receiveAddress.provinceName, this.state.receiveAddress.provinceCode, this.state.receiveAddress.name, this.state.receiveAddress.code, this.state.phone, (result, message) => {
                    if (result) {
                        this.setState({showModal: true});
                    } else {
                        this.setState({showLoading: false});
                        message.error(message ? message : "未知错误");
                    }
                });
            }
        }}>
        </div>
        <div style={{
            width: 968,
            position: "absolute",
            left: "20%",
            height: 52,
            border: "1px solid rgba(46,178,166,1)",
            background: "white"
        }}>
            <div style={{
                width: 172,
                position: "absolute",
                height: 52,
                top: -1,
                right: -173,
                backgroundColor: this.state.verifyCodeFlag ? "#2EB2A6" : "gray",
                lineHeight: "52px",
                textAlign: "center",
                color: "white",
                fontSize: 18,
                cursor: "pointer",
            }} onClick={(e) => {
                if (!this.state.verifyCodeFlag) {
                    return;
                }
                if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
                    message.error("联系人请输入正确的手机号!");
                    return;
                }
                getVerifyCode(this.state.phone, (result, msg) => {
                    if (result) {
                        message.info("验证码已发送至您手机，请注意查收。")
                        this.timer = setInterval(() => {
                            if (this.state.verifyCodeCount === 0) {
                                if (this.state.verifyCodeFlag) {
                                    this.setState({verifyCodeFlag: false, verifyCodeCount: 60});
                                } else {
                                    this.setState({verifyCodeFlag: true, verifyCodeCount: 0});
                                    clearInterval(this.timer)
                                }
                            } else {
                                this.setState({
                                    verifyCodeFlag: false,
                                    verifyCodeCount: this.state.verifyCodeCount - 1
                                });
                            }

                        }, 1000)
                    } else {
                        message.info(msg);
                    }
                })
            }}>获取验证码{!this.state.verifyCodeFlag ? `(${this.state.verifyCodeCount})` : ""}
            </div>
            <div onClick={(e) => this.setState({
                showSenderAddressChooser: !this.state.showSenderAddressChooser,
                showReceiverAddressChooser: false
            })} style={{
                height: "100%",
                width: 241,
                float: "left",
                position: "relative",
                cursor: "pointer"
            }}>
                <img src={IMG_ICON_CAR_GRAY}
                     style={{width: 19, marginTop: 18, marginLeft: 13, display: "block"}}/>
                <input value={this.state.searchValueSender}
                       onChange={(e) => this.setState({searchValueSender: e.target.value})} style={{
                    width: 170,
                    display: "block",
                    top: 13,
                    left: 42,
                    color: "#000000",
                    position: "absolute",
                    fontSize: 16,
                    backgroundColor: "transparent",
                    appearance: "none",
                    border: "none",
                    outline: "none"
                }} type="text" placeholder="发车地"/>
                <img src={IMG_ICON_ARROW_DOWN_GRAY}
                     style={{position: "absolute", width: 12, top: "50%", marginTop: -2, right: 10}}/>
                {
                    this.state.showSenderAddressChooser ?
                        <AddressChooser keyWord={this.state.searchValueSender}
                                        handleData={(data) => this.setState({
                                            senderAddress: data,
                                            showSenderAddressChooser: false,
                                            searchValueSender: data.name
                                        })} style={{
                            position: "absolute",
                            zIndex: 99,
                            left: -2,
                            top: 50,
                            color: "black"
                        }}/> : null
                }
            </div>
            <div style={{background: "#2EB2A6", width: 1, height: 27, marginTop: 13, float: "left"}}/>
            <div onClick={(e) => this.setState({
                showReceiverAddressChooser: !this.state.showReceiverAddressChooser,
                showSenderAddressChooser: false
            })} style={{
                height: "100%",
                width: 241,
                float: "left",
                position: "relative",
                cursor: "pointer"
            }}>
                <img src={IMG_ICON_POINTER_RECEIVE_GRAY}
                     style={{width: 15, marginTop: 17, marginLeft: 13, display: "block"}}/>
                <input value={this.state.searchValueReceive}
                       onChange={(e) => this.setState({searchValueReceive: e.target.value})} style={{
                    width: 170,
                    display: "block",
                    top: 13,
                    left: 42,
                    color: "#000000",
                    position: "absolute",
                    fontSize: 16,
                    backgroundColor: "transparent",
                    appearance: "none",
                    border: "none",
                    outline: "none"
                }} type="text" placeholder="收车地"/>
                <img src={IMG_ICON_ARROW_DOWN_GRAY}
                     style={{position: "absolute", width: 12, top: "50%", marginTop: -2, right: 10}}/>
                {
                    this.state.showReceiverAddressChooser ?
                        <AddressChooser keyWord={this.state.searchValueReceive}
                                        handleData={(data) => this.setState({
                                            receiveAddress: data,
                                            showReceiverAddressChooser: false,
                                            searchValueReceive: data.name
                                        })} style={{
                            position: "absolute",
                            zIndex: 99,
                            left: 0,
                            top: 50,
                            color: "black"
                        }}/> : null
                }
            </div>
            <div style={{background: "#2EB2A6", width: 1, height: 27, marginTop: 13, float: "left"}}/>
            <div style={{height: "100%", width: 241, float: "left", position: "relative"}}>
                <img src={IMG_ICON_PHONE_GRAY}
                     style={{width: 17, marginTop: 18, marginLeft: 13, display: "block"}}/>
                <input value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})}
                       style={{
                           width: 170,
                           display: "block",
                           top: 13,
                           left: 42,
                           color: "#000000",
                           position: "absolute",
                           fontSize: 16,
                           backgroundColor: "transparent",
                           appearance: "none",
                           border: "none",
                           outline: "none"
                       }} type="text" placeholder="手机号"/>
            </div>
            <div style={{background: "#2EB2A6", width: 1, height: 27, marginTop: 13, float: "left"}}/>
            <div style={{height: "100%", width: 230, float: "left", position: "relative"}}>
                <img src={IMG_KEY} style={{width: 18, marginTop: 18, marginLeft: 13, display: "block"}}/>
                <input value={this.state.verifyCode}
                       onChange={(e) => this.setState({verifyCode: e.target.value})}
                       style={{
                           width: 170,
                           display: "block",
                           top: 13,
                           left: 42,
                           color: "#000000",
                           position: "absolute",
                           fontSize: 16,
                           backgroundColor: "transparent",
                           appearance: "none",
                           border: "none",
                           outline: "none"
                       }} type="text" placeholder="验证码"/>
            </div>
        </div>
        <div
            style={{
                width: 1200,
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
                top: 30,
                left: 32
            }}>
        </div>
        <div style={{
            width: 280,
            height: 60,
            lineHeight: "60px",
            textAlign: "center",
            color: "white",
            fontSize: 24,
            position: "absolute",
            top: 430,
            left: "50%",
            marginLeft: -140,
            cursor: "pointer",
            borderRadius: 5,
            backgroundColor: "#20D1C1"
        }}
             onClick={(e) => {
                 if (!this.state.senderAddress ||
                     !this.state.senderAddress.code ||
                     this.state.senderAddress.name !== this.state.searchValueSender) {
                     message.error("请选择发车地!");
                 } else if (!this.state.receiveAddress ||
                     !this.state.receiveAddress.code || this.state.receiveAddress.name !== this.state.searchValueReceive) {
                     message.error("请选择收车地!");
                 } else if (!this.state.phone) {
                     message.error("请输入联系人!");
                 } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
                     message.error("联系人请输入正确的手机号!");
                 } else if (!this.state.verifyCode) {
                     message.error("请输入验证码");
                 } else {
                     place_order_withVerifyCode(this.state.phone, this.state.verifyCode,
                         this.state.senderAddress.provinceName,
                         this.state.senderAddress.provinceCode,
                         this.state.senderAddress.name,
                         this.state.senderAddress.code,
                         this.state.receiveAddress.provinceName,
                         this.state.receiveAddress.provinceCode, this.state.receiveAddress.name,
                         this.state.receiveAddress.code, this.state.phone, (result, msg) => {
                             if (result) {

                                 let leftData = this.state.realTimeOrderLeftData;
                                 let rightData = this.state.realTimeOrderRightData;
                                 this.setState(ServiceIntroduce.STATE_STRUCT());
                                 this.setState({
                                     realTimeOrderLeftData: leftData,
                                     realTimeOrderRightData: rightData,
                                     senderAddress: {},
                                     receiveAddress: {},
                                     verifyCode: '',
                                     phone: '',
                                     searchValueSender: '',
                                     searchValueReceive: '',
                                     verifyCodeFlag: true,
                                     verifyCodeCount: 0,
                                     showModal: true,
                                 });
                                 clearInterval(this.timer)
                             }
                         });
                 }
             }
             }>
            一键获取方案
        </div>
    </div>)
    showModal = () => (
        <div style={{
            margin: "0 auto",
            position: "fixed",
            backgroundColor: "white",
            borderRadius: 5,
            width: 395,
            zIndex: 101,
            height: 225,
            left: 0,
            right: 0,
            top: "50%",
            marginTop: -112.5
        }}>
                            <span style={{
                                color: "#181A1A",
                                marginTop: 21,
                                display: "block",
                                textAlign: "center",
                                fontSize: 20
                            }}>提示</span>
            <div style={{width: "100%", height: 1, background: "rgba(238,238,238,1)", marginTop: 21}}/>
            <span style={{
                color: "#181A1A",
                fontSize: 18,
                display: "block",
                marginTop: 41,
                textAlign: "center"
            }}>请保持手机畅通，客服人员马上给您报价。</span>
            <span onClick={(e) => this.setState({showModal: false})} style={{
                color: "white",
                fontSize: 16,
                width: 116,
                display: "block",
                margin: "0 auto",
                marginTop: 35,
                backgroundColor: "#25A8A0",
                borderRadius: 24,
                textAlign: "center",
                paddingTop: 8,
                paddingBottom: 8,
                cursor: "pointer"
            }}>确定</span>
        </div>
    )
    click1 = () => {
        this.props.history.replace(`/service/1`);
        this.setState({
                show1: true,
                show2: false,
                show3: false,
                show4: false,
                pageTitle: '',
                meta: '',
            }
        )

    }
    click2 = () => {
        this.props.history.replace(`/service/2`);
        this.setState({
                show1: false,
                show2: true,
                show3: false,
                show4: false,
                pageTitle: '',
                meta: '',
            }
        )
    }
    click3 = () => {
        this.props.history.replace(`/service/3`);
        this.setState({
                show1: false,
                show2: false,
                show3: true,
                show4: false,
                pageTitle: '',
                meta: '',
            }
        )
    }
    click4 = () => {
        this.props.history.replace(`/service/4`);
        this.setState({
                show1: false,
                show2: false,
                show3: false,
                show4: true,
            }
        )
    }
    caseUrlChange = (url) => {
        this.props.history.replace(url);
    }

    render() {
        const serviceButtonStyleShow = {
            backgroundColor: '#2EB2A6',
            border: '0px solid rgba(191, 191, 191, 1)',
            display: "flex",
            width: 280,
            height: 60,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 26,
        }
        const serviceButtonStyle = {
            border: '1px solid rgba(191, 191, 191, 1)',
            display: "flex",
            width: 280,
            height: 60,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 26,
        }
        const serviceTitleShow = {
            fontSize: 20,
            fontFamily: "Microsoft YaHei",
            fontWeight: 400,
            color: "white"
        }
        const serviceTitle = {
            fontSize: 20,
            fontFamily: "Microsoft YaHei",
            fontWeight: 400,
        }
        return (<div style={{
            maxWidth: 1920, width: "100%", marginLeft: "auto",
            marginRight: "auto"
        }}>
            <Helmet>
                <meta name="description" content={this.state.meta}/>
                <meta name="keywords" content={this.state.meta}/>
                <title>{this.state.pageTitle}</title>
            </Helmet>
            <div style={{
                width: "100%",
                height: "35vw",
                maxHeight: 660,
                backgroundImage: `url(${BACKGROUND})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"

            }}>
                <Head></Head>
                {this.createContentFrontPage()}
            </div>

            <div style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%", height: 150,
                backgroundColor: "white",
                boxShadow: `1px 4px 6px rgba(0,0,0,0.04)`,
                display: "flex", flexDirection: "row",
                justifyContent: "center", alignItems: "center"
            }}>
                <Link to={'/service/1'} target='_blank'>
                    <div className="servicebutton"
                         style={this.state.show1 ? serviceButtonStyleShow : serviceButtonStyle}>
                    <span className="buttoncontent"
                          style={this.state.show1 ? serviceTitleShow : serviceTitle}>服务范围</span>
                    </div>
                </Link>
                <Link to={'/service/2'} target='_blank'>
                    <div className="servicebutton"
                         style={this.state.show2 ? serviceButtonStyleShow : serviceButtonStyle}>
                    <span className="buttoncontent"
                          style={this.state.show2 ? serviceTitleShow : serviceTitle}>服务流程</span>
                    </div>
                </Link>
                <Link to={'/service/3'} target='_blank'>
                    <div className="servicebutton"
                         style={this.state.show3 ? serviceButtonStyleShow : serviceButtonStyle}>
                    <span className="buttoncontent"
                          style={this.state.show3 ? serviceTitleShow : serviceTitle}>服务优势</span>
                    </div>
                </Link>
                <Link to={'/service/4'} target='_blank'>
                    <div className="servicebutton"
                         style={this.state.show4 ? serviceButtonStyleShow : serviceButtonStyle}>
                    <span className="buttoncontent"
                          style={this.state.show4 ? serviceTitleShow : serviceTitle}>服务案例</span>
                    </div>
                </Link>
            </div>
            <div style={{
                display: this.state.show1 ? "block" : "none",
                marginTop: 4,
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <ServiceScope></ServiceScope>
            </div>
            <div style={{
                display: this.state.show2 ? "block" : "none",
                marginTop: 4,
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <ContentProcess></ContentProcess>
            </div>
            <div style={{
                display: this.state.show3 ? "block" : "none",
                marginTop: 4,
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <ServiceAdvantage></ServiceAdvantage>
            </div>
            <div style={{
                display: this.state.show4 ? "block" : "none",
                marginTop: 4,
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <ServiceCase callback={this.caseUrlChange} caseId={this.state.caseId} show={this.state.show4}
                             title={this.state.pathIndex}
                             pageTitle={this.state.pageTitle} meta={this.state.meta}/>
            </div>
            {
                (this.state.showLoading || this.state.showModal) ? <div style={{
                    position: "fixed",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 100,
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%"
                }}/> : null
            }
            {
                this.state.showLoading ?
                    <div style={{
                        margin: "0 auto",
                        position: "fixed",
                        backgroundColor: "white",
                        borderRadius: 5,
                        width: 395,
                        zIndex: 101,
                        height: 80,
                        left: 0,
                        right: 0,
                        top: "50%",
                        marginTop: -40,
                        lineHeight: "80px"
                    }}>
                        <img src={ICON_LOADING_GREEN} style={{
                            verticalAlign: "middle",
                            marginLeft: 20,
                            width: 40,
                            animation: "rotateInfinite 1.5s linear infinite"
                        }}/>
                        <span style={{
                            fontSize: 25,
                            marginLeft: 20,
                            verticalAlign: "middle",
                            color: "black"
                        }}>请稍等</span>
                    </div> : null
            }
            {this.state.showModal ?
                <div style={{
                    margin: "0 auto",
                    position: "fixed",
                    backgroundColor: "white",
                    borderRadius: 5,
                    width: 395,
                    zIndex: 101,
                    height: 225,
                    left: 0,
                    right: 0,
                    top: "50%",
                    marginTop: -112.5
                }}>
                            <span style={{
                                color: "#181A1A",
                                marginTop: 21,
                                display: "block",
                                textAlign: "center",
                                fontSize: 20
                            }}>提示</span>
                    <div style={{width: "100%", height: 1, background: "rgba(238,238,238,1)", marginTop: 21}}/>
                    <span style={{
                        color: "#181A1A",
                        fontSize: 18,
                        display: "block",
                        marginTop: 41,
                        textAlign: "center"
                    }}>请保持手机畅通，客服人员马上给您报价。</span>
                    <span onClick={(e) => this.setState({showModal: false})} style={{
                        color: "white",
                        fontSize: 16,
                        width: 116,
                        display: "block",
                        margin: "0 auto",
                        marginTop: 35,
                        backgroundColor: "#25A8A0",
                        borderRadius: 24,
                        textAlign: "center",
                        paddingTop: 8,
                        paddingBottom: 8,
                        cursor: "pointer"
                    }}>确定</span>
                </div> : null
            }
        </div>)
    }
}

export default ServiceIntroduce