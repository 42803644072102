import React from "react";
import {View} from "antd-mobile";
import Text from "antd-mobile/es/text";
import IMG_LOGO from "../../assets/img/logo/logo.png";
import IMG_QR_CODE_PUBLIC from "../../assets/img/qr_code_public.jpg";
import PHONE from "../../assets/img/foot/电话@2x.png";

class ParticleFoot extends React.Component {
    render() {
        return <div style={{
            width: "100%", height: 350, textAlign: "center"
        }}>
            <div style={{width: "100", height: 300, backgroundColor: "#225E68", position: "relative"}}>
                <div style={{
                    width: 316,
                    height: 100,
                    left: "18.75%",
                    top: 100,
                    backgroundImage: `url(${IMG_LOGO})`,
                    backgroundSize: "cover",
                    position: "absolute",
                    float: "left"
                }}></div>
                <div style={{
                    width: 1,
                    height: 159,
                    left: "40%",
                    top: 70,
                    backgroundColor: "#1A6D7B",
                    float: "left",
                    position: "absolute",
                }}/>
                <div style={{
                    position: "absolute",
                    left: "46.5%",
                    top: 51,
                }}>
                    <div style={{
                        width: 135,
                        height: 135,
                        backgroundImage: `url(${IMG_QR_CODE_PUBLIC})`,
                        backgroundSize: "cover",

                    }}>
                    </div>
                    <View style={{paddingTop: 16}}>
                        <Text style={{
                            color: "white",
                            fontSize: 16,
                            fontFamily: "Microsoft YaHei",
                            fontWeight: 400,
                        }}>扫码关注公众号</Text>
                    </View>
                    <View>
                        <Text style={{
                            color: "white",
                            fontSize: 16,
                            fontFamily: "Microsoft YaHei",
                            fontWeight: 400,
                        }}>开启运输</Text>
                    </View>
                </div>
                <div style={{
                    width: 1,
                    height: 159,
                    left: "59.5%",
                    top: 70,
                    backgroundColor: "#1A6D7B",
                    float: "left",
                    position: "absolute",
                }}/>
                <div style={{
                    width: 342,
                    height: 205,
                    left: "63%",
                    top: 50,
                    position: "absolute"
                }}>
                    <View>
                        <Text style={{
                            color: "white",
                            fontSize: 16,
                            fontFamily: "Microsoft YaHei",
                            fontWeight: 400,
                        }}>全国免费咨询热线</Text>
                    </View>
                    <div>
                        <div style={{
                            width: 24,
                            height: 36,
                            backgroundImage: `url(${PHONE})`,
                            backgroundSize: "cover",
                            display: "inline-block",
                            marginRight: 8,
                            marginTop: 5,
                        }}></div>
                        <View style={{
                            display: "inline-block"
                        }}>
                            <Text style={{
                                color: "white",
                                fontSize: 40,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                            }}>400-603-8656</Text>
                        </View>
                        <div style={{
                            width: 170,
                            height: 45,
                            backgroundColor: "white",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: 5,
                            marginTop: 8
                        }}>
                            <View>
                                <Text style={{
                                    color: "#225E68",
                                    fontSize: 24,
                                    fontFamily: "Microsoft YaHei",
                                    fontWeight: 400,
                                    lineHeight: 2,
                                    cursor: "pointer"
                                }}
                                      onClick={(e) => window.open("http://p.qiao.baidu.com/cps/chat?siteId=15167097&userId=29640200&siteToken=294c619cc592ae15bda78083498283b9", "在线咨询", "width=800, height=600")}
                                >立即咨询</Text>
                            </View>
                        </div>
                        <View style={{marginTop: 8, width: 342, height: 47}}>
                            <Text style={{
                                color: "white",
                                fontSize: 16,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                lineHeight: 2,
                            }}>联系地址：深圳市福田区福田街道黄岗社区 益田路3008号皇都广场B栋2506
                            </Text>
                        </View>
                    </div>

                </div>
            </div>
            <div style={{
                width: "100%",
                height: 50,
                backgroundColor: "#114C56",
                textAlign: "center"
            }}>
                <View style={{
                    paddingTop: 13,

                }}>
                    <Text style={{
                        color: "#FFFFFF",
                        fontSize: 16,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                    }}>车粒子科技有限公司  <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19064435号-2</a></Text>
                </View>
            </div>
        </div>
    }
}

export default ParticleFoot