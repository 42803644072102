import * as React from "react";
import BACKGROUND from "../../assets/img/introduce/u94.png"
import line from "../../assets/img/process/组 3 拷贝 5.png";
import ReactMarkdown from "react-markdown/with-html";
import One from "../../assets/img/introduce/WechatIMG505.png"
import TWO from "../../assets/img/introduce/WechatIMG505 拷贝.png"
import THREE from "../../assets/img/introduce/WechatIMG505 拷贝 2.png"
import FOUR from "../../assets/img/introduce/WechatIMG505 拷贝 3.png"
import FIVE from "../../assets/img/introduce/WechatIMG505 拷贝 4.png"
import ParticleFoot from "../foot/foot";
import Head from "../head/head";
import BANNER from "../../assets/img/banner/banner1.jpg"
import IMG_FIRST_PAGE_TITLE from "../../assets/img/introduce/WechatIMG274.png";
import IMG_FIRST_PAGE_PHONE from "../../assets/img/introduce/WechatIMG275.png";
import * as Scroll from "react-scroll/modules";
import IMG_FIRST_PAGE_BUTTON from "../../assets/img/introduce/WechatIMG276.png";
import {Link} from "react-router-dom";
import Partner from "../partner/partner";
import {Helmet} from "react-helmet";

require("./introduce.css")

class Introduce extends React.Component {

    render() {
        return <div style={{
            maxWidth: 1920, width: "100%", marginLeft: "auto",
            marginRight: "auto"
        }}>
            <Helmet>
                <meta name="description" content={this.state.meta}/>
                <meta name="keywords" content={this.state.meta}/>
                <title>{this.state.pageTitle}</title>
            </Helmet>
            <div style={{
                width: "100%",
                height: 660,
                backgroundImage: `url(${BANNER})`,
                position: "relative",
                overflow: "hidden",
                marginLeft: "auto",
                marginRight: "auto",
            }}>
                <Head></Head>
                <div style={{position: "absolute", top: 245, left: 360}}>
                    <img src={IMG_FIRST_PAGE_TITLE}
                         style={{width: 534, height: 66}}/>
                    <br/>
                    <div style={{marginTop: 40, position: "relative"}}>
                        <img src={IMG_FIRST_PAGE_PHONE} style={{width: 50}}/>
                        <div style={{
                            fontSize: 45,
                            position: "absolute",
                            top: -60,
                            left: 60,
                            marginBottom: 0,
                            marginLeft: 10,
                            marginTop: 50,
                            color: "#9B251A",
                            fontWeight: 800,
                            fontFamily: "Microsoft YaHei",
                        }}>400-603-8656
                        </div>
                    </div>
                    <br/>
                    <div style={{cursor: "pointer"}}>
                        <Link to='/place'>
                            <img src={IMG_FIRST_PAGE_BUTTON}
                                 style={{width: 180, height: 54, marginTop: 45,}}/>
                            <div style={{
                                fontSize: 24,
                                position: "absolute",
                                top: 229,
                                left: 45,
                                color: "white",
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 800,
                            }}>我要运车
                            </div>
                        </Link></div>
                </div>

            </div>
            <div style={{
                width: "100%",
                height: 1846,
                marginLeft: "auto",
                marginRight: "auto",
                backgroundImage: `url(${BACKGROUND})`
            }}>
                <div style={{textAlign: "center", marginRight: "auto", marginLeft: "auto", paddingTop: 102}}>
                    <div style={{fontSize: 36, fontFamily: "Microsoft YaHei", fontWeight: 400}}>公司简介</div>
                    <div style={{fontSize: 16, fontFamily: "Microsoft YaHei", fontWeight: 400}}>Company profile</div>
                    <img src={line} style={{marginTop: 25}}></img>
                    <div
                        style={{marginTop: 50, width: 800, marginLeft: "auto", marginRight: "auto"}}>
                        <ReactMarkdown
                            source={'全国领先的互联网汽车物流公司，轿运车公司。从事门到门运输汽车、汽车异地运输、自驾游汽车运输、' +
                            '二手车运输、私家车运输、汽车托运、轿车托运、邮寄汽车、运车等托运服务，运输汽车长期合作，范围覆盖新能源汽车、' +
                            '小轿车、SUV、商务车、货运车等多种车型。业务范围覆盖北京、上海、广州、深圳、杭州、重庆、西安、四川等地。' +
                            '未来车粒子科技将持续秉承着重服务，保品质，科技赋能的态度服务好客户，输出更多利于汽车物流领域的产品。'}></ReactMarkdown>
                    </div>
                    <div style={{
                        width: 480,
                        overflow: "hidden",
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: 57,
                    }}>
                        <div
                            style={{
                                fontSize: 36,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#D50000",
                                float: "left"
                            }}>1000+
                            <div style={{
                                fontSize: 16,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#4D4D4D",
                            }}>专线运输承运商</div>
                        </div>
                        <div
                            style={{
                                fontSize: 36,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#D50000",
                                float: "left",
                                marginLeft: 62
                            }}>10000+
                            <div style={{
                                fontSize: 16,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#4D4D4D",
                            }}>专职司机</div>
                        </div>
                        <div
                            style={{
                                fontSize: 36,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#D50000",
                                float: "left",
                                marginLeft: 60
                            }}>300+
                            <div style={{
                                fontSize: 16,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#4D4D4D",
                            }}>覆盖城市</div>
                        </div>

                    </div>
                    <div style={{
                        width: 480,
                        overflow: "hidden",
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: 57
                    }}>
                        <div
                            style={{
                                fontSize: 36,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#D50000",
                                float: "left",
                                marginLeft: 60
                            }}>10万+
                            <div style={{
                                fontSize: 16,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#4D4D4D",
                            }}>年运输量</div>
                        </div>
                        <div
                            style={{
                                fontSize: 36,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#D50000",
                                float: "left",
                                marginLeft: 59
                            }}>10000+
                            <div style={{
                                fontSize: 16,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 400,
                                color: "#4D4D4D",
                            }}>客户服务量</div>
                        </div>

                    </div>
                    <div style={{
                        width: 1200,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 79,
                        overflow: "hidden"
                    }}>

                        <div style={{
                            width: 406,
                            height: 518,
                            float: "left",
                            backgroundImage: `url(${One})`,
                            overflow: "hidden"
                        }}
                             onMouseEnter={() => this.setState({wishShow: true})}
                             onMouseLeave={() => this.setState({wishShow: false})}>
                            <div className="wishsub"
                                 style={this.state.wishShow ? {
                                     width: 406,
                                     height: 518,
                                     backgroundColor: "#53AC9F",
                                     opacity: 0.9,
                                     paddingTop: 90,
                                     display: "block"
                                 } : {
                                     width: 406,
                                     height: 518,
                                     backgroundColor: "#53AC9F",
                                     opacity: 0.9,
                                     paddingTop: 90,
                                     display: "none",
                                 }}>
                                <div style={{
                                    fontSize: 24,
                                    fontFamily: "Microsoft YaHei",
                                    fontWeight: 800,
                                    color: "#FFFFFF",
                                    paddingTop: 15
                                }}>公司愿景
                                    <div style={{
                                        width: 30,
                                        height: 2,
                                        backgroundColor: "white",
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    }}></div>
                                    <div style={{
                                        fontWeight: 400,
                                        fontSize: 16,
                                        lineHeight: 3,
                                        width: 300,
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginTop: 10,
                                    }}>
                                        <ReactMarkdown
                                            source={'<p>致力于以科技赋能汽车物流，</br>提供汽车运输快递服务。</br>以开放平台连接海量用户;</br>以智能算法提升匹配效率;</br>以专业服务保障准时安全;</br>引领汽车物流走向移动互联时代。</p>'}
                                            escapeHtml={false}></ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                            <div style={this.state.wishShow ? {
                                fontSize: 24,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 800,
                                color: "#FFFFFF",
                                marginTop: "60%",
                                display: "none",
                            } : {
                                fontSize: 24,
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 800,
                                color: "#FFFFFF",
                                marginTop: "60%",
                                display: "block",
                            }}>公司愿景
                                <div style={{
                                    width: 30,
                                    height: 2,
                                    backgroundColor: "white", marginLeft: "auto", marginRight: "auto"
                                }}></div>
                            </div>

                        </div>
                        <div style={{width: 790, height: 518, float: "right"}}>
                            <div className="wangluobuju"
                                 style={{width: 393, height: 257, float: "right", backgroundImage: `url("${TWO}")`}}>
                                <div className="wangluobujusub"
                                     style={{width: "100%", height: "100%", backgroundColor: "#53AC9F", opacity: 0.9}}>
                                    <div style={{
                                        fontSize: 24,
                                        fontFamily: "Microsoft YaHei",
                                        fontWeight: 800,
                                        color: "#FFFFFF",
                                        paddingTop: 20
                                    }}>网络布局
                                        <div style={{
                                            width: 30,
                                            height: 2,
                                            backgroundColor: "white",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}></div>
                                        <div style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                            lineHeight: 2,
                                            marginLeft: 30,
                                            marginRight: 30,
                                            marginTop: 20
                                        }}>本公司已整合1000+专线运输承运商。10000+专职运输司机，运输网络已覆盖全国超300个城市，覆盖全国所有省会城市及大多数地级市，年运输台量可达100000+，累计为10000+客户提供优质服务
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    fontSize: 24,
                                    fontFamily: "Microsoft YaHei",
                                    fontWeight: 800,
                                    color: "#FFFFFF",
                                    marginTop: "30%"
                                }}>网络布局
                                    <div style={{
                                        width: 30,
                                        height: 2,
                                        backgroundColor: "white", marginLeft: "auto", marginRight: "auto"
                                    }}></div>
                                </div>
                            </div>
                            <div className="profession" style={{
                                width: 393,
                                height: 257,
                                float: "right",
                                backgroundImage: `url("${FIVE}")`,
                                marginRight: 4
                            }}>
                                <div className="professionsub"
                                     style={{width: "100%", height: "100%", backgroundColor: "#53AC9F", opacity: 0.9}}>
                                    <div style={{
                                        fontSize: 24,
                                        fontFamily: "Microsoft YaHei",
                                        fontWeight: 800,
                                        color: "#FFFFFF",
                                        paddingTop: 20
                                    }}>专业能力
                                        <div style={{
                                            width: 30,
                                            height: 2,
                                            backgroundColor: "white",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}></div>
                                        <div style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                            lineHeight: 2,
                                            marginLeft: 30,
                                            marginRight: 30,
                                            marginTop: 20
                                        }}>为加强客户体验，进一步提供全链条服务，本公司搭建专业的提车团队和送车团队，解决干线运输外的同城配送问题。提供全行业最优质的标准化提车服务，好评率高达100%，被业界称为"行业标杆"。
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    fontSize: 24,
                                    fontFamily: "Microsoft YaHei",
                                    fontWeight: 800,
                                    color: "#FFFFFF",
                                    marginTop: "30%"
                                }}>专业能力
                                    <div style={{
                                        width: 30,
                                        height: 2,
                                        backgroundColor: "white",
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    }}></div>
                                </div>

                            </div>
                            <div className="weilaizhanwang" style={{
                                width: 393,
                                height: 257,
                                float: "right",
                                backgroundImage: `url("${FOUR}")`,
                                marginTop: 4
                            }}>
                                <div className="weilaizhanwangsub"
                                     style={{width: "100%", height: "100%", backgroundColor: "#53AC9F", opacity: 0.9}}>
                                    <div style={{
                                        fontSize: 24,
                                        fontFamily: "Microsoft YaHei",
                                        fontWeight: 800,
                                        color: "#FFFFFF",
                                        paddingTop: 20
                                    }}>未来展望
                                        <div style={{
                                            width: 30,
                                            height: 2,
                                            backgroundColor: "white",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}></div>
                                        <div style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                            lineHeight: 2,
                                            marginLeft: 30,
                                            marginRight: 30,
                                            marginTop: 20
                                        }}>车粒子科技将持续秉承着"重服务，保品质，科技赋能的态度服务好客户，输出更多有利于汽车物流领域的产品和服务。
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    fontSize: 24,
                                    fontFamily: "Microsoft YaHei",
                                    fontWeight: 800,
                                    color: "#FFFFFF",
                                    marginTop: "30%"
                                }}>未来展望
                                    <div style={{
                                        width: 30,
                                        height: 2,
                                        backgroundColor: "white", marginLeft: "auto", marginRight: "auto"
                                    }}></div>
                                </div>
                            </div>
                            <div className="chanpinshuchu" style={{
                                width: 393,
                                height: 257,
                                float: "right",
                                backgroundImage: `url("${THREE}")`,
                                marginTop: 4,
                                marginRight: 4
                            }}>
                                <div className="chanpinshuchusub"
                                     style={{width: "100%", height: "100%", backgroundColor: "#53AC9F", opacity: 0.9}}>
                                    <div style={{
                                        fontSize: 24,
                                        fontFamily: "Microsoft YaHei",
                                        fontWeight: 800,
                                        color: "#FFFFFF",
                                        paddingTop: 20
                                    }}>产品输出
                                        <div style={{
                                            width: 30,
                                            height: 2,
                                            backgroundColor: "white",
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}></div>
                                        <div style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                            lineHeight: 2,
                                            marginLeft: 30,
                                            marginRight: 30,
                                            marginTop: 20
                                        }}>公司陆续推出了粒子运车、粒子承运人、粒子运车公众号、粒子运车提车版、oss管理平台等互联网产品，完成对汽车物流行业上下游的线上整合，大大提升各环节效率，赋能物流产业链前后端。
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    fontSize: 24,
                                    fontFamily: "Microsoft YaHei",
                                    fontWeight: 800,
                                    color: "#FFFFFF",
                                    marginTop: "30%"
                                }}>产品输出
                                    <div style={{
                                        width: 30,
                                        height: 2,
                                        backgroundColor: "white", marginLeft: "auto", marginRight: "auto"
                                    }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{fontSize: 36, fontFamily: "Microsoft YaHei", fontWeight: 400, marginTop: 102}}>合作伙伴
                    </div>
                    <div style={{fontSize: 16, fontFamily: "Microsoft YaHei", fontWeight: 400}}>Partners</div>
                    <img src={line} style={{marginTop: 25}}></img>
                    <div style={{marginTop: 50}}>
                        <Partner></Partner>
                    </div>
                </div>
                <div style={{marginTop: 50}}>
                    <ParticleFoot></ParticleFoot>
                </div>
            </div>
        </div>
    }

    constructor(props) {
        super(props);
        this.state = {
            hover1: false,
            hover2: false,
            hover3: false,
            hover4: false,
            hover5: false,
            hover6: false,
            hover7: false,
            hover8: false,
            hover9: false,
            hover10: false,
            hover11: false,
            hover12: false,
            hover13: false,
            wishShow: false,
            pageTitle: "公司简介 -深圳车粒子科技有限公司-汽车托运价格表,哪家好",
            meta: "公司简介"
        }

    }
}

export default Introduce