/**
 * 接口前缀地址
 */
// baseUrl
// 测试环境（连接的生产库，目前带那个生产用）
const baseUrl = 'https://api.app.carticletech.com:19003';
// 正式环境（弃用）
// const baseUrl = 'https://api.app.carticletech.com:9003';

// baseUrl2
// 测试环境
// const baseUrl2 = 'http://dev.carticletech.com:8090'
const baseUrl2 = ""

// baseUrl3
// 测试环境
// const baseUrl3 = 'http://dev.carticletech.com:3000'
// 正式环境
const baseUrl3 = "http://www.carticletech.com"

// baseUrl4
// 测试环境
// const baseUrl4 = 'https://dev.app.carticletech.com:9966'
// 正式环境
const baseUrl4 = "https://shipper.carticletech.com:9966"


export default {
    // placeOrder: `${baseUrl}/wx/order/place/quick`,
    // 修改查价接口
    placeOrder: `${baseUrl4}/particle-cs/shipper/c_order/official/inquiry`,
    // 发送验证码
    getVerifyCode: `${baseUrl4}/particle-cs/shipper/login/verify_code/send`,
    // 登录
    loginWithVerifyCode: `${baseUrl4}/particle-cs/shipper/login/official/login`,

    get24HourOrderInfo: `${baseUrl}/wx/order/intraday`,
    must_know: `${baseUrl2}/qa/list`,
    news_list: `${baseUrl2}/news/list`,
    service_case_list: `${baseUrl2}/service_case/list`,
    baseUrl3,
    defaultTitle: "粒子运车"
}
