import * as React from "react";
import BANNER from "../../assets/img/banner/banner1.jpg"
import Head from "../head/head";
import IMG_FIRST_PAGE_TITLE from "../../assets/img/introduce/WechatIMG274.png";
import IMG_FIRST_PAGE_PHONE from "../../assets/img/introduce/WechatIMG275.png";
import {Link} from "react-router-dom";
import IMG_FIRST_PAGE_BUTTON from "../../assets/img/introduce/WechatIMG276.png";
import ParticleFoot from "../foot/foot";
import BACKGROUND from "../../assets/img/transfer_introduce/图层 5 (1).png"
import BACKGROUND2 from "../../assets/img/transfer_introduce/矩形 19 拷贝 (1).png"
import BACKGROUND3 from "../../assets/img/transfer_introduce/矩形 19 拷贝.png"
import line from "../../assets/img/process/组 3 拷贝 5.png";
import {get_front_page_must_know2} from "../../action/must_know_action";
import {get_front_page_news_2} from "../../action/news_action";
import IMG_CLOCK from "../../assets/img/clock.png";
import api from "../../request/api";
import {Helmet} from "react-helmet";

class TransformIntroduce extends React.Component {
    constructor(props) {
        super(props);
        // console.log(props.match.params)
        // console.log(props.location)
        // console.log(props.location.search)
        let pathIndex = '';
        if (props.location.search) {
            pathIndex = props.location.search.split("=")[1];
        }
        let show1 = true;
        let show2 = false;
        let pageTitle = '';
        let meta = '';
        if (props.match.params) {
            if (props.match.params.name === 'qa') {
                if (!props.match.params.id) {
                    meta = "托运须知"
                    pageTitle = "托运须知 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好"
                }
                show1 = true;
                show2 = false;
            } else if (props.match.params.name === 'news') {
                if (!props.match.params.id) {
                    meta = "最新资讯"
                    pageTitle = "最新资讯 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好"
                }
                show1 = false;
                show2 = true;
            }
        }
        if (props.location.state) {
            if (props.location.state.show === "qa") {
                show1 = true;
                show2 = false;
            } else if (props.location.state.show === "news") {
                show2 = true;
                show1 = false;
            }
        }


        this.state = {
            pathIndex: pathIndex,
            active: 0,
            content: '',
            newsActive: '',
            newsContent: '',
            list: [1, 2, 3, 4, 5, 6, 7, 8],
            newsList: [1, 2, 3, 4],
            show1: show1,
            show2: show2,
            mustKnowData: [],
            newsData: [],
            pageTitle: props.location.state ? props.location.state.pageTitle : pageTitle,
            meta: props.location.state ? props.location.state.meta : meta,
        }
        // console.log(this.state)
    }

    componentWillMount() {
        get_front_page_must_know2(api.baseUrl3 + "/qa/list", (result, msg, datas) => {
                if (result && datas) {
                    {
                        if (this.state.show1) {
                            this.setState({mustKnowData: datas});

                            let item = '';
                            if (this.state.pathIndex) {

                                // let index = this.props.match.params.id.split('.')[0]
                                // index = datas.length - index
                                // item = datas.filter(x => x.title === title)[0]
                                // let index = this.props.match.params.id;
                                let index = datas.length - this.state.pathIndex;
                                item = datas[index];
                                this.setState({
                                    pageTitle: (item !== '') ? item.pageTitle : '粒子运车',
                                    meta: (item !== '') ? item.meta : '',
                                });
                                // for (let i = 0; i < datas.length; i++) {
                                //     if (datas[i].title === title) {
                                this.setState({
                                    active: index,
                                    content: datas[index].content,
                                })
                                // }
                                // }
                            } else {
                                this.setState({
                                    active: 0,
                                    content: datas[0].content,
                                })
                            }
                        }
                    }
                }
            }
        );
        get_front_page_news_2(api.baseUrl3 + "/news/list", (result, msg, datas) => {
                if (result && datas) {
                    if (this.state.show2) {
                        this.setState({newsData: datas});
                        // if (!this.state.pageTitle || this.state.pageTitle === '') {
                        let item = '';
                        if (this.state.pathIndex) {
                            // let index = this.props.match.params.id.split('.')[0]
                            // index = datas.length - index
                            // item = datas.filter(x => x.title === title)[0]
                            // let index = this.props.match.params.id
                            // let index = this.state.pathIndex;
                            let index = datas.length - this.state.pathIndex;
                            item = datas[index];
                            this.setState({
                                pageTitle: (item !== '') ? item.pageTitle : '粒子运车',
                                meta: (item !== '') ? item.meta : '',
                            });
                            // for (let i = 0; i < datas.length; i++) {
                            //     if (datas[i].title === title) {
                            this.setState({
                                newsActive: index,
                                newsContent: datas[index].content,
                            })
                            // }
                            // }
                        } else {
                            this.setState({
                                newsActive: 0,
                                newsContent: datas[0].content,
                            })
                        }
                    }
                }
            }
        );
    }

    handleClick = (number, index, that) => {
        // this.props.history.replace(`/transform/qa/` + number.title + `.html`);
        // this.setState({
        //     active: index,
        //     content: number.content,
        //     pageTitle: number.pageTitle,
        //     meta: number.meta,
        // })
    };
    handleClickNews = (number, index, that) => {
        // this.props.history.replace(`/transform/news/` + number.title + `.html`);
        // this.setState({
        //     newsActive: index,
        //     newsContent: number.content,
        //     pageTitle: number.pageTitle,
        //     meta: number.meta,
        // })
    };

    render() {
        const serviceButtonStyleShow = {
            backgroundColor: '#2EB2A6',
            border: '0px solid rgba(191, 191, 191, 1)',
            display: "flex",
            width: 280,
            height: 60,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 26,
        }
        const serviceButtonStyle = {
            border: '1px solid rgba(191, 191, 191, 1)',
            display: "flex",
            width: 280,
            height: 60,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 26,
        }
        const listItems = this.state.mustKnowData.map((number, index) => {
                return <Link
                    to={'/transform/qa?index=' + (this.state.mustKnowData.length - index)} target="_blank">
                    <li className={`li-${index}`} style={this.state.active + '' === index + '' ? {
                        backgroundImage: `url("${BACKGROUND3}")`,
                        width: 368,
                        height: 80,
                        marginTop: 5,
                        cursor: "pointer",
                    } : {
                        backgroundColor: '#F5F5F5',
                        width: 361,
                        height: 80,
                        marginTop: 5,
                        cursor: "pointer",

                    }}>
                        <div style={this.state.active === index ? {
                            color: "white",
                            fontSize: 18,
                            fontWeight: 400,
                        } : {
                            color: "black",
                            fontSize: 18,
                            fontWeight: 400
                        }}>
                            <p>{number.title}</p>
                        </div>

                    </li>
                </Link>
            }
        )
        const newsListItems = this.state.newsData.map((number, index) =>
            <Link
                to={'/transform/news?index=' + (this.state.newsData.length - index)} target="_blank">
                <li className={`news-li-${number}`} style={this.state.newsActive + '' === index + '' ? {
                    backgroundImage: `url("${BACKGROUND2}")`,
                    width: 368,
                    height: 154,
                    marginTop: 5,
                    cursor: "pointer",
                } : {
                    backgroundColor: '#F5F5F5',
                    width: 361,
                    height: 154,
                    marginTop: 5,
                    cursor: "pointer",
                }}
                    onClick={this.handleClickNews.bind(this, number, index)}>
                    <div style={this.state.newsActive === index ? {
                        color: "white",
                    } : {
                        color: "black",
                    }}>
                        <div style={{
                            // lineHeight: "50px",
                            fontSize: 18,
                            height: 60,
                            fontWeight: 400
                        }}> {number.title}</div>
                        <div style={{display: "flex", flexFlow: "row", marginTop: 63, height: 24}}>
                            <img src={IMG_CLOCK} style={{display: "block", width: 16, height: 16}}/>
                            <div style={{lineHeight: "16px", fontSize: 14, marginLeft: 10}}>
                                {number.date}
                            </div>
                        </div>
                    </div>
                </li>
            </Link>
        )

        return <div style={{maxWidth: 1920, width: "100%", height: 1080, marginRight: "auto", marginLeft: "auto"}}>
            <Helmet>
                <meta name="description" content={this.state.meta}/>
                <meta name="keywords" content={this.state.meta}/>
                <title>{this.state.pageTitle}</title>
            </Helmet>
            <div style={{
                width: "100%",
                height: 660,
                backgroundImage: `url(${BANNER})`,
                position: "relative",
                overflow: "hidden",
                marginLeft: "auto",
                marginRight: "auto",
            }}>
                <Head></Head>
                <div style={{position: "absolute", top: 245, left: 360}}>
                    <img src={IMG_FIRST_PAGE_TITLE}
                         style={{width: 534, height: 66}}/>
                    <br/>
                    <div style={{marginTop: 40, position: "relative"}}>
                        <img src={IMG_FIRST_PAGE_PHONE} style={{width: 50}}/>
                        <div style={{
                            fontSize: 45,
                            position: "absolute",
                            top: -60,
                            left: 60,
                            marginBottom: 0,
                            marginLeft: 10,
                            marginTop: 50,
                            color: "#9B251A",
                            fontWeight: 800,
                            fontFamily: "Microsoft YaHei",
                        }}>400-603-8656
                        </div>
                    </div>
                    <br/>
                    <div style={{cursor: "pointer"}}>
                        <Link to='/place'>
                            <img src={IMG_FIRST_PAGE_BUTTON}
                                 style={{width: 180, height: 54, marginTop: 45,}}/>
                            <div style={{
                                fontSize: 24,
                                position: "absolute",
                                top: 229,
                                left: 45,
                                color: "white",
                                fontFamily: "Microsoft YaHei",
                                fontWeight: 800,
                            }}>我要运车
                            </div>
                        </Link></div>
                </div>
            </div>
            <div style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%", height: 150,
                backgroundColor: "white",
                boxShadow: `1px 4px 6px rgba(0,0,0,0.04)`,
                display: "flex", flexDirection: "row",
                justifyContent: "center", alignItems: "center"
            }}>
                <Link to={{pathname: '/transform/qa', state: {id: 1, show: "qa"}}} target="_blank">
                    <div className="servicebutton"
                         style={this.state.show1 ? serviceButtonStyleShow : serviceButtonStyle}>
                    <span className="buttoncontent" style={this.state.show1 ? {
                        fontSize: 20,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                        color: "white"
                    } : {
                        fontSize: 20,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                    }}>托运须知</span>
                    </div>
                </Link>
                <Link to={{pathname: '/transform/news', state: {id: 1, show: "qa"}}} target="_blank">
                    <div className="servicebutton"
                         style={this.state.show2 ? serviceButtonStyleShow : serviceButtonStyle}>
                    <span className="buttoncontent" style={this.state.show2 ? {
                        fontSize: 20,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                        color: "white"
                    } : {
                        fontSize: 20,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                    }}>最新资讯</span>
                    </div>
                </Link>
            </div>
            <div style={{
                width: "100%",
                height: 1080,
                textAlign: "center",
                backgroundImage: `url("${BACKGROUND}")`
            }}>
                <div style={{display: this.state.show1 ? 'block' : 'none'}}>
                    <div style={{textAlign: "center", marginRight: "auto", marginLeft: "auto", paddingTop: 102}}>
                        <div style={{fontSize: 36, fontFamily: "Microsoft YaHei", fontWeight: 400}}>托运须知</div>
                        <div style={{fontSize: 16, fontFamily: "Microsoft YaHei", fontWeight: 400}}>Shipping
                            instructions
                        </div>
                        <img src={line} style={{marginTop: 25}}></img>
                    </div>
                    <div style={{
                        width: 1200,
                        height: 300,
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: 50,
                    }}>
                        <div style={{
                            float: "left",
                            width: 390,
                            maxHeight: 450,
                            backgroundColor: "white",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}>
                            <ul style={{
                                listStyle: "none",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: 0,
                                width: 361
                            }}>
                                {listItems}
                            </ul>
                        </div>
                        <div style={{
                            float: "right",
                            width: 780,
                            height: 780,
                            backgroundColor: "white",
                            overflowY: "auto",
                        }}>
                            <div className='editor-wrapper' dangerouslySetInnerHTML={{__html: this.state.content}}/>
                        </div>
                    </div>
                </div>
                <div style={{display: this.state.show2 ? 'block' : 'none'}}>
                    <div style={{textAlign: "center", marginRight: "auto", marginLeft: "auto", paddingTop: 102}}>
                        <div style={{fontSize: 36, fontFamily: "Microsoft YaHei", fontWeight: 400}}>最新资讯</div>
                        <div style={{fontSize: 16, fontFamily: "Microsoft YaHei", fontWeight: 400}}>Carticletech
                            Information
                        </div>
                        <img src={line} style={{marginTop: 25}}></img>
                    </div>
                    <div style={{
                        width: 1200,
                        height: 700,
                        marginRight: "auto",
                        marginLeft: "auto",
                        marginTop: 50,
                    }}>
                        <div style={{
                            float: "left",
                            width: 390,
                            maxHeight: 650,
                            backgroundColor: "white",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}>
                            <ul style={{
                                listStyle: "none",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: 0,
                                width: 361
                            }}>
                                {newsListItems}
                            </ul>
                        </div>
                        <div style={{
                            float: "right",
                            width: 780,
                            height: 780,
                            backgroundColor: "white",
                            overflowY: "auto",
                        }}>
                            <div className='editor-wrapper' dangerouslySetInnerHTML={{__html: this.state.newsContent}}/>
                        </div>
                    </div>
                </div>
            </div>
            <ParticleFoot></ParticleFoot>
        </div>
    }
}

export default TransformIntroduce