import React from "react";
import ONE from "../../assets/img/service_advantage/1.png"
import TWO from "../../assets/img/service_advantage/2.png"
import THREE from "../../assets/img/service_advantage/3.png"
import FOUR from "../../assets/img/service_advantage/4.png"
import SIX from "../../assets/img/service_advantage/下载 (6).png"
import EIGHT from "../../assets/img/service_advantage/下载 (8).png"
import NINE from "../../assets/img/service_advantage/下载 (9).png"
import TEN from "../../assets/img/service_advantage/下载 (10).png"
import ELEVEN from "../../assets/img/service_advantage/下载 (11).png"
import BACKGROUND from "../../assets/img/service_advantage/图层 11.png"
import TWELVE from "../../assets/img/service_advantage/下载 (12).png"
import THRTHEEN from "../../assets/img/service_advantage/下载 (13).png"
import FORTEEN from "../../assets/img/service_advantage/下载 (14).png"
import FIFTEEN from "../../assets/img/service_advantage/下载 (15).png"
import SIXTEEN from "../../assets/img/service_advantage/下载 (16).png"
import SEVENTEEN from "../../assets/img/service_advantage/下载 (17).png"
import GUAN_WANG from "../../assets/img/service_advantage/官网首页x.png"
import JIA_GE_TOU_MING from "../../assets/img/service_advantage/车巴巴-价格透明.png"
import DUOBIANCING1 from "../../assets/img/service_advantage/多边形 1 拷贝.png"
import JIA_GE_CHAO_DI from "../../assets/img/service_advantage/组 15.png"
import JIA_GE_YI_ZHI from "../../assets/img/service_advantage/组 14.png"
import ZENG_SONG_BAO_XIAN from "../../assets/img/service_advantage/组 16.png"
import {View} from "antd-mobile";
import Text from "antd-mobile/es/text";
import ReactMarkdown from "react-markdown/with-html";
import ParticleFoot from "../foot/foot";
import IMG_DOCUMENT_1 from "../../assets/img/document/document_1.jpg";
import IMG_DOCUMENT_2 from "../../assets/img/document/document_2.jpg";
import IMG_DOCUMENT_3 from "../../assets/img/document/document_3.jpg";

require("./service_advantage.css")

class ServiceAdvantage extends React.Component {
    render() {
        return <div style={{
            width: "100%", overflowX: "hidden", marginLeft: "auto",
            marginRight: "auto",
        }}>
            <div className='service_advantage' style={{
                width: "100%",
                height: 1080,
                backgroundImage: `url("${BACKGROUND}")`,
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <div style={{paddingTop: 70, height: 170, position: "relative"}}>

                    <div style={{
                        position: "absolute",
                        left: 360,
                        top: 70,
                        width: 32,
                        height: 47,
                        backgroundImage: `url(${ONE})`
                    }}/>
                    <View style={{
                        position: "absolute",
                        left: 412,
                        top: 70,
                    }}>
                        <ReactMarkdown source={this.SI_DA} escapeHtml={false}></ReactMarkdown>
                        <ReactMarkdown source={this.SI_DA_EN} escapeHtml={false}></ReactMarkdown>
                    </View>
                    <div style={{
                        width: 1200,
                        height: 1,
                        backgroundColor: "#E6E6E6",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 73,
                    }}></div>
                </div>
                <div style={{marginTop: 25, width: 1100, marginLeft: "auto", marginRight: "auto"}}>
                    <div style={{marginTop: 10, height: 300, width: 140, float: "left"}}>
                        <div style={{height: 140, width: 140, backgroundImage: `url("${SIX}")`}}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15
                        }}>
                            <Text style={{
                                fontSize: 24,
                                fontWeight: 400,
                                fontFamily: "Microsoft YaHei",
                                color: "#1A1A1A"
                            }}>海量运力</Text>
                        </View>
                        <div style={{
                            width: 22,
                            height: 3,
                            backgroundColor: "#0C5A70",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 20,
                        }}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15,
                            textAlign: "center",
                        }}>
                            <ReactMarkdown source={this.QIAN_JIA} escapeHtml={false}></ReactMarkdown>
                        </View>
                    </div>
                    <div style={{height: 300, width: 140, float: "left", marginLeft: 175,}}>
                        <div style={{height: 140, width: 140, backgroundImage: `url("${EIGHT}")`}}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15
                        }}>
                            <Text style={{
                                fontSize: 24,
                                fontWeight: 400,
                                fontFamily: "Microsoft YaHei",
                                color: "#1A1A1A"
                            }}>领先服务</Text>
                        </View>
                        <div style={{
                            width: 22,
                            height: 3,
                            backgroundColor: "#0C5A70",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 20,
                        }}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15,
                            textAlign: "center",
                        }}>
                            <ReactMarkdown source={this.SHOUCHUANG} escapeHtml={false}></ReactMarkdown>
                        </View>
                    </div>
                    <div style={{height: 300, width: 140, float: "left", marginLeft: 175,}}>
                        <div style={{height: 140, width: 140, backgroundImage: `url("${NINE}")`}}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15
                        }}>
                            <Text style={{
                                fontSize: 24,
                                fontWeight: 400,
                                fontFamily: "Microsoft YaHei",
                                color: "#1A1A1A"
                            }}>安全放心</Text>
                        </View>
                        <div style={{
                            width: 22,
                            height: 3,
                            backgroundColor: "#0C5A70",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 20,
                        }}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15,
                            textAlign: "center",
                        }}>
                            <ReactMarkdown source={this.TwenteeWAN} escapeHtml={false}></ReactMarkdown>
                        </View>
                    </div>
                    <div style={{height: 300, width: 140, float: "left", marginLeft: 175,}}>
                        <div style={{height: 140, width: 140, backgroundImage: `url("${TEN}")`}}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15
                        }}>
                            <Text style={{
                                fontSize: 24,
                                fontWeight: 400,
                                fontFamily: "Microsoft YaHei",
                                color: "#1A1A1A"
                            }}>智能高效</Text>
                        </View>
                        <div style={{
                            width: 22,
                            height: 3,
                            backgroundColor: "#0C5A70",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 20,
                        }}></div>
                        <View style={{
                            height: 23,
                            width: 100,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 15,
                            textAlign: "center",
                        }}>
                            <ReactMarkdown source={this.JINGJIA} escapeHtml={false}></ReactMarkdown>
                        </View>
                    </div>
                </div>
                <div style={{paddingTop: 430, height: 160, position: "relative"}}>

                    <div style={{
                        position: "absolute",
                        left: 360,
                        top: 353,
                        width: 32,
                        height: 47,
                        backgroundImage: `url(${TWO})`
                    }}/>
                    <View style={{
                        position: "absolute",
                        left: 412,
                        top: 353,
                    }}>
                        <ReactMarkdown source={this.WANG_LUO} escapeHtml={false}></ReactMarkdown>
                        <ReactMarkdown source={this.WANG_LUO_EN} escapeHtml={false}></ReactMarkdown>
                    </View>
                    <div style={{
                        width: 1200,
                        height: 1,
                        backgroundColor: "#E6E6E6",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}></div>
                </div>
                <div style={{width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 10}}>
                    <div style={{width: 210, height: 150, float: "left"}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 42,
                                height: 42,
                                backgroundImage: `url(${ELEVEN})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 168, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 10, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>全覆盖</Text>
                            </View>
                            <View style={{marginLeft: 10, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>覆盖网络及运输线路</Text>
                            </View>
                        </div>
                    </div>
                    <div style={{width: 210, height: 150, float: "left", marginLeft: 100}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 40,
                                height: 42,
                                backgroundImage: `url(${TWELVE})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 168, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 10, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>多式联运</Text>
                            </View>
                            <View style={{marginLeft: 10, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>水上运输、铁路运输交织</Text>
                            </View>
                        </div>
                    </div>
                    <div style={{width: 210, height: 150, float: "left", marginLeft: 100}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 41,
                                height: 38,
                                backgroundImage: `url(${THRTHEEN})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 168, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 14, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>专线广</Text>
                            </View>
                            <View style={{marginLeft: 14, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>1000+专线运输公司</Text>
                            </View>
                        </div>
                    </div>
                    <div style={{width: 210, height: 150, float: "left", marginLeft: 100}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 56,
                                height: 28,
                                backgroundImage: `url(${FORTEEN})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 168, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 24, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>运力深</Text>
                            </View>
                            <View style={{marginLeft: 24, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>10000+专线运输车辆</Text>
                            </View>
                        </div>
                    </div>
                </div>
                <div style={{width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 10}}>
                    <div style={{width: 210, height: 150, float: "left"}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 40,
                                height: 40,
                                backgroundImage: `url(${FIFTEEN})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 168, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 12, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>发全国</Text>
                            </View>
                            <View style={{marginLeft: 12, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>覆盖300+城市</Text>
                            </View>
                        </div>
                    </div>
                    <div style={{width: 210, height: 150, float: "left", marginLeft: 100}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 39,
                                height: 38,
                                backgroundImage: `url(${SIXTEEN})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 168, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 14, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>网络效率高</Text>
                            </View>
                            <View style={{marginLeft: 14, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>3000条运输线路网络闭环</Text>
                            </View>
                        </div>
                    </div>
                    <div style={{width: 270, height: 150, float: "left", marginLeft: 100}}>
                        <div style={{width: 42, height: 150, float: "left"}}>
                            <div style={{
                                width: 49,
                                height: 45,
                                backgroundImage: `url(${SEVENTEEN})`,
                                marginTop: 50
                            }}></div>
                        </div>
                        <div style={{width: 228, height: 150, float: "left"}}>
                            <View style={{marginTop: 40, marginLeft: 14, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 24,
                                    fontWeight: 800,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#1A1A1A"
                                }}>五大枢纽</Text>
                            </View>
                            <View style={{marginLeft: 14, textAlign: "left"}}>
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: "Microsoft YaHei",
                                    color: "#4D4D4D"
                                }}>五大核心枢纽（北京、上海、广州、成都、青岛</Text>
                            </View>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: 350, height: 170, position: "relative"}}>

                    <div style={{
                        position: "absolute",
                        left: 360,
                        top: 350,
                        width: 32,
                        height: 47,
                        backgroundImage: `url(${THREE})`
                    }}/>
                    <View style={{
                        position: "absolute",
                        left: 412,
                        top: 350,
                    }}>
                        <ReactMarkdown source={this.JIAGE} escapeHtml={false}></ReactMarkdown>
                        <ReactMarkdown source={this.JIAGE_EN} escapeHtml={false}></ReactMarkdown>
                    </View>
                    <div style={{
                        width: 1200,
                        height: 1,
                        backgroundColor: "#E6E6E6",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 73,
                    }}>
                    </div>
                </div>

                {/*<div style={{*/}
                {/*    width: 1920,*/}
                {/*    height: 1080,*/}
                {/*    marginTop: 18,*/}
                {/*    backgroundImage: `url("${BACKGROUND}")`,*/}
                {/*}}>*/}
                <div style={{paddingTop: 50, marginTop: 60}}>
                    <div style={{height: 400, position: "relative"}}>
                        <div style={{
                            width: 437,
                            height: 436,
                            backgroundImage: `url("${GUAN_WANG}")`,
                            marginLeft: "auto",
                            marginRight: "auto",
                            position: "relative",
                        }}>
                            <div style={{position: "absolute", width: 146, height: 58, right: 386, top: 67}}>
                                <div style={{position: "relative"}}>
                                    <div style={{
                                        position: "absolute",
                                        width: 73,
                                        height: 73,
                                        top: 0,
                                        right: 0,
                                        backgroundImage: `url("${JIA_GE_TOU_MING}")`
                                    }}></div>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 3,
                                        right: 66,
                                    }}>
                                        <Text style={{
                                            fontSize: 16,
                                            fontWeight: 800,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#1A1A1A"
                                        }}>价格透明</Text>
                                    </View>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 28,
                                        right: 85,
                                        textAlign: "right"
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#666666",
                                            lineHeight: 0,
                                        }}>无隐形消费， 所有费用写进合同</Text>
                                    </View>
                                </div>
                            </div>
                            <div style={{position: "absolute", width: 146, height: 58, left: 384, top: 67}}>
                                <div style={{position: "relative"}}>
                                    <div style={{
                                        position: "absolute",
                                        width: 73,
                                        height: 73,
                                        top: 0,
                                        left: 0,
                                        backgroundImage: `url("${JIA_GE_CHAO_DI}")`
                                    }}></div>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 3,
                                        left: 67,
                                    }}>
                                        <Text style={{
                                            fontSize: 16,
                                            fontWeight: 800,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#1A1A1A"
                                        }}>价格超低</Text>
                                    </View>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 28,
                                        left: 85,
                                        textAlign: "left"
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#666666",
                                            lineHeight: 0,
                                        }}>低于行业市场价格 低利润获取客户</Text>
                                    </View>
                                </div>
                            </div>
                            <div style={{position: "absolute", width: 146, height: 58, right: 386, top: 293}}>
                                <div style={{position: "relative"}}>
                                    <div style={{
                                        position: "absolute",
                                        width: 73,
                                        height: 73,
                                        top: 0,
                                        right: 0,
                                        backgroundImage: `url("${JIA_GE_YI_ZHI}")`
                                    }}></div>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 3,
                                        right: 66,
                                    }}>
                                        <Text style={{
                                            fontSize: 16,
                                            fontWeight: 800,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#1A1A1A"
                                        }}>价格一致</Text>
                                    </View>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 28,
                                        right: 85,
                                        textAlign: "right"
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#666666",
                                            lineHeight: 0,
                                        }}>运输过程中,无其他添加费用</Text>
                                    </View>
                                </div>
                            </div>
                            <div style={{position: "absolute", width: 146, height: 58, left: 384, top: 293}}>
                                <div style={{position: "relative"}}>
                                    <div style={{
                                        position: "absolute",
                                        width: 73,
                                        height: 73,
                                        top: 0,
                                        left: 0,
                                        backgroundImage: `url("${ZENG_SONG_BAO_XIAN}")`
                                    }}></div>
                                    <View style={{
                                        position: "absolute",
                                        width: 100,
                                        height: 73,
                                        top: 3,
                                        left: 67,
                                    }}>
                                        <Text style={{
                                            fontSize: 16,
                                            fontWeight: 800,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#1A1A1A"
                                        }}>赠送保险</Text>
                                    </View>
                                    <View style={{
                                        position: "absolute",
                                        width: 200,
                                        height: 73,
                                        top: 28,
                                        left: 85,
                                        textAlign: "left"
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            fontWeight: 400,
                                            fontFamily: "Microsoft YaHei",
                                            color: "#666666",
                                            lineHeight: 0,
                                        }}>每辆车赠送20万运输保险，独立保单，区别于其他货运险</Text>
                                    </View>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height: 80, position: "relative"}}>
                    <div style={{
                        position: "absolute",
                        left: 360,
                        top: 70,
                        width: 32,
                        height: 47,
                        backgroundImage: `url(${FOUR})`
                    }}/>
                    <View style={{
                        position: "absolute",
                        left: 412,
                        top: 70,
                    }}>
                        <ReactMarkdown source={this.SI_DA} escapeHtml={false}></ReactMarkdown>
                        <ReactMarkdown source={this.SI_DA_EN} escapeHtml={false}></ReactMarkdown>
                    </View>
                </div>
                <div style={{
                    width: 1200,
                    height: 1,
                    backgroundColor: "#E6E6E6",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 73,
                }}></div>
                <div style={{height: 450, width: 1200, marginLeft: "auto", marginRight: "auto"}}>
                    <div style={{float: "left", marginTop: 45}}>
                        <div style={{
                            width: 566,
                            height: 74,
                            backgroundImage: `url("${DUOBIANCING1}")`,
                            position: "relative"
                        }}>
                            <View style={{position: "absolute", top: 10, left: 30}}>
                                <Text style={{fontSize: 35, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    1
                                </Text>
                            </View>
                            <View style={{position: "absolute", top: 20, left: 90}}>
                                <Text style={{fontSize: 18, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    自营团队：区别其他物流加盟方式，所有驻点团队自营
                                </Text>
                            </View>
                        </div>
                        <div style={{
                            width: 566,
                            height: 74,
                            backgroundImage: `url("${DUOBIANCING1}")`,
                            marginTop: 25,
                            position: "relative"
                        }}>
                            <View style={{position: "absolute", top: 10, left: 30}}>
                                <Text style={{fontSize: 35, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    2
                                </Text>
                            </View>
                            <View style={{position: "absolute", top: 20, left: 90}}>
                                <Text style={{fontSize: 18, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    太保合作：太平洋保险公司合作，专业的汽车运输保险
                                </Text>
                            </View>
                        </div>
                        <div style={{
                            width: 566,
                            height: 74,
                            backgroundImage: `url("${DUOBIANCING1}")`,
                            marginTop: 25,
                            position: "relative"
                        }}>
                            <View style={{position: "absolute", top: 10, left: 30}}>
                                <Text style={{fontSize: 35, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    3
                                </Text>
                            </View>
                            <View style={{position: "absolute", top: 20, left: 90}}>
                                <Text style={{fontSize: 18, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    运输资质：运输资质齐全，具备道路运输许可证、营业执照
                                </Text>
                            </View>
                        </div>
                        <div style={{
                            width: 566,
                            height: 74,
                            backgroundImage: `url("${DUOBIANCING1}")`,
                            marginTop: 25,
                            position: "relative"
                        }}>
                            <View style={{position: "absolute", top: 10, left: 30}}>
                                <Text style={{fontSize: 35, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    4
                                </Text>
                            </View>
                            <View style={{position: "absolute", top: 20, left: 90}}>
                                <Text style={{fontSize: 18, fontFamily: "Microsoft YaHei", color: "white"}}>
                                    专业协议：正规运输协议，条款清晰，避免纠纷
                                </Text>
                            </View>
                        </div>
                    </div>
                    <div style={{float: "right", position: "relative", width: 600, height: 450}}>
                        <div style={{
                            position: "absolute",
                            left: this.state.currentSafePage === 0 ? "50%" : (this.state.currentSafePage === 1 ? 105 : 476),
                            width: this.state.currentSafePage === 0 ? 230 : 186,
                            zIndex: this.state.currentSafePage === 0 ? 4 : 3,
                            top: this.state.currentSafePage === 0 ? 30 : 50,
                            marginLeft: this.state.currentSafePage === 0 ? -40 : 0,
                            transitionProperty: "all",
                            transitionDuration: "0.5s",
                            transitionTimingFunction: "ease",
                            backgroundImage: `url(${IMG_DOCUMENT_1})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}>
                            <div style={{
                                backgroundColor: "#000",
                                zIndex: 0,
                                width: "100%",
                                height: this.state.currentSafePage !== 0 ? 272 : 300,
                                cursor: "pointer",
                                opacity: this.state.currentSafePage !== 0 ? 0.3 : 0,
                                transitionProperty: "all",
                                transitionDuration: "0.5s",

                            }} onClick={(e) => this.setState({currentSafePage: 0})}/>
                            <span style={{
                                color: this.state.currentSafePage === 0 ? "#000000" : "#B0B0B0",
                                display: "block",
                                textAlign: "center",
                                fontSize: 22,
                                marginTop: 32,
                                transitionProperty: "all",
                                transitionDuration: "0.5s",
                            }}>运输资质</span>
                        </div>
                        <div style={{
                            position: "absolute",
                            left: this.state.currentSafePage === 1 ? "50%" : (this.state.currentSafePage === 2 ? 105 : 476),
                            width: this.state.currentSafePage === 1 ? 230 : 186,
                            zIndex: this.state.currentSafePage === 1 ? 4 : 3,
                            top: this.state.currentSafePage === 1 ? 30 : 50,
                            marginLeft: this.state.currentSafePage === 1 ? -40 : 0,
                            transitionProperty: "all",
                            transitionDuration: "0.5s",
                            transitionTimingFunction: "ease",
                            backgroundImage: `url(${IMG_DOCUMENT_2})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}>
                            <div style={{
                                backgroundColor: "#000",
                                zIndex: 0,
                                width: "100%",
                                height: this.state.currentSafePage !== 1 ? 272 : 300,
                                cursor: "pointer",
                                opacity: this.state.currentSafePage !== 1 ? 0.3 : 0,
                                transitionProperty: "all",
                                transitionDuration: "0.5s"
                            }} onClick={(e) => this.setState({currentSafePage: 1})}/>
                            <span style={{
                                color: this.state.currentSafePage === 1 ? "#000000" : "#B0B0B0",
                                display: "block",
                                textAlign: "center",
                                fontSize: 22,
                                marginTop: 32,
                                transitionProperty: "all",
                                transitionDuration: "0.5s"
                            }}>太保合作</span>
                        </div>
                        <div style={{
                            position: "absolute",
                            left: this.state.currentSafePage === 2 ? "50%" : (this.state.currentSafePage === 0 ? 105 : 476),
                            width: this.state.currentSafePage === 2 ? 230 : 186,
                            zIndex: this.state.currentSafePage === 2 ? 4 : 3,
                            top: this.state.currentSafePage === 2 ? 30 : 50,
                            marginLeft: this.state.currentSafePage === 2 ? -40 : 0,
                            transitionProperty: "all",
                            transitionDuration: "0.5s",
                            transitionTimingFunction: "ease",
                            backgroundImage: `url(${IMG_DOCUMENT_3})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                        }}>
                            <div style={{
                                backgroundColor: "#000",
                                zIndex: 0,
                                width: "100%",
                                height: this.state.currentSafePage !== 2 ? 272 : 315,
                                cursor: "pointer",
                                opacity: this.state.currentSafePage !== 2 ? 0.3 : 0,
                                transitionProperty: "all",
                                transitionDuration: "0.5s"
                            }} onClick={(e) => this.setState({currentSafePage: 2})}/>
                            <span style={{
                                color: this.state.currentSafePage === 2 ? "#000000" : "#B0B0B0",
                                display: "block",
                                textAlign: "center",
                                fontSize: 22,
                                marginTop: 32,
                                transitionProperty: "all",
                                transitionDuration: "0.5s"
                            }}>专业运输合同</span>
                        </div>
                    </div>
                </div>
                <ParticleFoot></ParticleFoot>
            </div>
        </div>
    }

    static STATE_STRUCT = () => {
        return {
            currentSafePage: 0,
        }
    };

    state = ServiceAdvantage.STATE_STRUCT();
    SI_DA = "<P style='color: #0C5A70;font-size: 24px;font-family: Microsoft YaHei;font-weight: bold;margin-bottom: 0'>四大核心优势</P>"
    SI_DA_EN = "<P style='color: #0C5A70;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0;opacity: 0.7'>Four core strengths</P>"
    WANG_LUO = "<P style='color: #0C5A70;font-size: 24px;font-family: Microsoft YaHei;font-weight: bold;margin-bottom: 0'>网络优势</P>"
    WANG_LUO_EN = "<P style='color: #0C5A70;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0;opacity: 0.7'>Network advantages</P>"
    JIAGE = "<P style='color: #0C5A70;font-size: 24px;font-family: Microsoft YaHei;font-weight: bold;margin-bottom: 0'>价格优势</P>"
    JIAGE_EN = "<P style='color: #0C5A70;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0;opacity: 0.7'>Price advantages</P>"
    QIAN_JIA = "<P style='color: #4D4D4D;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0'>千家物流</br>万辆板车</br>多式联运</br></P>"
    SHOUCHUANG = "<P style='color: #4D4D4D;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0'>首创服务标准</br>线上全程可视</br>24H贴心服务</br></P>"
    SHOUCHUANG = "<P style='color: #4D4D4D;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0'>首创服务标准</br>线上全程可视</br>24H贴心服务</br></P>"
    TwenteeWAN = "<P style='color: #4D4D4D;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0'>20万运输保险</br>太保平台对接</br>客诉先行赔付</br></P>"
    JINGJIA = "<P style='color: #4D4D4D;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;margin-bottom: 0'>竞价订单模型</br>物流管理系统</br>智能线路优化</br></P>"
}

export default ServiceAdvantage