import React from 'react';

import * as Scroll from 'react-scroll';
import IMG_QR_CODE_PUBLIC from "./assets/img/qr_code_public.jpg";
import IMG_QR_CODE_DOWNLOAD from "./assets/img/qr_code.png";
import IMG_GREEN_LINE from "./assets/img/green_line.png";
import IMG_BG_PARTNER from './assets/img/bg/background_partner.jpg'
import IMG_BG_TRANSIT_SERVICE from './assets/img/bg/background_transit_service.jpg'
import IMG_BG_GOODNESS from './assets/img/bg/background_goodness.jpg'
import IMG_BG_QUERY_PRICE from './assets/img/bg/background_query_price.jpg'
import IMG_BG_NETWORK from './assets/img/bg/background_network.jpg'
import IMG_BG_CASE from './assets/img/bg/background_case.jpg'
import IMG_BG_SAFE from './assets/img/bg/background_safe.jpg'
import IMG_BG_FIRST_PAGE from './assets/img/bg/background_first_page.jpg'
import IMG_BG_FIRST_PAGE_2 from './assets/img/bg/background_first_page_2.jpg'
import IMG_BG_FIRST_PAGE_3 from './assets/img/bg/background_first_page_3.jpg'
import background_first_page_4 from './assets/img/bg/background_first_page_4.jpg'
import IMG_BG_QA from './assets/img/bg/background_must_know.jpg'
import IMG_BG_APP from './assets/img/bg/background_app.jpg'
import IMG_WHITE_LINE from "./assets/img/white_line.png";
import IMG_BG_ABOUT_US from "./assets/img/bg/background_about_us.jpg";
import IMG_BG_SERVICE from './assets/img/bg/background_service.jpg';
import IMG_SPLIT_LOGO from './assets/img/split_logo.png';
import IMG_SPLIT_LOGO_WHITE from './assets/img/split_logo_white.png';
import IMG_LOGO_WITH_BACKGROUND from "./assets/img/logo/logo_with_background.jpg";
import IMG_PROCESS_BACKGROUND from './assets/img/bg/background_process.jpg';
import IMG_APP from './assets/img/app.png';
import IMG_BUTTON_IOS_DOWNLOAD from './assets/img/button/button_android_download.png';
import IMG_BUTTON_ANDROID_DOWNLOAD from './assets/img/button/button_ios_download.png';
import IMG_ICON_ARROW_DOWN_GRAY from './assets/img/icon/icon_arrow_down_gray.png';
import IMG_ICON_PHONE_GRAY from './assets/img/icon/icon_phone_gray.png';
import IMG_ICON_POINTER_RECEIVE_GRAY from './assets/img/icon/icon_pointer_receive_gray.png';
import IMG_ICON_EARTH from './assets/img/icon/icon_earth.png';
import IMG_ICON_SIGN from './assets/img/icon/icon_sign.png';
import IMG_ICON_POINTER from './assets/img/icon/icon_pointer.png';
import IMG_ICON_CAR from './assets/img/icon/icon_car.png';
import IMG_ICON_HUB from './assets/img/icon/icon_hub.png';
import IMG_ICON_BUILDING from './assets/img/icon/icon_building.png';
import IMG_ICON_CIRCLE from './assets/img/icon/icon_circle.png';
import IMG_ICON_CAR_GRAY from './assets/img/icon/icon_car_gray.png';
import IMG_ICON_TRUCK_WITH_PEOPLE_GREEN from './assets/img/icon/icon_truck_with_people_green.png';
import IMG_ICON_TRUCK_ARROW_GREEN from './assets/img/icon/icon_truck_arrow_green.png';
import IMG_ICON_BUILDING_GREEN from './assets/img/icon/icon_building_green.png';
import IMG_ICON_CAR_GREEN from './assets/img/icon/icon_car_green.png';

import IMG_ICON_POINTER_UP from './assets/img/icon/icon_pointer_up.png';
import IMG_ICON_CHECK_WHITE from './assets/img/icon/icon_check_white.png';
import IMG_ICON_CLOUD_WHITE from './assets/img/icon/icon_cloud_white.png';
import IMG_ICON_HEART_WHITE from './assets/img/icon/icon_heart_white.png';
import IMG_ICON_SHIELD_WHITE from './assets/img/icon/icon_shield_white.png';
import IMG_ICON_TRUCK_WHITE from './assets/img/icon/icon_truck_white.png';
import IMG_ICON_REAL_TIME_LABEL from './assets/img/icon/icon_realtime_label.png';
import IMG_FIRST_PAGE_TITLE from './assets/img/firt_page_title.png';
import IMG_FIRST_PAGE_PHONE from './assets/img/first_page_phone.png';
import IMG_FIRST_PAGE_TITLE2 from "./assets/img/introduce/WechatIMG274.png";
import IMG_FIRST_PAGE_PHONE2 from "./assets/img/introduce/WechatIMG275.png";
import IMG_FIRST_PAGE_BUTTON2 from "./assets/img/introduce/WechatIMG276.png";
import IMG_FIRST_PAGE_BUTTON from './assets/img/first_page_button.png';
import { Button, message } from "antd";
import { place_order, get_24h_order_info, getVerifyCode, place_order_withVerifyCode } from "./action/order_action";
import { get_front_page_cases } from "./action/case_action";
import { get_front_page_must_know } from "./action/must_know_action";
import { get_front_page_news } from "./action/news_action";
import AddressChooser from "./component/address_chooser";
import ICON_LOADING_GREEN from "./assets/img/icon/icon_loading.png";
import IMG_MAP from './assets/img/map.png';
import IMG_TRUCK from './assets/img/truck.png';
import IMG_PROCESS_FIRST from './assets/img/process/process_first.png';
import IMG_PROCESS_SECOND from './assets/img/process/process_second.png';
import IMG_PROCESS_THIRD from './assets/img/process/process_third.png';
import IMG_DOCUMENT_1 from './assets/img/document/document_1.jpg';
import IMG_DOCUMENT_2 from './assets/img/document/document_2.jpg';
import IMG_DOCUMENT_3 from './assets/img/document/document_3.jpg';
import IMG_SERVICE_1 from './assets/img/service/service_1.jpg';
import IMG_SERVICE_2 from './assets/img/service/service_2.jpg';
import IMG_SERVICE_3 from './assets/img/service/service_3.jpg';
import IMG_SERVICE_4 from './assets/img/service/service_4.jpg';
import IMG_SERVICE_SPACE_1 from './assets/img/service_space/1.jpg';
import IMG_SERVICE_SPACE_2 from './assets/img/service_space/2.jpg';
import IMG_SERVICE_SPACE_3 from './assets/img/service_space/3.jpg';
import IMG_SERVICE_SPACE_4 from './assets/img/service_space/4.jpg';
import IMG_PROCESS_1 from './assets/img/process/process_1.png';
import IMG_PROCESS_2 from './assets/img/process/process_2.png';
import IMG_PROCESS_3 from './assets/img/process/process_3.png';
import IMG_PROCESS_4 from './assets/img/process/process_4.png';
import IMG_PROCESS_5 from './assets/img/process/process_5.png';
import IMG_PROCESS_6 from './assets/img/process/process_6.png';
import IMG_PROCESS_7 from './assets/img/process/process_7.png';
import IMG_PROCESS_8 from './assets/img/process/process_8.png';
import IMG_PROCESS_STEP from './assets/img/process/process_step.png';
import IMG_CASE_ITEM_BACKGROUND from './assets/img/bg/background_case_item.jpg';
import IMG_QUESTION from './assets/img/question.png';
import IMG_ANSWER from './assets/img/answer.png';
import IMG_MORE_CLICK from './assets/img/more_click.png';
import IMG_MORE_UN_CLICK from './assets/img/more_unclick.png';
import IMG_CLOCK from './assets/img/clock.png';
import IMG_KEY from './assets/img/img_key.png';
import Head from "./component/head/head";
import Partner from "./component/partner/partner";
import ParticleFoot from "./component/foot/foot";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class AppV2 extends React.Component {
  static STATE_STRUCT = () => {
    return {
      verifyCodeFlag: true,
      verifyCodeCount: 0,
      verifyCodeCountInit: 60,
      currentPageIndex: 1,
      showSenderAddressChooser: false,
      senderAddress: {},
      showReceiverAddressChooser: false,
      receiveAddress: {},
      phone: "",
      verifyCode: "",
      showLoading: false,
      showModal: false,
      searchValueSender: "",
      searchValueReceive: "",
      currentFrontPage: 0,
      currentSafePage: 0,

      frontPageTimer: null,
      realTimeOrderTimer: null,
      realTimeOrderLeftData: [],
      realTimeOrderRightData: [],
      realTimeOrderLeftIndex: 0,
      realTimeOrderRightIndex: 0,

      caseData: [],
      currentCaseIndex: 0,

      currentMustKnowNewsIndex: 0,
      mustKnowData: [],
      newsData: [],
      pageTitle: "车粒子科技-轿运车公司-自驾游汽车-私家车-二手车-汽车异地运输-门到门运输汽车",
      meta: "深圳车粒子科技有限公司是全国领先的互联网汽车物流公司，轿运车公司。从事门到门运输汽车、汽车异地运输、自驾游汽车运输、二手车运输、私家车运输、汽车托运、轿车托运、邮寄汽车、运车等托运服务，运输汽车长期合作，范围覆盖新能源汽车、小轿车、SUV、商务车、货运车等多种车型。业务范围覆盖北京、上海、广州、深圳、杭州、重庆、西安、四川等地。",
      keyWordsMeta: "车粒子科技,粒子运车,轿运车公司,门到门运输汽车,自驾游汽车运输,汽车异地运输,二手车运输,私家车运输,汽车托运,轿车托运,汽车物流,邮寄汽车,运汽车,运车,运输汽车长期合作",
    }
  };

  state = AppV2.STATE_STRUCT();

  componentDidMount() {

    get_24h_order_info((result, msg, data) => {
      if (result) {
        if (data && data.length > 0) {
          let leftData = [];
          let rightData = [];
          data.forEach((item, index) => {
            item.time = this.getRandomTime();
            (index % 2 === 0 ? leftData : rightData).push(item);
          });
          this.setState({ realTimeOrderLeftData: leftData, realTimeOrderRightData: rightData });
        }
      }
    });
    this.setState({
      frontPageTimer: setInterval(() => {
        if (this.state.currentFrontPage === 3) {
          this.setState({
            currentFrontPage: 0
          })
        } else {
          this.setState({
            currentFrontPage: this.state.currentFrontPage + 1
          })
        }
      }, 4000)
    });
    this.setState({
      frontPageTimer: setInterval(() => {
        if (this.state.realTimeOrderLeftData && this.state.realTimeOrderLeftData.length > 0)
          this.setState({
            realTimeOrderLeftIndex: this.state.realTimeOrderLeftIndex + 1 >= this.state.realTimeOrderLeftData.length ? 0 : this.state.realTimeOrderLeftIndex + 1,
          });
        if (this.state.realTimeOrderRightData && this.state.realTimeOrderRightData.length > 0)
          this.setState({
            realTimeOrderRightIndex: this.state.realTimeOrderRightIndex + 1 >= this.state.realTimeOrderRightData.length ? 0 : this.state.realTimeOrderRightIndex + 1,
          });
      }, 3000)
    });

    get_front_page_cases((result, msg, data) => {
      if (result && data) {
        this.setState({ caseData: data });
      }
    });

    get_front_page_must_know((result, msg, data) => {
      if (result && data) {
        this.setState({ mustKnowData: data });
      }
    });

    get_front_page_news((result, msg, data) => {
      if (result && data) {
        this.setState({ newsData: data });
      }
    });
  }

  componentWillUnmount() {
    if (this.state.frontPageTimer != null) {
      clearInterval(this.state.frontPageTimer);
    }
    if (this.state.realTimeOrderTimer != null) {
      clearInterval(this.state.realTimeOrderTimer);
    }
    clearInterval(this.timer);
  }


  createOrder = (data) => {
    if (data && data.length !== 0) {
      return data.map((item) => {
        return (<div className="transition"
          style={{ lineHeight: "21px" }}>始发城市:&nbsp;{item.originCityName}&nbsp;&nbsp;&nbsp;&nbsp;到达城市:&nbsp;{item.deliverCityName}&nbsp;&nbsp;&nbsp;&nbsp;时间:&nbsp;{item.time} </div>)
      })
    } else
      return (<div />)
  };

  getRandomTime = () => {
    let number = this.getRandomNumberByRange(1, 10);
    return number <= 5 ? this.getRandomNumberByRange(1, 23) + "小时前" : this.getRandomNumberByRange(1, 59) + "分钟前";
  };

  getRandomNumberByRange = (start, end) => {
    return Math.floor(Math.random() * (end - start) + start)
  };

  changPage = (index) => {
    this.setState({ currentPageIndex: index });
  };

  createContentFrontPage = () => {
    return (
      <div style={{ width: "100%", height: 660, position: "relative", overflow: "hidden" }}>
        <Head></Head>
        <div style={{
            height: "100%",
            position: "absolute",
            zIndex: 2,
            left: "50%",
            marginLeft: -960,
            cursor: 'pointer',
            display: this.state.currentFrontPage === 0 ? 'block' : 'none'

          }}
          onClick={() => window.open('https://mp.weixin.qq.com/s/0oqrd_-5UwQ2InZjH4OPjQ')}
        >
          <img className="opacityTransition" src={background_first_page_4} style={{
            display: 'block',
            width: '100%',
            height: '100%'
          }} />
        </div>


        <img className="opacityTransition" src={IMG_BG_FIRST_PAGE} style={{
          height: "100%",
          position: "absolute",
          zIndex: -1,
          left: "50%",
          marginLeft: -960,
          display: this.state.currentFrontPage === 1 ? 'block' : 'none'
        }} />
        <img className="opacityTransition" src={IMG_BG_FIRST_PAGE_2} style={{
          height: "100%",
          position: "absolute",
          zIndex: -1,
          left: "50%",
          marginLeft: -960,
          display: this.state.currentFrontPage === 2 ? 'block' : 'none'
        }} />
        <img className="opacityTransition" src={IMG_BG_FIRST_PAGE_3} style={{
          height: "100%",
          position: "absolute",
          zIndex: -1,
          left: "50%",
          marginLeft: -960,
          display: this.state.currentFrontPage === 3 ? 'block' : 'none'
        }} />
        <div className="opacityTransition" style={{
          width: 1200,
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          marginTop: 280,
          display: this.state.currentFrontPage === 1 ? 'block' : 'none'
        }}>
          <img src={IMG_FIRST_PAGE_TITLE2}
            style={{ width: 534, height: 66 }} />
          <br />
          <div style={{ marginTop: 40, position: "relative" }}>
            <img src={IMG_FIRST_PAGE_PHONE2} style={{ width: 50 }} />
            <div style={{
              fontSize: 45,
              position: "absolute",
              top: -60,
              left: 60,
              marginBottom: 0,
              marginLeft: 10,
              marginTop: 50,
              color: "#9B251A",
              fontWeight: 800,
              fontFamily: "Microsoft YaHei",
            }}>400-603-8656
            </div>
          </div>
          <br />
          <div style={{ cursor: "pointer" }}>
            <Link to='/place'>
              <img src={IMG_FIRST_PAGE_BUTTON2}
                style={{ width: 180, height: 54, marginTop: 45, }} />
              <div style={{
                fontSize: 24,
                position: "absolute",
                top: 229,
                left: 45,
                color: "white",
                fontFamily: "Microsoft YaHei",
                fontWeight: 800,
              }}>我要运车
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  };
  createContentProduct = () => (
    <div style={{ width: "100%", height: 615, position: "relative", overflow: "hidden" }}>
      <img src={IMG_BG_QUERY_PRICE}
        style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
      <span style={{
        paddingTop: 100,
        color: "#1A1A1A",
        display: "block",
        fontSize: 36,
        textAlign: "center",
        fontWeight: "Bold"
      }}>专属运输方案</span>
      <span style={{
        paddingTop: 15,
        color: "#4D4D4D",
        display: "block",
        fontSize: 16,
        textAlign: "center",
        fontWeight: "Bold"
      }}>Exclusive Transportation Plan</span>
      <img src={IMG_SPLIT_LOGO} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
      <div style={{ width: 1200, marginLeft: "auto", marginRight: "auto", position: "relative", marginTop: 53 }}>
        <div style={{ position: "absolute", right: 0, width: 172, cursor: "pointer" }} onClick={(e) => {
          console.log(this.state.verifyCodeFlag)
          if (!this.state.verifyCodeFlag) {
            return;
          }
          if (!this.state.senderAddress.code || this.state.senderAddress.name !== this.state.searchValueSender) {
            message.error("请选择发车地!");
          } else if (!this.state.receiveAddress.code || this.state.receiveAddress.name !== this.state.searchValueReceive) {
            message.error("请选择收车地!");
          } else if (!this.state.phone) {
            message.error("请输入联系人!");
          } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
            message.error("联系人请输入正确的手机号!");
          } else {
            getVerifyCode(this.state.phone, (result, msg) => {
              if (result) {
                message.info("验证码已发送至您手机，请注意查收。")
                this.timer = setInterval(() => {
                  if (this.state.verifyCodeCount === 0) {
                    if (this.state.verifyCodeFlag) {
                      this.setState({ verifyCodeFlag: false, verifyCodeCount: 60 });
                    } else {
                      this.setState({ verifyCodeFlag: true, verifyCodeCount: 0 });
                      clearInterval(this.timer)
                    }
                  } else {
                    this.setState({
                      verifyCodeFlag: false,
                      verifyCodeCount: this.state.verifyCodeCount - 1
                    });
                  }

                }, 1000)
              } else {
                message.info(msg);
              }
            })
          }
        }}>

          <div style={{
            width: "100%",
            position: "absolute",
            height: 52,
            backgroundColor: this.state.verifyCodeFlag ? "#2EB2A6" : "gray",
            lineHeight: "52px",
            textAlign: "center",
            color: "white",
            fontSize: 18
          }}>获取验证码{!this.state.verifyCodeFlag ? `(${this.state.verifyCodeCount})` : ""}
          </div>
        </div>
        <div style={{
          width: 968,
          position: "absolute",
          right: 172,
          height: 52,
          border: "1px solid rgba(46,178,166,1)",
          background: "white"
        }}>
          <div onClick={(e) => this.setState({
            showSenderAddressChooser: !this.state.showSenderAddressChooser,
            showReceiverAddressChooser: false
          })} style={{ height: "100%", width: 241, float: "left", position: "relative", cursor: "pointer" }}>
            <img src={IMG_ICON_CAR_GRAY} style={{ width: 19, marginTop: 18, marginLeft: 13, display: "block" }} />
            <input value={this.state.searchValueSender}
              onChange={(e) => this.setState({ searchValueSender: e.target.value })} style={{
                width: 170,
                display: "block",
                top: 13,
                left: 42,
                color: "#000000",
                position: "absolute",
                fontSize: 16,
                backgroundColor: "transparent",
                appearance: "none",
                border: "none",
                outline: "none"
              }} type="text" placeholder="发车地" />
            <img src={IMG_ICON_ARROW_DOWN_GRAY}
              style={{ position: "absolute", width: 12, top: "50%", marginTop: -2, right: 10 }} />
          </div>
          <div style={{ background: "#2EB2A6", width: 1, height: 27, marginTop: 13, float: "left" }} />
          <div onClick={(e) => this.setState({
            showReceiverAddressChooser: !this.state.showReceiverAddressChooser,
            showSenderAddressChooser: false
          })} style={{ height: "100%", width: 241, float: "left", position: "relative", cursor: "pointer" }}>
            <img src={IMG_ICON_POINTER_RECEIVE_GRAY}
              style={{ width: 15, marginTop: 17, marginLeft: 13, display: "block" }} />
            <input value={this.state.searchValueReceive}
              onChange={(e) => this.setState({ searchValueReceive: e.target.value })} style={{
                width: 170,
                display: "block",
                top: 13,
                left: 42,
                color: "#000000",
                position: "absolute",
                fontSize: 16,
                backgroundColor: "transparent",
                appearance: "none",
                border: "none",
                outline: "none"
              }} type="text" placeholder="收车地" />
            <img src={IMG_ICON_ARROW_DOWN_GRAY}
              style={{ position: "absolute", width: 12, top: "50%", marginTop: -2, right: 10 }} />
          </div>
          <div style={{ background: "#2EB2A6", width: 1, height: 27, marginTop: 13, float: "left" }} />
          <div style={{ height: "100%", width: 241, float: "left", position: "relative" }}>
            <img src={IMG_ICON_PHONE_GRAY}
              style={{ width: 17, marginTop: 18, marginLeft: 13, display: "block" }} />
            <input value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} style={{
              width: 170,
              display: "block",
              top: 13,
              left: 42,
              color: "#000000",
              position: "absolute",
              fontSize: 16,
              backgroundColor: "transparent",
              appearance: "none",
              border: "none",
              outline: "none"
            }} type="text" placeholder="手机号" />
          </div>
          <div style={{ background: "#2EB2A6", width: 1, height: 27, marginTop: 13, float: "left" }} />
          <div style={{ height: "100%", width: 230, float: "left", position: "relative" }}>
            <img src={IMG_KEY} style={{ width: 18, marginTop: 18, marginLeft: 13, display: "block" }} />
            <input value={this.state.verifyCode} onChange={(e) => this.setState({ verifyCode: e.target.value })}
              style={{
                width: 170,
                display: "block",
                top: 13,
                left: 42,
                color: "#000000",
                position: "absolute",
                fontSize: 16,
                backgroundColor: "transparent",
                appearance: "none",
                border: "none",
                outline: "none"
              }} type="text" placeholder="验证码" />
          </div>
        </div>
        <div style={{
          width: 280,
          height: 60,
          lineHeight: "60px",
          textAlign: "center",
          color: "white",
          fontSize: 24,
          position: "absolute",
          top: 80,
          left: "50%",
          marginLeft: -140,
          cursor: "pointer",
          borderRadius: 5,
          backgroundColor: "#2EB2A6"
        }} onClick={(e) => {
          if (!this.state.senderAddress.code || this.state.senderAddress.name !== this.state.searchValueSender) {
            message.error("请选择发车地!");
          } else if (!this.state.receiveAddress.code || this.state.receiveAddress.name !== this.state.searchValueReceive) {
            message.error("请选择收车地!");
          } else if (!this.state.phone) {
            message.error("请输入联系人!");
          } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
            message.error("联系人请输入正确的手机号!");
          } else if (!this.state.verifyCode) {
            message.error("请输入验证码");
          } else {
            place_order_withVerifyCode(this.state.phone, this.state.verifyCode,
              this.state.senderAddress.provinceName,
              this.state.senderAddress.provinceCode,
              this.state.senderAddress.name,
              this.state.senderAddress.code,
              this.state.receiveAddress.provinceName,
              this.state.receiveAddress.provinceCode, this.state.receiveAddress.name,
              this.state.receiveAddress.code, this.state.phone, (result, msg) => {
                if (result) {
                  let leftData = this.state.realTimeOrderLeftData;
                  let rightData = this.state.realTimeOrderRightData;
                  this.setState(AppV2.STATE_STRUCT());
                  this.setState({ realTimeOrderLeftData: leftData, realTimeOrderRightData: rightData });
                  this.setState({ showModal: true });
                  clearInterval(this.timer);
                } else {
                  this.setState({ showLoading: false });
                  message.error(msg ? msg : "未知错误");
                }
              });
          }
        }}>
          一键获取方案
        </div>
        <img src={IMG_ICON_REAL_TIME_LABEL} style={{
          width: 97,
          position: "absolute",
          top: 200,
          display: this.state.realTimeOrderLeftData && this.state.realTimeOrderLeftData.length !== 0 ? "block" : "none",
          left: 60
        }} />
        <div style={{
          height: 21,
          position: "absolute",
          top: 200,
          width: 400,
          left: 180,
          color: "#414141",
          overflow: "hidden",
          fontSize: 12,
          fontWeight: 500,
          display: this.state.realTimeOrderLeftData && this.state.realTimeOrderLeftData.length !== 0 ? "block" : "none"
        }}>
          <div className="transition" style={{ marginTop: -(this.state.realTimeOrderLeftIndex * 21) }} />
          {
            this.createOrder(this.state.realTimeOrderLeftData)
          }
        </div>
        <img src={IMG_ICON_REAL_TIME_LABEL} style={{
          width: 97,
          position: "absolute",
          top: 200,
          display: this.state.realTimeOrderRightData && this.state.realTimeOrderRightData.length !== 0 ? "block" : "none",
          left: 785
        }} />
        <div style={{
          height: 21,
          position: "absolute",
          top: 200,
          width: 400,
          left: 905,
          color: "#414141",
          overflow: "hidden",
          fontSize: 12,
          fontWeight: 500,
          display: this.state.realTimeOrderRightData && this.state.realTimeOrderRightData.length !== 0 ? "block" : "none"
        }}>
          <div className="transition" style={{ marginTop: -(this.state.realTimeOrderRightIndex * 21) }} />
          {
            this.createOrder(this.state.realTimeOrderRightData)
          }
        </div>
      </div>
    </div>
  );
  createContentProductAddressChooser = () =>
  (
    <div style={{ width: 1200, marginLeft: "auto", marginRight: "auto", position: "relative", top: -268 }}>
      {
        this.state.showSenderAddressChooser ?
          <AddressChooser keyWord={this.state.searchValueSender} handleData={(data) => this.setState({
            senderAddress: data,
            showSenderAddressChooser: false,
            searchValueSender: data.name
          })} style={{ position: "absolute", zIndex: 99, left: 60, color: "black" }} /> : null
      }
      {
        this.state.showReceiverAddressChooser ?
          <AddressChooser keyWord={this.state.searchValueReceive} handleData={(data) => this.setState({
            receiveAddress: data,
            showReceiverAddressChooser: false,
            searchValueReceive: data.name
          })} style={{ position: "absolute", zIndex: 99, left: 302, color: "black" }} /> : null
      }
    </div>
  );
  createContentAboutUs = () => (<div style={{ width: "100%", height: 880, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_ABOUT_US}
      style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 77,
      color: "white",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>公司简介</span>
    <span style={{
      paddingTop: 15,
      color: "#B3B3B3",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Company Profile</span>
    <img src={IMG_SPLIT_LOGO_WHITE} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    <div style={{ width: 1350, marginLeft: "auto", marginRight: "auto", position: "relative", marginTop: 35 }}>
      <span style={{ display: "block", fontSize: 24, fontWeight: 500, color: "#2EB2A6" }}>深圳车粒子科技有限公司</span>
      <span style={{
        display: "block",
        fontSize: 14,
        lineHeight: "28px",
        width: 591,
        marginTop: 30,
        fontWeight: 500,
        color: "white"
      }}>
        全国领先的互联网汽车物流公司，轿运车公司。从事门到门运输汽车、汽车异地运输、自驾游汽车运输、
        二手车运输、私家车运输、汽车托运、轿车托运、邮寄汽车、运车等托运服务，运输汽车长期合作，范围覆盖新能源汽车、
        小轿车、SUV、商务车、货运车等多种车型。业务范围覆盖北京、上海、广州、深圳、杭州、重庆、西安、四川等地。
        未来车粒子科技将持续秉承着重服务，保品质，科技赋能的态度服务好客户，输出更多利于汽车物流领域的产品。<br />
      </span>
      <div style={{ color: "white", position: "absolute" }}>
        <div style={{ float: "left", width: 107, paddingTop: 24, textAlign: "center" }}>
          <img src={IMG_ICON_TRUCK_WITH_PEOPLE_GREEN} style={{ width: 38 }} />
          <div style={{ textAlign: "center", marginTop: 10, height: 20 }}>
            <span style={{ fontSize: 18, fontWeight: 400, fontFamily: "Impact" }}>1000</span><span
              style={{ fontSize: 12, fontWeight: 500 }}>+</span>
          </div>
          <div style={{ textAlign: "center", lineHeight: "28px", fontSize: 12, fontWeight: 500 }}>
            专线运输承运商
          </div>
        </div>
        <div style={{ float: "left", width: 107, paddingTop: 24, marginLeft: 13, textAlign: "center" }}>
          <img src={IMG_ICON_TRUCK_ARROW_GREEN} style={{ width: 38 }} />
          <div style={{ textAlign: "center", marginTop: 10, height: 20 }}>
            <span style={{ fontSize: 18, fontWeight: 400, fontFamily: "Impact" }}>10000</span><span
              style={{ fontSize: 12, fontWeight: 500 }}>+</span>
          </div>
          <div style={{ textAlign: "center", lineHeight: "28px", fontSize: 12, fontWeight: 500 }}>
            专职司机
          </div>
        </div>
        <div style={{ float: "left", width: 107, paddingTop: 24, marginLeft: 13, textAlign: "center" }}>
          <img src={IMG_ICON_BUILDING_GREEN} style={{ width: 38 }} />
          <div style={{ textAlign: "center", marginTop: 10, height: 20 }}>
            <span style={{ fontSize: 18, fontWeight: 400, fontFamily: "Impact" }}>300</span><span
              style={{ fontSize: 12, fontWeight: 500 }}>+</span>
          </div>
          <div style={{ textAlign: "center", lineHeight: "28px", fontSize: 12, fontWeight: 500 }}>
            覆盖城市
          </div>
        </div>
        <div style={{ float: "left", width: 107, paddingTop: 24, marginLeft: 13, textAlign: "center" }}>
          <img src={IMG_ICON_CAR_GREEN} style={{ width: 38 }} />
          <div style={{ textAlign: "center", marginTop: 10, height: 20 }}>
            <span style={{ fontSize: 18, fontWeight: 400, fontFamily: "Impact" }}>10万</span><span
              style={{ fontSize: 12, fontWeight: 500 }}>+</span>
          </div>
          <div style={{ textAlign: "center", lineHeight: "28px", fontSize: 12, fontWeight: 500 }}>
            年运输量
          </div>
        </div>
        <div style={{ float: "left", width: 107, paddingTop: 24, marginLeft: 13, textAlign: "center" }}>
          <img src={IMG_ICON_TRUCK_WITH_PEOPLE_GREEN} style={{ width: 39 }} />
          <div style={{ textAlign: "center", marginTop: 10, height: 20 }}>
            <span style={{ fontSize: 18, fontWeight: 400, fontFamily: "Impact" }}>1000</span><span
              style={{ fontSize: 12, fontWeight: 500 }}>+</span>
          </div>
          <div style={{ textAlign: "center", lineHeight: "28px", fontSize: 12, fontWeight: 500 }}>
            客户服务量
          </div>
        </div>
      </div>
      <Link to="/company" target="_blank">
        <div style={{
          fontSize: 16,
          position: "absolute",
          marginTop: 150,
          display: "block",
          color: "#2EB2A6",
          width: 140,
          height: 45,
          lineHeight: "45px",
          textAlign: "center",
          cursor: "pointer",
          borderColor: "#2EB2A6",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 5
        }}>
          查看更多+
        </div>
      </Link>
    </div>
  </div>);
  createContentServiceSpace = () => (
    <div style={{ width: "100%", height: 1044, position: "relative", overflow: "hidden" }}>
      <img src={IMG_BG_SERVICE}
        style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
      <span style={{
        paddingTop: 100,
        color: "#1A1A1A",
        display: "block",
        fontSize: 36,
        textAlign: "center",
        fontWeight: "Bold"
      }}>服务范围</span>
      <span style={{
        paddingTop: 15,
        color: "#4D4D4D",
        display: "block",
        fontSize: 16,
        textAlign: "center",
        fontWeight: "Bold"
      }}>Scope of services</span>
      <img src={IMG_SPLIT_LOGO} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
      <span style={{
        paddingTop: 45,
        color: "#1A1A1A",
        display: "block",
        fontSize: 16,
        textAlign: "center",
        fontWeight: "Bold"
      }}>
        根据用户的需求要求，提供专业的汽车运输服务，并根据车辆属性提供<br />对应的专业定制化服务，以满足用户需求为首要目标。
      </span>
      <div style={{
        width: 1200,
        height: 580,
        position: "relative",
        margin: '0 auto',
        marginTop: 40,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row"
      }}>
        <div className='service_space' style={{ backgroundImage: `url(${IMG_SERVICE_SPACE_1})` }}>
          <div className='green_background' />
          <img src={IMG_TRUCK} className='truck' />
          <span className='label'>商品车运输</span>
          <Link to={{ pathname: '/service/1', state: { id: 1 } }} target="_blank">
            <span className='button' style={{ color: "black" }}>></span>
          </Link>
        </div>
        <div className='service_space' style={{ backgroundImage: `url(${IMG_SERVICE_SPACE_2})` }}>
          <div className='green_background' />
          <img src={IMG_TRUCK} className='truck' />
          <span className='label'>二手车运输</span>
          <Link to={{ pathname: '/service/1', state: { id: 1 } }} target="_blank">
            <span className='button' style={{ color: "black" }}>></span>
          </Link>
        </div>
        <div className='service_space' style={{ backgroundImage: `url(${IMG_SERVICE_SPACE_3})` }}>
          <div className='green_background' />
          <img src={IMG_TRUCK} className='truck' />
          <span className='label'>高端车运输</span>
          <Link to={{ pathname: '/service/1', state: { id: 1 } }} target="_blank">
            <span className='button' style={{ color: "black" }}>></span>
          </Link>
        </div>
        <div className='service_space' style={{ backgroundImage: `url(${IMG_SERVICE_SPACE_4})` }}>
          <div className='green_background' />
          <img src={IMG_TRUCK} className='truck' />
          <span className='label'>私家车运输</span>
          <Link to={{ pathname: '/service/1', state: { id: 1 } }} target="_blank">
            <span className='button' style={{ color: "black" }}>></span>
          </Link>
        </div>
      </div>
    </div>);
  createContentApp = (APP_URL_IOS, APP_URL_ANDROID) => (
    <div style={{ width: "100%", height: 650, position: "relative", overflow: "hidden" }}>
      <img src={IMG_BG_APP}
        style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
      <span style={{
        paddingTop: 66,
        color: "black",
        display: "block",
        fontSize: 36,
        textAlign: "center",
        fontWeight: "Bold"
      }}>粒子运车app</span>
      <img src={IMG_GREEN_LINE}
        style={{ marginTop: 30, display: "block", marginLeft: "auto", marginRight: "auto" }} />
      <div style={{ width: 1200, marginLeft: "auto", marginRight: "auto", position: "relative", marginTop: 42 }}>
        <img src={IMG_APP} style={{ position: "absolute", left: 195 }} />
        <img src={IMG_QR_CODE_DOWNLOAD} style={{ position: "absolute", right: 214, width: 168, top: 44 }} />
        <img onClick={(e) => window.location.href = APP_URL_IOS} src={IMG_BUTTON_IOS_DOWNLOAD}
          style={{ position: "absolute", right: 233, width: 124, cursor: "pointer", top: 236 }} />
        <img src={IMG_QR_CODE_DOWNLOAD} style={{ position: "absolute", right: 499, width: 168, top: 44 }} />
        <img onClick={(e) => window.location.href = APP_URL_ANDROID} src={IMG_BUTTON_ANDROID_DOWNLOAD}
          style={{ position: "absolute", right: 518, width: 124, cursor: "pointer", top: 236 }} />
        <span style={{
          fontSize: 18,
          fontWeight: 500,
          display: "block",
          position: "absolute",
          top: 334,
          right: 225,
          color: "black"
        }}>线上一键下单，在途信息实时查询，7*24小时在线客服</span>
      </div>
    </div>);
  createContentService = () => (<div style={{ width: "100%", height: 677, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_TRANSIT_SERVICE}
      style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 88,
      color: "white",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>运输服务</span>
    <img src={IMG_GREEN_LINE} style={{ marginTop: 30, display: "block", marginLeft: "auto", marginRight: "auto" }} />
    <div style={{
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      color: "white",
      fontSize: 18,
      fontWeight: 500,
      width: 610,
      textAlign: "center",
      marginTop: 34
    }}>根据用户的需求要求，提供专业的汽车运输服务，并根据车辆属性提供对应的专业定制化服务，以满足用户需求为首要目标。
    </div>
    <div style={{ marginLeft: "auto", marginRight: "auto", width: 1200, marginTop: 76 }}>
      <div className="process_service" style={{ width: 400, height: 261, float: "left", position: "relative" }}>
        <img src={IMG_PROCESS_FIRST} style={{ width: "100%", height: "100%", position: "absolute" }} />
        <div className="shade" style={{
          width: "100%",
          height: "100%",
          background: "#2EB2A6",
          opacity: 0.6,
          position: "absolute"
        }} />
        <img src={IMG_TRUCK} style={{
          width: 126,
          position: "inherit",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 93
        }} />
        <div style={{
          position: "inherit",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 35
        }}>
          新车运输
        </div>
      </div>
      <div className="process_service" style={{ width: 400, height: 261, float: "left", position: "relative" }}>
        <img src={IMG_PROCESS_SECOND} style={{ width: "100%", height: "100%", position: "absolute" }} />
        <div className="shade" style={{
          width: "100%",
          height: "100%",
          background: "#2EB2A6",
          opacity: 0.6,
          position: "absolute"
        }} />
        <img src={IMG_TRUCK} style={{
          width: 126,
          position: "inherit",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 93
        }} />
        <div style={{
          position: "inherit",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 35
        }}>
          二手车运输
        </div>
      </div>
      <div className="process_service" style={{ width: 400, height: 261, float: "left", position: "relative" }}>
        <img src={IMG_PROCESS_THIRD} style={{ width: "100%", height: "100%", position: "absolute" }} />
        <div className="shade" style={{
          width: "100%",
          height: "100%",
          background: "#2EB2A6",
          opacity: 0.6,
          position: "absolute"
        }} />
        <img src={IMG_TRUCK} style={{
          width: 126,
          position: "inherit",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 93
        }} />
        <div style={{
          position: "inherit",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: 20,
          marginTop: 35
        }}>
          高端车运输
        </div>
      </div>
    </div>
  </div>);
  createContentProcess = () => (<div style={{ width: "100%", height: 925, position: "relative", overflow: "hidden" }}>
    <img src={IMG_PROCESS_BACKGROUND}
      style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -959.5 }} />
    <span style={{
      paddingTop: 77,
      color: "white",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>服务流程</span>
    <span style={{
      paddingTop: 15,
      color: "#B3B3B3",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Service Process</span>
    <img src={IMG_SPLIT_LOGO_WHITE} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    <span style={{
      display: "block",
      margin: "0 auto",
      textAlign: "center",
      fontSize: 16,
      marginTop: 40,
      lineHeight: "32px",
      color: "white",
    }}>
      公司秉承着最专业、智能、高效、严谨、高客户体验的服务理念，<br />严格执行我们的专业服务流程：下单、签合同、验车、提车、运输、交车、付款、评价反馈。<br />每个环节做到标准、专业、细致、可靠。
    </span>
    <div style={{ width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 58, position: "relative" }}>
      <div style={{ position: "absolute", textAlign: "center", width: 170, left: 0, top: 0 }}>
        <img className="service_process_img" src={IMG_PROCESS_1} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>下单</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>在线下单，方便快捷</div>
      </div>
      <img src={IMG_PROCESS_STEP} style={{ width: 42, left: 231, top: 45, position: "absolute" }} />
      <div style={{ position: "absolute", textAlign: "center", width: 170, left: 340, top: 0 }}>
        <img className="service_process_img" src={IMG_PROCESS_2} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>签约</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>电子合同，永久存档</div>
      </div>
      <img src={IMG_PROCESS_STEP} style={{ width: 42, left: 571, top: 45, position: "absolute" }} />
      <div style={{ position: "absolute", textAlign: "center", width: 170, left: 680, top: 0 }}>
        <img className="service_process_img" src={IMG_PROCESS_3} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>验车</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>专业验车，避免纠纷</div>
      </div>
      <img src={IMG_PROCESS_STEP} style={{ width: 42, right: 231, top: 45, position: "absolute" }} />
      <div style={{ position: "absolute", textAlign: "center", width: 170, right: 0, top: 0 }}>
        <img className="service_process_img" src={IMG_PROCESS_4} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>提车</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>自营团队，安全可靠</div>
      </div>

      <div style={{ position: "absolute", textAlign: "center", width: 170, left: 0, top: 240 }}>
        <img className="service_process_img" src={IMG_PROCESS_5} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>评价</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>客户回访，持续完善</div>
      </div>
      <img src={IMG_PROCESS_STEP}
        style={{ width: 42, left: 231, top: 285, transform: "rotateY(180deg)", position: "absolute" }} />
      <div style={{ position: "absolute", textAlign: "center", width: 170, left: 340, top: 240 }}>
        <img className="service_process_img" src={IMG_PROCESS_6} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>付款</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>验车付款，订单完结</div>
      </div>
      <img src={IMG_PROCESS_STEP}
        style={{ width: 42, left: 571, top: 285, transform: "rotateY(180deg)", position: "absolute" }} />
      <div style={{ position: "absolute", textAlign: "center", width: 170, left: 680, top: 240 }}>
        <img className="service_process_img" src={IMG_PROCESS_7} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>交车</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>提前约定，准时交付</div>
      </div>
      <img src={IMG_PROCESS_STEP}
        style={{ width: 42, right: 231, top: 285, transform: "rotateY(180deg)", position: "absolute" }} />
      <div style={{ position: "absolute", textAlign: "center", width: 170, right: 0, top: 240 }}>
        <img className="service_process_img" src={IMG_PROCESS_8} style={{ width: 100 }} />
        <div style={{ color: "white", fontSize: 24, marginTop: 20 }}>运输</div>
        <div style={{ color: "#B3B3B3", fontSize: 18, marginTop: 15 }}>实时监控，在途可视</div>
      </div>
    </div>
  </div>);
  createContentGoodness = () => (<div style={{
    width: "100%",
    height: 783,
    position: "relative",
    backgroundColor: "black",
    backgroundImage: `url(${IMG_BG_GOODNESS})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%"
  }}>
    <span style={{
      paddingTop: 100,
      color: "#1A1A1A",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>服务优势</span>
    <span style={{
      paddingTop: 15,
      color: "#4D4D4D",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Service Advantages</span>
    <img src={IMG_SPLIT_LOGO} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    <div style={{
      marginTop: 50,
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
      display: "flex",
      flexFlow: "row",
      width: 1200
    }}>
      <div className="service">
        <img src={IMG_SERVICE_1}
          style={{ position: "absolute", width: "100%", height: "100%", zIndex: -1, left: 0, top: 0 }} />
        <div className="shade" style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 0,
          left: 0,
          top: 0,
          opacity: 0.7
        }} />
        <img src={IMG_ICON_TRUCK_WHITE}
          style={{ position: "absolute", width: 51, left: "50%", marginLeft: -25.5, top: 88 }} />
        <div style={{
          position: "absolute",
          width: 19,
          height: 2,
          backgroundColor: "white",
          left: "50%",
          marginLeft: -9.5,
          top: 154
        }} />
        <span style={{
          fontSize: 26,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          width: 150,
          left: "50%",
          marginLeft: -75,
          top: 180
        }}>海量运力</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 105, top: 245 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 131,
          top: 237
        }}>千家物流</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 105, top: 245 + 28 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 131,
          top: 237 + 28
        }}>万辆板车</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 105, top: 245 + 56 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 131,
          top: 237 + 56
        }}>多式联运</span>
      </div>
      <div className="service">
        <img src={IMG_SERVICE_2}
          style={{ position: "absolute", width: "100%", height: "100%", zIndex: -1, left: 0, top: 0 }} />
        <div className="shade" style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 0,
          left: 0,
          top: 0,
          opacity: 0.7
        }} />
        <img src={IMG_ICON_HEART_WHITE}
          style={{ position: "absolute", width: 52, left: "50%", marginLeft: -26, top: 82 }} />
        <div style={{
          position: "absolute",
          width: 19,
          height: 2,
          backgroundColor: "white",
          left: "50%",
          marginLeft: -9.5,
          top: 154
        }} />
        <span style={{
          fontSize: 26,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          width: 150,
          left: "50%",
          marginLeft: -75,
          top: 180
        }}>领先服务</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 87, top: 245 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 110,
          top: 237
        }}>首创服务标准</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 87, top: 245 + 28 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 110,
          top: 237 + 28
        }}>线上全程可视</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 87, top: 245 + 56 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 110,
          top: 237 + 56
        }}>24hr贴心服务</span>
      </div>
      <div className="service">
        <img src={IMG_SERVICE_3}
          style={{ position: "absolute", width: "100%", height: "100%", zIndex: -1, left: 0, top: 0 }} />
        <div className="shade" style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 0,
          left: 0,
          top: 0,
          opacity: 0.7
        }} />
        <img src={IMG_ICON_SHIELD_WHITE}
          style={{ position: "absolute", width: 49, left: "50%", marginLeft: -24.5, top: 81 }} />
        <div style={{
          position: "absolute",
          width: 19,
          height: 2,
          backgroundColor: "white",
          left: "50%",
          marginLeft: -9.5,
          top: 154
        }} />
        <span style={{
          fontSize: 26,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          width: 150,
          left: "50%",
          marginLeft: -75,
          top: 180
        }}>安全放心</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 87, top: 245 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 110,
          top: 237
        }}>20万运输保险</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 87, top: 245 + 28 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 110,
          top: 237 + 28
        }}>太保平台对接</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 87, top: 245 + 56 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 110,
          top: 237 + 56
        }}>客诉先行赔付</span>
      </div>
      <div className="service">
        <img src={IMG_SERVICE_4}
          style={{ position: "absolute", width: "100%", height: "100%", zIndex: -1, left: 0, top: 0 }} />
        <div className="shade" style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 0,
          left: 0,
          top: 0,
          opacity: 0.7
        }} />
        <img src={IMG_ICON_CLOUD_WHITE}
          style={{ position: "absolute", width: 58, left: "50%", marginLeft: -29, top: 87 }} />
        <div style={{
          position: "absolute",
          width: 19,
          height: 2,
          backgroundColor: "white",
          left: "50%",
          marginLeft: -9.5,
          top: 154
        }} />
        <span style={{
          fontSize: 26,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          width: 150,
          left: "50%",
          marginLeft: -75,
          top: 180
        }}>智能高效</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 89, top: 245 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 114,
          top: 237
        }}>竞价订单模型</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 89, top: 245 + 28 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 114,
          top: 237 + 28
        }}>物流管理系统</span>
        <img src={IMG_ICON_CHECK_WHITE} style={{ width: 14, position: "absolute", left: 89, top: 245 + 56 }} />
        <span style={{
          fontSize: 16,
          fontWeight: 500,
          color: "white",
          display: "block",
          position: "absolute",
          left: 114,
          top: 237 + 56
        }}>智能线路优化</span>
      </div>
    </div>
  </div>);
  createContentNetwork = () => (<div style={{ width: "100%", height: 1014, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_NETWORK}
      style={{ height: 1014, position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 77,
      color: "white",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>网络优势</span>
    <span style={{
      paddingTop: 15,
      color: "#B3B3B3",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Network Advantages</span>
    <img src={IMG_SPLIT_LOGO_WHITE} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    <div style={{ width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 115, position: "relative" }}>
      <img src={IMG_MAP} style={{ position: "absolute", right: 0, marginTop: 53, width: 615 }} />
      <div style={{
        width: 525,
        height: 626,
        background: "white",
        position: "relative",
        borderRadius: 5,
        border: "1px solid rgba(198,204,203,1)"
      }}>
        <div style={{
          width: 262,
          height: 170,
          position: "absolute",
          left: 0,
          top: 0,
          borderStyle: "dotted",
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 1,
          borderBottomWidth: 1,
          borderColor: "rgba(198,204,203,1)",
          textAlign: "center"
        }}>
          <img src={IMG_ICON_EARTH} style={{ width: 42, marginTop: 29 }} />
          <br />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            marginTop: 8,
            display: "block"
          }}>全覆盖</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            marginTop: 4,
            display: "block"
          }}>覆盖网络及运输线路</span>
        </div>
        <div style={{
          width: 262,
          height: 170,
          position: "absolute",
          right: 0,
          top: 0,
          borderStyle: "dotted",
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderColor: "rgba(198,204,203,1)",
          textAlign: "center"
        }}>
          <img src={IMG_ICON_SIGN} style={{ width: 40, marginTop: 29 }} />
          <br />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            marginTop: 8,
            display: "block"
          }}>多式联运</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            marginTop: 4,
            display: "block"
          }}>水上运输、铁路运输交织</span>
        </div>
        <div style={{
          width: 262,
          height: 170,
          position: "absolute",
          left: 0,
          top: 170,
          borderStyle: "dotted",
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 1,
          borderBottomWidth: 1,
          borderColor: "rgba(198,204,203,1)",
          textAlign: "center"
        }}>
          <img src={IMG_ICON_POINTER} style={{ width: 41, marginTop: 28 }} />
          <br />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            marginTop: 8,
            display: "block"
          }}>专线广</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            marginTop: 4,
            display: "block"
          }}>1000+专线运输公司</span>
        </div>
        <div style={{
          width: 262,
          height: 170,
          position: "absolute",
          right: 0,
          top: 170,
          borderStyle: "dotted",
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderColor: "rgba(198,204,203,1)",
          textAlign: "center"
        }}>
          <img src={IMG_ICON_CAR} style={{ width: 56, marginTop: 38 }} />
          <br />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            marginTop: 8,
            display: "block"
          }}>运力深</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            marginTop: 4,
            display: "block"
          }}>10000+专线运输车辆</span>
        </div>
        <div style={{
          width: 262,
          height: 170,
          position: "absolute",
          left: 0,
          top: 340,
          borderStyle: "dotted",
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 1,
          borderBottomWidth: 1,
          borderColor: "rgba(198,204,203,1)",
          textAlign: "center"
        }}>
          <img src={IMG_ICON_BUILDING} style={{ width: 40, marginTop: 31 }} />
          <br />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            marginTop: 8,
            display: "block"
          }}>发全国</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            marginTop: 4,
            display: "block"
          }}>覆盖全国300城市</span>
        </div>
        <div style={{
          width: 262,
          height: 170,
          position: "absolute",
          right: 0,
          top: 340,
          borderStyle: "dotted",
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderColor: "rgba(198,204,203,1)",
          textAlign: "center"
        }}>
          <img src={IMG_ICON_CIRCLE} style={{ width: 39, marginTop: 35 }} />
          <br />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            marginTop: 8,
            display: "block"
          }}>网络效率高</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            marginTop: 4,
            display: "block"
          }}>3000条运输线路网络闭环</span>
        </div>
        <div style={{ width: "100%", height: 102, bottom: 0, left: 0, position: "absolute" }}>
          <img src={IMG_ICON_HUB} style={{
            width: 52,
            top: "50%",
            display: "block",
            left: 52,
            position: "absolute",
            marginTop: -19
          }} />
          <span style={{
            fontSize: 24,
            fontWeight: "bold",
            color: "black",
            display: "block",
            position: "absolute",
            left: 135,
            top: 15
          }}>五大枢纽</span>
          <span style={{
            fontSize: 16,
            fontWeight: 500,
            color: "rgba(93,93,93,1)",
            display: "block",
            position: "absolute",
            left: 135,
            bottom: 13
          }}>五大核心枢纽（北京、上海、广州、成都、青岛）</span>
        </div>
      </div>
    </div>
  </div>);
  createContentSafe = () => (<div style={{ width: "100%", height: 1039, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_SAFE} style={{ height: 1039, position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 100,
      color: "#1A1A1A",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>安全保障</span>
    <span style={{
      paddingTop: 15,
      color: "#4D4D4D",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Safety Guarantee</span>
    <img src={IMG_SPLIT_LOGO} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    <div style={{ width: 1000, marginLeft: -483, left: "50%", position: "relative", top: 30 }}>
      <div style={{
        position: "absolute",
        left: this.state.currentSafePage === 0 ? "50%" : (this.state.currentSafePage === 1 ? 0 : 664),
        width: this.state.currentSafePage === 0 ? 435 : 306,
        zIndex: this.state.currentSafePage === 0 ? 4 : 3,
        top: this.state.currentSafePage === 0 ? 0 : 93,
        marginLeft: this.state.currentSafePage === 0 ? -232.5 : 0,
        transitionProperty: "all",
        transitionDuration: "0.5s",
        transitionTimingFunction: "ease"
      }}>
        <img src={IMG_DOCUMENT_1} style={{ position: "absolute", zIndex: -1, width: "100%", cursor: "pointer" }} />
        <div style={{
          backgroundColor: "#000",
          zIndex: 0,
          width: "100%",
          height: this.state.currentSafePage !== 0 ? 435 : 620,
          cursor: "pointer",
          opacity: this.state.currentSafePage !== 0 ? 0.3 : 0,
          transitionProperty: "all",
          transitionDuration: "0.5s"
        }} onClick={(e) => this.setState({ currentSafePage: 0 })} />
        <span style={{
          color: this.state.currentSafePage === 0 ? "white" : "#808080",
          display: "block",
          textAlign: "center",
          fontSize: 22,
          marginTop: 32,
          transitionProperty: "all",
          transitionDuration: "0.5s"
        }}>运输资质</span>
      </div>
      <div style={{
        position: "absolute",
        left: this.state.currentSafePage === 1 ? "50%" : (this.state.currentSafePage === 2 ? 0 : 664),
        width: this.state.currentSafePage === 1 ? 435 : 306,
        zIndex: this.state.currentSafePage === 1 ? 4 : 3,
        top: this.state.currentSafePage === 1 ? 0 : 93,
        marginLeft: this.state.currentSafePage === 1 ? -232.5 : 0,
        transitionProperty: "all",
        transitionDuration: "0.5s",
        transitionTimingFunction: "ease"
      }}>
        <img src={IMG_DOCUMENT_2} style={{ position: "absolute", zIndex: -1, width: "100%", cursor: "pointer" }} />
        <div style={{
          backgroundColor: "#000",
          zIndex: 0,
          width: "100%",
          height: this.state.currentSafePage !== 1 ? 435 : 620,
          cursor: "pointer",
          opacity: this.state.currentSafePage !== 1 ? 0.3 : 0,
          transitionProperty: "all",
          transitionDuration: "0.5s"
        }} onClick={(e) => this.setState({ currentSafePage: 1 })} />
        <span style={{
          color: this.state.currentSafePage === 1 ? "white" : "#808080",
          display: "block",
          textAlign: "center",
          fontSize: 22,
          marginTop: 32,
          transitionProperty: "all",
          transitionDuration: "0.5s"
        }}>太保合作</span>
      </div>
      <div style={{
        position: "absolute",
        left: this.state.currentSafePage === 2 ? "50%" : (this.state.currentSafePage === 0 ? 0 : 664),
        width: this.state.currentSafePage === 2 ? 435 : 306,
        zIndex: this.state.currentSafePage === 2 ? 4 : 3,
        top: this.state.currentSafePage === 2 ? 0 : 93,
        marginLeft: this.state.currentSafePage === 2 ? -232.5 : 0,
        transitionProperty: "all",
        transitionDuration: "0.5s",
        transitionTimingFunction: "ease"
      }}>
        <img src={IMG_DOCUMENT_3} style={{ position: "absolute", zIndex: -1, width: "100%", cursor: "pointer" }} />
        <div style={{
          backgroundColor: "#000",
          zIndex: 0,
          width: "100%",
          height: this.state.currentSafePage !== 2 ? 450 : 639.5,
          cursor: "pointer",
          opacity: this.state.currentSafePage !== 2 ? 0.3 : 0,
          transitionProperty: "all",
          transitionDuration: "0.5s"
        }} onClick={(e) => this.setState({ currentSafePage: 2 })} />
        <span style={{
          color: this.state.currentSafePage === 2 ? "white" : "#808080",
          display: "block",
          textAlign: "center",
          fontSize: 22,
          marginTop: 32,
          transitionProperty: "all",
          transitionDuration: "0.5s"
        }}>专业运输合同</span>
      </div>
    </div>
  </div>);
  createContentCases = () => (<div style={{ width: "100%", height: 947, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_CASE}
      style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 100,
      color: "#1A1A1A",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>服务案例</span>
    <span style={{
      paddingTop: 15,
      color: "#4D4D4D",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Service Cases</span>
    <img src={IMG_SPLIT_LOGO} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    {
      this.state.caseData.length > 0 ? this.createGeneratedContentCase() : <br />
    }
  </div>);
  createGeneratedContentCase = () => (
    <div style={{ width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 70, position: "relative" }}>
      <div style={{ display: "flex", width: "100%", flexFlow: "row", height: 387 }}>
        <img src={this.state.caseData[this.state.currentCaseIndex].image}
          style={{ flex: 690, height: "100%", objectFit: "cover" }} />
        <div style={{ flex: 510, height: "100%", backgroundImage: `url(${IMG_CASE_ITEM_BACKGROUND})` }}>
          <span style={{
            display: "block",
            color: "white",
            fontSize: 30,
            marginLeft: 40,
            marginTop: 40,
            marginRight: 40
          }}>
            {this.state.caseData[this.state.currentCaseIndex].title}
          </span>
          <span style={{
            display: "block",
            color: "white",
            fontSize: 14,
            marginLeft: 40,
            marginTop: 14,
            marginRight: 40,
            height: 180
          }}>
            {this.state.caseData[this.state.currentCaseIndex].desc}
          </span>
          <Link to={{
            pathname: '/service/4?index=' + (this.state.caseData.length - this.state.currentCaseIndex),
            state: {
              id: 4,
              caseId: this.state.caseData[this.state.currentCaseIndex].id,
              meta: this.state.caseData[this.state.currentCaseIndex].meta,
              pageTitle: this.state.caseData[this.state.currentCaseIndex].pageTitle,
            }
          }} target='_blank'>
            <span style={{
              fontSize: 16,
              marginTop: 10,
              marginLeft: 40,
              display: "block",
              color: "white",
              width: 140,
              height: 45,
              lineHeight: "45px",
              textAlign: "center",
              cursor: "pointer",
              borderColor: "white",
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 5
            }}>
              查看详情+
            </span>
          </Link>
        </div>
      </div>
      <div style={{ width: "100%", marginTop: 20 }}>
        <div style={{ width: 285, height: 160, position: "relative", float: "left", cursor: "pointer" }}
          onClick={() => this.setState({ currentCaseIndex: 0 })}>
          <img src={this.state.caseData[0].image}
            style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div className="case_shadow"
            style={{ display: this.state.currentCaseIndex === 0 ? "none" : "block" }} />
        </div>
        <div style={{
          width: 285,
          height: 160,
          position: "relative",
          float: "left",
          cursor: "pointer",
          marginLeft: 20,
          display: this.state.caseData[1] ? "block" : "none"
        }} onClick={() => this.setState({ currentCaseIndex: 1 })}>
          <img src={this.state.caseData[1] ? this.state.caseData[1].image : ""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div className="case_shadow"
            style={{ display: this.state.currentCaseIndex === 1 ? "none" : "block" }} />
        </div>
        <div style={{
          width: 285,
          height: 160,
          position: "relative",
          float: "left",
          cursor: "pointer",
          marginLeft: 20,
          display: this.state.caseData[2] ? "block" : "none"
        }} onClick={() => this.setState({ currentCaseIndex: 2 })}>
          <img src={this.state.caseData[2] ? this.state.caseData[2].image : ""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div className="case_shadow"
            style={{ display: this.state.currentCaseIndex === 2 ? "none" : "block" }} />
        </div>
        <div style={{
          width: 285,
          height: 160,
          position: "relative",
          float: "left",
          cursor: "pointer",
          marginLeft: 20,
          display: this.state.caseData[3] ? "block" : "none"
        }} onClick={() => this.setState({ currentCaseIndex: 3 })}>
          <img src={this.state.caseData[3] ? this.state.caseData[3].image : ""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div className="case_shadow"
            style={{ display: this.state.currentCaseIndex === 3 ? "none" : "block" }} />
        </div>
      </div>
    </div>);
  createContentMustKnow = () => (<div style={{ width: "100%", height: 1316, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_QA} style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 77,
      color: "white",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>了解托运</span>
    <span style={{
      paddingTop: 15,
      color: "#B3B3B3",
      display: "block",
      fontSize: 16,
      textAlign: "center",
      fontWeight: "Bold"
    }}>Understanding Shipping</span>
    <img src={IMG_SPLIT_LOGO_WHITE} style={{ width: 466, margin: '0 auto', marginTop: 20, display: "block" }} />
    <div style={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: 23, height: 50 }}>
      <span onClick={() => this.setState({ currentMustKnowNewsIndex: 0 })} style={{
        float: 'left',
        color: this.state.currentMustKnowNewsIndex === 0 ? "white" : "black",
        borderRadius: 5,
        backgroundColor: this.state.currentMustKnowNewsIndex === 0 ? '#2DB1A5' : 'white',
        width: 190,
        height: 50,
        fontSize: 20,
        lineHeight: "50px",
        cursor: "pointer",
        textAlign: "center"
      }}>
        托运须知
      </span>
      <span onClick={() => this.setState({ currentMustKnowNewsIndex: 1 })} style={{
        float: 'right',
        color: this.state.currentMustKnowNewsIndex === 1 ? "white" : "black",
        borderRadius: 5,
        backgroundColor: this.state.currentMustKnowNewsIndex === 1 ? '#2DB1A5' : 'white',
        width: 190,
        height: 50,
        fontSize: 20,
        lineHeight: "50px",
        cursor: "pointer",
        textAlign: "center"
      }}>
        最新资讯
      </span>
    </div>
    <div style={{
      width: 1200,
      height: 760,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 30,
      position: "relative",
      backgroundColor: "white",
      borderRadius: 5,
      opacity: 0.9
    }}>
      {
        this.state.currentMustKnowNewsIndex === 0 && this.state.mustKnowData.length > 0 ?
          this.createGeneratedContentMustKnow() : <none />
      }
      {
        this.state.currentMustKnowNewsIndex === 1 && this.state.newsData.length > 0 ?
          this.createGeneratedContentNews() : <none />
      }
    </div>
  </div>);
  createGeneratedContentMustKnow = () => (
    <div style={{
      width: "100%",
      height: "100%",
      paddingTop: 20,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 50
    }}>
      {
        this.state.mustKnowData.map((item, index) => {
          return <div style={{ width: "100%", position: "relative", marginTop: 30 }}>
            <div style={{ display: "flex", flexFlow: "row" }}>
              <img style={{ display: "block", width: 37, height: 24 }} src={IMG_QUESTION} />
              <span style={{
                width: 952,
                display: "block",
                color: '#191919',
                fontSize: 24,
                marginLeft: 11,
                fontWeight: 'bold',
                lineHeight: "24px"
              }}>{item.question}</span>
            </div>
            <div style={{ display: "flex", flexFlow: "row", marginTop: 20 }}>
              <img style={{ display: "block", width: 37, height: 24 }} src={IMG_ANSWER} />
              <span style={{
                width: 952,
                marginBottom: 30,
                display: "block",
                color: '#4C4C4C',
                fontSize: 14,
                marginLeft: 11,
                fontWeight: 'bold',
                height: 38
              }}>{item.answer}</span>
            </div>
            <div className="more" style={{
              width: 36,
              height: 36,
              position: "absolute",
              right: 0,
              top: "50%",
              marginTop: -18,
              cursor: "pointer"
            }}>
              <Link to={{
                pathname: '/transform/qa?index=' + (this.state.mustKnowData.length - index)
              }} target="_blank">
                <img className="more_hover" src={IMG_MORE_CLICK} />
              </Link>
              <img className="more_un_hover" src={IMG_MORE_UN_CLICK} />
            </div>
            {
              index !== this.state.mustKnowData.length - 1 ?
                <div style={{ width: "100%", height: 1, backgroundColor: "black", opacity: 0.1 }} /> :
                <none />
            }
          </div>
        }
        )
      }
    </div>
  );
  createGeneratedContentNews = () => (
    <div style={{
      width: "100%",
      height: "100%",
      paddingTop: 30,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 50
    }}>
      {
        this.state.newsData.map((item, index) =>
          <div style={{ width: "100%", position: "relative", marginTop: 20 }}>
            <div style={{ width: "100%", display: "flex", flexFlow: "row" }}>
              <img src={item.image} style={{
                width: 193,
                height: 133,
                display: "block",
                objectFit: "cover",
                marginBottom: 20
              }} />
              <div style={{ width: 785, marginLeft: 20 }}>
                <div style={{ color: "#191919", fontSize: 24, fontWeight: 'bold', width: "100%" }}>
                  {item.title}
                </div>
                <div style={{ display: "flex", flexFlow: "row", marginTop: 13 }}>
                  <img src={IMG_CLOCK} style={{ display: "block", width: 16, height: 16 }} />
                  <div style={{ lineHeight: "16px", fontSize: 14, color: "#999999", marginLeft: 10 }}>
                    {item.date}
                  </div>
                </div>
                <div style={{ marginTop: 22, width: "100%", height: 38, fontSize: 14, color: "#4C4C4C" }}>
                  {item.desc}
                </div>
              </div>
            </div>
            <div className="more" style={{
              width: 36,
              height: 36,
              position: "absolute",
              right: 0,
              top: "50%",
              marginTop: -18,
              cursor: "pointer"
            }}>
              <Link to={{
                pathname: '/transform/news?index=' + (this.state.newsData.length - index)
              }} target="_blank">
                <img className="more_hover" src={IMG_MORE_CLICK} />
              </Link>
              <img className="more_un_hover" src={IMG_MORE_UN_CLICK} />
            </div>
            {
              index !== this.state.newsData.length - 1 ?
                <div style={{ width: "100%", height: 1, backgroundColor: "black", opacity: 0.1 }} /> :
                <none />
            }
          </div>
        )
      }
    </div>
  );
  createContentPartner = () => (<div style={{ width: "100%", height: 765, position: "relative", overflow: "hidden" }}>
    <img src={IMG_BG_PARTNER}
      style={{ height: "100%", position: "absolute", zIndex: -1, left: "50%", marginLeft: -960 }} />
    <span style={{
      paddingTop: 77,
      color: "white",
      display: "block",
      fontSize: 36,
      textAlign: "center",
      fontWeight: "Bold"
    }}>合作伙伴</span>
    <img src={IMG_WHITE_LINE} style={{ marginTop: 30, display: "block", marginLeft: "auto", marginRight: "auto" }} />
    <div style={{ marginTop: 80 }}>
      <Partner></Partner>
    </div>
  </div>);

  render() {
    const APP_URL_ANDROID = "http://apk.app.carticletech.com/shipper/carticle-shipper-release.apk";
    const APP_URL_IOS = "https://apps.apple.com/cn/app/粒子运车-货主/id1471585819";
    const TEMP_IMAGE_URL = "https://car1.autoimg.cn/upload/spec/13452/1024x0_1_q95_autohomecar__20120723095341589264.jpg";

    return (
      <div style={{
        minWidth: 1280, fontFamily: "PingFang S",
        maxWidth: 1920, width: "100%", marginLeft: "auto",
        marginRight: "auto"
      }}>
        <Helmet>
          <meta name="description" content={this.state.meta} />
          <meta name="keywords" content={this.state.keyWordsMeta} />
          <title>{this.state.pageTitle}</title>
        </Helmet>
        <div style={{
          width: 157,
          height: 450,
          position: "fixed",
          right: 0,
          top: "50%",
          marginTop: -286.5,
          zIndex: 99,
          backgroundColor: "white"
        }}>
          <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <img src={IMG_LOGO_WITH_BACKGROUND} style={{ display: "block", width: "100%" }} />
            <div style={{
              marginTop: 24,
              color: "#414141",
              fontSize: 14,
              fontWeight: 500,
              textAlign: "center"
            }}>
              服务热线
            </div>
            <div style={{
              marginTop: 12,
              color: "#0C5A70",
              fontSize: 19,
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "FZLanTingHeiS-B-GB"
            }}>
              400-603-8656
            </div>
            <Scroll.Link to="content_product" spy={true} smooth={true} duration={500}
              onSetActive={() => this.changPage(2)}>
              <div
                onClick={(e) => window.open("http://p.qiao.baidu.com/cps/chat?siteId=15167097&userId=29640200&siteToken=294c619cc592ae15bda78083498283b9", "在线咨询", "width=800, height=600")}
                style={{
                  width: 120,
                  height: 40,
                  lineHeight: "40px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 20,
                  color: "white",
                  fontSize: 14,
                  fontWeight: 500,
                  backgroundColor: "#2EB2A6",
                  borderRadius: 20,
                  textAlign: "center",
                  cursor: "pointer"
                }}>
                点击在线咨询
              </div>
            </Scroll.Link>
            {/*<img src={IMG_QR_CODE_DOWNLOAD} style={{*/}
            {/*    width: 94,*/}
            {/*    display: "block",*/}
            {/*    marginLeft: "auto",*/}
            {/*    marginRight: "auto",*/}
            {/*    marginTop: 8*/}
            {/*}}/>*/}
            {/*<div style={{*/}
            {/*    marginTop: 1,*/}
            {/*    color: "#414141",*/}
            {/*    fontSize: 14,*/}
            {/*    fontWeight: 500,*/}
            {/*    textAlign: "center"*/}
            {/*}}>*/}
            {/*    下载产品一键下单*/}
            {/*</div>*/}
            <img src={IMG_QR_CODE_PUBLIC} style={{
              width: 94,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 4
            }} />
            <div style={{
              marginTop: 1,
              color: "#414141",
              fontSize: 14,
              fontWeight: 500,
              textAlign: "center"
            }}>
              扫描关注微信公众号<br />
              获取更多信息
            </div>
            <Scroll.Link to="content_front_page" spy={true} smooth={true} duration={500}
              onSetActive={() => this.changPage(1)}>
              <div style={{
                width: "100%",
                height: 46,
                backgroundColor: "#0C5A70",
                textAlign: "center",
                lineHeight: "46px",
                position: "absolute",
                bottom: 0,
                cursor: "pointer"
              }}>
                <span style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: 500,
                  cursor: "pointer"
                }}>返回顶部</span>
                <img src={IMG_ICON_POINTER_UP} style={{ width: 12, marginLeft: 6 }} />
              </div>
            </Scroll.Link>
          </div>
        </div>
        <Scroll.Element name="content_front_page">
          {this.createContentFrontPage()}
        </Scroll.Element>
        <Scroll.Element name="content_product">
          {this.createContentProduct()}
          {this.createContentProductAddressChooser()}
        </Scroll.Element>
        <Scroll.Element name="content_about_us">
          {this.createContentAboutUs()}
        </Scroll.Element>
        {this.createContentServiceSpace()}
        {/*<Scroll.Element name="content_app">*/}
        {/*    {this.createContentApp(APP_URL_IOS, APP_URL_ANDROID)}*/}
        {/*</Scroll.Element>*/}
        {/*<Scroll.Element name="content_service">*/}
        {/*    {this.createContentService()}*/}
        {/*</Scroll.Element>*/}
        <Scroll.Element name="content_process">
          {this.createContentProcess()}
        </Scroll.Element>
        <Scroll.Element name="content_goodness">
          {this.createContentGoodness()}
        </Scroll.Element>
        <Scroll.Element name="content_network">
          {this.createContentNetwork()}
        </Scroll.Element>
        <Scroll.Element name="content_safe">
          {this.createContentSafe()}
        </Scroll.Element>
        <Scroll.Element name="content_cases">
          {this.createContentCases()}
        </Scroll.Element>
        <Scroll.Element name="content_must_know">
          {this.createContentMustKnow()}
        </Scroll.Element>
        <Scroll.Element name="content_partner">
          {this.createContentPartner()}
        </Scroll.Element>
        <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
          <ParticleFoot></ParticleFoot>
        </div>

        {
          (this.state.showLoading || this.state.showModal) ? <div style={{
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 100,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }} /> : null
        }
        {
          this.state.showLoading ?
            <div style={{
              margin: "0 auto",
              position: "fixed",
              backgroundColor: "white",
              borderRadius: 5,
              width: 395,
              zIndex: 101,
              height: 80,
              left: 0,
              right: 0,
              top: "50%",
              marginTop: -40,
              lineHeight: "80px"
            }}>
              <img src={ICON_LOADING_GREEN} style={{
                verticalAlign: "middle",
                marginLeft: 20,
                width: 40,
                animation: "rotateInfinite 1.5s linear infinite"
              }} />
              <span style={{
                fontSize: 25,
                marginLeft: 20,
                verticalAlign: "middle",
                color: "black"
              }}>请稍等</span>
            </div> : null
        }
        {
          this.state.showModal ?
            <div style={{
              margin: "0 auto",
              position: "fixed",
              backgroundColor: "white",
              borderRadius: 5,
              width: 395,
              zIndex: 101,
              height: 225,
              left: 0,
              right: 0,
              top: "50%",
              marginTop: -112.5
            }}>
              <span style={{
                color: "#181A1A",
                marginTop: 21,
                display: "block",
                textAlign: "center",
                fontSize: 20
              }}>提示</span>
              <div style={{ width: "100%", height: 1, background: "rgba(238,238,238,1)", marginTop: 21 }} />
              <span style={{
                color: "#181A1A",
                fontSize: 18,
                display: "block",
                marginTop: 41,
                textAlign: "center"
              }}>请保持手机畅通，客服人员马上给您报价。</span>
              <span onClick={(e) => this.setState({ showModal: false })} style={{
                color: "white",
                fontSize: 16,
                width: 116,
                display: "block",
                margin: "0 auto",
                marginTop: 35,
                backgroundColor: "#25A8A0",
                borderRadius: 24,
                textAlign: "center",
                paddingTop: 8,
                paddingBottom: 8,
                cursor: "pointer"
              }}>确定</span>
            </div> : null
        }
      </div>
    )
  }
}

export default AppV2;
