import React from 'react';
import IMG_BG_1 from './assets/img/bg/bg_1.jpg';
import IMG_BG_2 from './assets/img/bg/bg_2.jpg';
import IMG_BG_3 from './assets/img/bg/bg_3.jpg';
import IMG_BG_4 from './assets/img/bg/bg_4.jpg';
import IMG_LOGO from './assets/img/logo/logo.png';
import IMG_ICON_PHONE from './assets/img/icon/icon_phone.png';
import IMG_BANNER from './assets/img/banner.png';
import IMG_GREEN_HAND_BUTTON from './assets/img/button/button_with_hand_green.png';
import IMG_PHONE from './assets/img/phone.png';
import IMG_ICON_ARROW_DOWN_GRAY from './assets/img/icon/icon_arrow_down_gray.png';
import IMG_ICON_PHONE_GRAY from './assets/img/icon/icon_phone_gray.png';
import IMG_ICON_POINTER_RECEIVE_GRAY from './assets/img/icon/icon_pointer_receive_gray.png';
import IMG_ICON_CAR_GRAY from './assets/img/icon/icon_car_gray.png';
import IMG_QR_CODE from './assets/img/qr_code.png';
import IMG_QR_CODE_PUBLIC from './assets/img/qr_code_public.jpg';
import IMG_BUTTON_ANDROID_DOWNLOAD from './assets/img/button/button_android_download.png';
import IMG_BUTTON_IOS_DOWNLOAD from './assets/img/button/button_ios_download.png';
import IMG_WHITE_LINE from './assets/img/white_line.png';
import IMG_GREEN_LINE from './assets/img/green_line.png';
import IMG_DESC_1 from './assets/img/desc/desc_1.png';
import IMG_DESC_2 from './assets/img/desc/desc_2.jpg';
import IMG_DESC_3 from './assets/img/desc/desc_3.jpg';
import IMG_DESC_4 from './assets/img/desc/desc_4.jpg';
import IMG_DESC_5 from './assets/img/desc/desc_5.jpg';
import IMG_SERVICE_1_FRONT from './assets/img/service/service_1_front.jpg';
import IMG_SERVICE_2_FRONT from './assets/img/service/service_2_front.jpg';
import IMG_SERVICE_3_FRONT from './assets/img/service/service_3_front.jpg';
import IMG_SERVICE_4_FRONT from './assets/img/service/service_4_front.jpg';
import IMG_LOGO_360 from './assets/img/logo/thumbnail_che300.jpg';
import IMG_LOGO_GUAZI from './assets/img/logo/thumbnail_guazi.png';
import IMG_LOGO_LIANMENG from './assets/img/logo/thumbnail_lianmeng.png';
import IMG_LOGO_LIANZHONG from './assets/img/logo/thumbnail_lianzhong.png';
import IMG_LOGO_MAODOU from './assets/img/logo/thumbnail_maodou.png';
import IMG_LOGO_SHENZHOU from './assets/img/logo/thumbnail_shenzhou.png';
import IMG_LOGO_YOUCHEKU from './assets/img/logo/thumbnail_youcheku.png';
import IMG_LOGO_YOUXIN from './assets/img/logo/thumbnail_youxin.png';
import IMG_ICON_PHONE_GREEN from './assets/img/icon/icon_phone_green.png';
import IMG_ICON_POINTER_GREEN from './assets/img/icon/icon_pointer_green.png';
import ICON_LOADING_GREEN from './assets/img/icon/icon_loading.png';
import AddressChooser from './component/address_chooser';
import {message} from 'antd';
import {place_order} from "./action/order_action";

import * as Scroll from 'react-scroll';

class App extends React.Component{
    static STATE_STRUCT = () => {
        return {
            currentPageIndex: 1,
            showSenderAddressChooser: false,
            senderAddress: {},
            showReceiverAddressChooser: false,
            receiveAddress: {},
            phone: "",
            showLoading: false,
            showModal: false,
            searchValueSender: "",
            searchValueReceive: "",
        }
    };

    state = App.STATE_STRUCT();

    changPage = (index) => {
        this.setState({currentPageIndex : index});
    };

    render() {
        const APP_URL_ANDROID = "http://apk.app.carticletech.com/carrier/carticle-carrier-release.apk";
        const APP_URL_IOS = "https://apps.apple.com/cn/app/粒子运车-货主/id1471585819";

        return (
          <div style={{minWidth: 1280, fontFamily: "PingFang S"}}>
              <img src={IMG_SERVICE_1_FRONT} style={{display: "none"}} />
              <img src={IMG_SERVICE_2_FRONT} style={{display: "none"}} />
              <img src={IMG_SERVICE_3_FRONT} style={{display: "none"}} />
              <img src={IMG_SERVICE_4_FRONT} style={{display: "none"}} />
              <div style={{backgroundColor: "#031A21", opacity: 0.5, width: "100%", height: 97, position: "fixed", top: 0, zIndex: 99}}>
                  <div style={{width: 1280, height: "100%", marginLeft: "auto", marginRight: "auto", fontSize: 24, fontWeight: "bold", color: "white"}}>
                      <img style={{marginTop: 15, position:"relative", float: "left"}} src={IMG_LOGO} />
                      <Scroll.Link to="content_1" spy={true} smooth={true} duration={500} onSetActive={()=> this.changPage(1)}>
                          <div className="navButton" style={{float: "left", cursor: "pointer", height: "100%", marginLeft: 80, paddingLeft: 10, paddingRight: 10}}>
                              <span style={{verticalAlign: "middle", marginTop: 33, display: "block", color: "white"}}>首页</span>
                              {
                                  this.state.currentPageIndex === 1 ? <div style={{position: "relative", width: "100%", height: 5, backgroundColor: "white", marginTop: 23}} /> : null
                              }
                          </div>
                      </Scroll.Link>
                      <Scroll.Link to="content_2" spy={true} smooth={true} duration={500} onSetActive={()=> this.changPage(2)}>
                          <div className="navButton" style={{float: "left", cursor: "pointer", height: "100%", marginLeft: 80, paddingLeft: 10, paddingRight: 10}}>
                              <span style={{verticalAlign: "middle", marginTop: 33, display: "block", color: "white"}}>运输流程</span>
                              {
                                  this.state.currentPageIndex === 2 ? <div style={{position: "relative", width: "100%", height: 5, backgroundColor: "white", marginTop: 23}} /> : null
                              }
                          </div>
                      </Scroll.Link>
                      <Scroll.Link to="content_3" spy={true} smooth={true} duration={500} onSetActive={()=> this.changPage(3)}>
                          <div className="navButton" style={{float: "left", cursor: "pointer", height: "100%", marginLeft: 80, paddingLeft: 10, paddingRight: 10}}>
                              <span style={{verticalAlign: "middle", marginTop: 33, display: "block", color: "white"}}>服务优势</span>
                              {
                                  this.state.currentPageIndex === 3 ? <div style={{position: "relative", width: "100%", height: 5, backgroundColor: "white", marginTop: 23}} /> : null
                              }
                          </div>
                      </Scroll.Link>
                      <Scroll.Link to="content_4" spy={true} smooth={true} duration={500} onSetActive={()=> this.changPage(4)}>
                          <div className="navButton" style={{float: "left", cursor: "pointer", height: "100%", marginLeft: 80, paddingLeft: 10, paddingRight: 10}}>
                              <span style={{verticalAlign: "middle", marginTop: 33, display: "block", color: "white"}}>合作伙伴</span>
                              {
                                  this.state.currentPageIndex === 4 ? <div style={{position: "relative", width: "100%", height: 5, backgroundColor: "white", marginTop: 23}} /> : null
                              }
                          </div>
                      </Scroll.Link>
                      <span style={{float: "right", fontSize: 28, marginTop: 30}}>021-3952-5667</span>
                      <img src={IMG_ICON_PHONE} style={{width: 30, float: "right", marginTop: 36, marginRight: 14}} />
                  </div>
              </div>
              <Scroll.Element name="content_1">
                  <div style={{width: "100%", height: 1080, backgroundImage: `url(${IMG_BG_1})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
                    <img src={IMG_BANNER} style={{width: 970, marginLeft: "auto", marginRight: "auto", display: "block", paddingTop: 245}} />
                    <div style={{marginTop: 80, width: 800, height : 350, marginLeft: "auto", marginRight: "auto", backgroundColor: "white", borderRadius: 10, boxShadow: "0px 3px 40px 0px rgba(12,90,112,0.15)"}}>
                        <div style={{height: 30, width: 300, float: "left", marginLeft: 40, color: "#cccccc", fontSize: 16}}>
                            <div onClick={(e) => this.setState({showSenderAddressChooser: !this.state.showSenderAddressChooser, showReceiverAddressChooser: false})} style={{cursor: "pointer", width: "100%", height: 50, backgroundColor:"rgb(249, 249, 249)", marginTop: 35, border: this.state.showSenderAddressChooser ? "1px solid rgb(37,168,160)" : "1px solid rgb(240, 240, 240)"}}>
                                <img src={IMG_ICON_CAR_GRAY} style={{width: 25, marginTop: 15, marginLeft: 15, display: "block", float: "left"}} />
                                <input value={this.state.searchValueSender} onChange={(e) => this.setState({searchValueSender: e.target.value})} style={{marginLeft: 15, display: "block", float: "left", marginTop: 12, color: "#000000", fontSize: 16, backgroundColor: "transparent", appearance: "none", border: "none", outline: "none"}} type="text" placeholder="发车地"/>
                                <img src={IMG_ICON_ARROW_DOWN_GRAY} style={{display: "block", float: "right", marginTop: 20, marginRight: 15, width: 15}} />
                            </div>
                            <div onClick={(e) => this.setState({showReceiverAddressChooser: !this.state.showReceiverAddressChooser, showSenderAddressChooser: false})} style={{cursor: "pointer", width: "100%", height: 50, backgroundColor:"rgb(249, 249, 249)", marginTop: 25, border: this.state.showReceiverAddressChooser ? "1px solid rgb(37,168,160)" : "1px solid rgb(240, 240, 240)"}}>
                                <img src={IMG_ICON_POINTER_RECEIVE_GRAY} style={{width: 25, marginTop: 10, marginLeft: 15, display: "block", float: "left"}} />
                                <input value={this.state.searchValueReceive} onChange={(e) => this.setState({searchValueReceive: e.target.value})} style={{marginLeft: 15, display: "block", float: "left", marginTop: 12, color: "#000000", fontSize: 16, backgroundColor: "transparent", appearance: "none", border: "none", outline: "none"}} type="text" placeholder="收车地"/>
                                <img src={IMG_ICON_ARROW_DOWN_GRAY} style={{display: "block", float: "right", marginTop: 20, marginRight: 15, width: 15}} />
                            </div>
                            <div style={{cursor: "pointer", width: "100%", height: 50, backgroundColor:"rgb(249, 249, 249)", marginTop: 25, border: "1px solid rgb(240, 240, 240)"}}>
                                <img src={IMG_ICON_PHONE_GRAY} style={{width: 25, marginTop: 14, marginLeft: 15, display: "block", float: "left"}} />
                                <input value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} style={{marginLeft: 15, display: "block", float: "left", marginTop: 12, color: "#000000", fontSize: 16, backgroundColor: "transparent", appearance: "none", border: "none", outline: "none"}} type="text" placeholder="手机号"/>
                            </div>
                            <img onClick={(e) => {
                                if (!this.state.senderAddress.code || this.state.senderAddress.name !== this.state.searchValueSender){
                                    message.error("请选择发车地!");
                                }else if(!this.state.receiveAddress.code || this.state.receiveAddress.name !== this.state.searchValueReceive){
                                    message.error("请选择收车地!");
                                }else if(!this.state.phone){
                                    message.error("请输入联系人!");
                                }else if(!(/^1[3456789]\d{9}$/.test(this.state.phone))){
                                    message.error("联系人请输入正确的手机号!");
                                }else{
                                    this.setState({showLoading: true});
                                    place_order(this.state.senderAddress.provinceName, this.state.senderAddress.provinceCode, this.state.senderAddress.name, this.state.senderAddress.code, this.state.receiveAddress.provinceName, this.state.receiveAddress.provinceCode, this.state.receiveAddress.name, this.state.receiveAddress.code, this.state.phone, (result, message) => {
                                        if (result){
                                            this.setState(App.STATE_STRUCT());
                                            this.setState({showModal: true});
                                        }else {
                                            this.setState({showLoading: false});
                                            message.error(message ? message : "未知错误");
                                        }
                                    });
                                }
                            }} src={IMG_GREEN_HAND_BUTTON} style={{width: "100%", marginTop: 25, cursor: "pointer"}} />
                        </div>
                        <img src={IMG_PHONE} style={{width:150, marginTop: 25, marginLeft: 75, display: "block", float: "left", animation: "phone-into-animation 1s"}} />
                        <div style={{marginLeft: 50, marginTop: 35, float: "left"}}>
                            <img src={IMG_QR_CODE} style={{width: 150}} />
                            <img onClick={(e) => window.location.href = APP_URL_ANDROID} src={IMG_BUTTON_IOS_DOWNLOAD} style={{width: 150, display:"block", cursor: "pointer", marginTop: 25}} />
                            <img onClick={(e) => window.location.href = APP_URL_IOS} src={IMG_BUTTON_ANDROID_DOWNLOAD} style={{width: 150, display:"block", cursor: "pointer", marginTop: 20}} />
                        </div>
                        {
                            this.state.showSenderAddressChooser ?
                                <AddressChooser keyWord={this.state.searchValueSender} handleData={(data) => this.setState({senderAddress: data, showSenderAddressChooser: false, searchValueSender: data.name})} style = {{position: "absolute", zIndex: 99, marginTop: 84, marginLeft: 40}} /> : null
                        }
                        {
                            this.state.showReceiverAddressChooser ?
                                <AddressChooser keyWord={this.state.searchValueReceive} handleData={(data) => this.setState({receiveAddress: data, showReceiverAddressChooser: false, searchValueReceive: data.name})} style = {{position: "absolute", zIndex: 99, marginTop: 159, marginLeft: 40}} /> : null
                        }
                    </div>
                  </div>
              </Scroll.Element>
              <Scroll.Element name="content_2">
                  <div style={{display: "block", width: "100%", height: 1070, backgroundImage: `url(${IMG_BG_2})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
                    <span style={{paddingTop: 160, color: "black", display: "block", fontSize: 36, textAlign: "center", fontWeight: "Bold"}}>运输流程</span>
                    <img src={IMG_GREEN_LINE} style={{marginTop: 30, display: "block", marginLeft: "auto", marginRight: "auto"}} />
                    <div style={{width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 125}}>
                        <img className="desc" src={IMG_DESC_1} style={{width: 355}} />
                        <img className="desc" src={IMG_DESC_2} style={{width: 355, marginLeft: 67.5}} />
                        <img className="desc" src={IMG_DESC_3} style={{width: 355, marginLeft: 67.5}} />
                    </div>
                    <div style={{width: 777.5, marginLeft: "auto", marginRight: "auto", marginTop: 70}}>
                        <img className="desc" src={IMG_DESC_4} style={{width: 355}} />
                        <img className="desc" src={IMG_DESC_5} style={{width: 355, marginLeft: 67.5}} />
                    </div>
                  </div>
              </Scroll.Element>
              <Scroll.Element name="content_3">
                  <div style={{width: "100%", height: 1080, backgroundImage: `url(${IMG_BG_3})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
                      <span style={{paddingTop: 160, color: "white", display: "block", fontSize: 36, textAlign: "center", fontWeight: "Bold"}}>服务优势</span>
                      <img src={IMG_WHITE_LINE} style={{marginTop: 30, display: "block", marginLeft: "auto", marginRight: "auto"}} />
                      <div style={{marginTop: 140, marginLeft: "auto", marginRight: "auto", textAlign: "center", display: "flex", flexFlow: "row", width: 1200}}>
                          <div className="service service1" />
                          <div className="service service2" />
                          <div className="service service3" />
                          <div className="service service4" />
                      </div>
                      <div style={{width:1200, marginLeft: "auto", marginRight: "auto", marginTop: 90, color: "white"}}>
                          <div style={{width: 300, textAlign: "center", float: "left"}}>
                              <span style={{fontSize: 40, fontWeight: "bold"}}>10000</span>
                              <span style={{fontSize: 20}}>+</span><br />
                              <span style={{fontSize: 20}}>服务客户</span>
                          </div>
                          <div style={{width: 300, textAlign: "center", float: "left"}}>
                              <span style={{fontSize: 40, fontWeight: "bold"}}>300</span>
                              <span style={{fontSize: 20}}>+</span><br />
                              <span style={{fontSize: 16}}>服务覆盖城市</span>
                          </div>
                          <div style={{width: 300, textAlign: "center", float: "left"}}>
                              <span style={{fontSize: 40, fontWeight: "bold"}}>100000</span>
                              <span style={{fontSize: 20}}>+</span><br />
                              <span style={{fontSize: 16}}>运输车辆总数</span>
                          </div>
                          <div style={{width: 300, textAlign: "center", float: "left"}}>
                              <span style={{fontSize: 40, fontWeight: "bold"}}>100</span>
                              <span style={{fontSize: 20}}>%</span><br />
                              <span style={{fontSize: 16}}>用户满意度</span>
                          </div>
                      </div>
                  </div>
              </Scroll.Element>
              <Scroll.Element name="content_4">
                  <div style={{width: "100%", height: 873, backgroundImage: `url(${IMG_BG_4})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
                      <span style={{paddingTop: 160, color: "black", display: "block", fontSize: 36, textAlign: "center", fontWeight: "Bold"}}>合作伙伴</span>
                      <img src={IMG_GREEN_LINE} style={{marginTop: 30, display: "block", marginLeft: "auto", marginRight: "auto"}} />
                      <div style={{width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 130}}>
                          <img src={IMG_LOGO_GUAZI} style={{width: 260}} />
                          <img src={IMG_LOGO_MAODOU} style={{width: 260, marginLeft: 53.3}} />
                          <img src={IMG_LOGO_YOUXIN} style={{width: 260, marginLeft: 53.3}} />
                          <img src={IMG_LOGO_LIANMENG} style={{width: 260, marginLeft: 53.3}} />
                      </div>
                      <div style={{width: 1200, marginLeft: "auto", marginRight: "auto", marginTop: 50}}>
                          <img src={IMG_LOGO_360} style={{width: 260}} />
                          <img src={IMG_LOGO_SHENZHOU} style={{width: 260, marginLeft: 53.3}} />
                          <img src={IMG_LOGO_LIANZHONG} style={{width: 260, marginLeft: 53.3}} />
                          <img src={IMG_LOGO_YOUCHEKU} style={{width: 260, marginLeft: 53.3}} />
                      </div>
                  </div>
              </Scroll.Element>
              <div style={{width: "100%", height: 339, backgroundColor: "#2A2C2C", color: "rgba(212,212,212,1)"}}>
                  <div style={{width: 1200, marginLeft: "auto", marginRight: "auto", position: "relative"}}>
                      <div style={{width: 599, float: "left"}}>
                          <img src={IMG_LOGO} style={{width: 197, marginTop: 58}} />
                          <div style={{marginTop: 36, lineHeight: "34px", fontSize: 16, fontWeight: 400, width: 495}}>
                              深圳车粒子科技有限公司，一家专注于汽车领域的互联网物流平台，致力于以科技赋能汽车物流，提供汽车运输快递服务。以开放平台连接海量用户，以智能算法提升匹配效率，以专业服务保障准时安全，引领汽车物流走向移动互联时代。
                          </div>
                      </div>
                      <div style={{width: 1, height: 254, marginTop: 58, float: "left", backgroundColor: "rgba(255,255,255,0.2)"}} />
                      <div style={{width: 599, float: "left", paddingLeft: 70, fontWeight: 400}}>
                          <span style={{fontSize: 18, display: "block", marginTop: 58}}> 广东&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上海&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;北京&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;山东&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;四川</span>
                          <div style={{marginTop: 22}}>
                              <img src={IMG_ICON_PHONE_GREEN} style={{width: 16, display: "block", float: "left", marginTop: 6}} />
                              <span style={{fontSize: 16, marginLeft: 10, display: "block", float: "left", marginTop: 3}}>联系电话:</span>
                              <span style={{fontSize: 22, marginLeft: 24, display: "block", float: "left"}}>021-3952-5667</span>
                          </div>
                          <br />
                          <div style={{marginTop: 22}}>
                              <img src={IMG_ICON_POINTER_GREEN} style={{width: 14, display: "block", float: "left", marginTop: 6}} />
                              <span style={{fontSize: 16, marginLeft: 10, display: "block", float: "left", marginTop: 3}}>地址：深圳市福田区福田街道皇岗社区益田路3008号皇都广场B栋2506</span>
                          </div>
                          <div>
                              <img src={IMG_QR_CODE_PUBLIC} style={{width: 96, paddingTop: 25, display: "block", float: "left"}} />
                              <span style={{fontSize: 16, color: "#FFFFFF", float: "left", paddingTop: 46, marginLeft: 35}}>扫码关注公众号<br/>开启运输</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div style={{width: "100%", opacity: 0.2, height: 1, backgroundColor: "white"}} />
              <div style={{textAlign: "center", backgroundColor: "#2A2C2C", width: "100%", color: "#D4D4D4", fontSize: 12, paddingTop : 20, paddingBottom: 20}}>
                  车粒子科技有限公司  <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19064435号-2</a>
              </div>
              {
                  (this.state.showLoading  || this.state.showModal) ? <div style={{position: "fixed", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 100, left: 0, top: 0, width: "100%", height: "100%"}} /> : null
              }
              {
                  this.state.showLoading ?
                      <div style={{margin: "0 auto", position: "fixed", backgroundColor: "white", borderRadius: 5, width: 395, zIndex: 101, height: 80, left: 0, right: 0, top: "50%", marginTop: -40, lineHeight: "80px"}}>
                          <img src={ICON_LOADING_GREEN} style={{verticalAlign: "middle", marginLeft: 20, width: 40, animation:"rotateInfinite 1.5s linear infinite"}} />
                          <span style={{fontSize: 25, marginLeft: 20, verticalAlign: "middle"}}>请稍等</span>
                      </div> : null
              }
              {
                  this.state.showModal ?
                      <div style={{margin: "0 auto", position: "fixed", backgroundColor: "white", borderRadius: 5, width: 395, zIndex: 101, height: 225, left: 0, right: 0, top: "50%", marginTop: -112.5}}>
                          <span style={{color: "#181A1A", marginTop: 21, display: "block", textAlign: "center", fontSize: 20}}>提示</span>
                          <div style={{width: "100%", height: 1, background: "rgba(238,238,238,1)", marginTop: 21}} />
                          <span style={{color: "#181A1A", fontSize: 18, display: "block", marginTop: 41, textAlign: "center"}}>请保持手机畅通，客服人员马上给您报价。</span>
                          <span onClick={(e) => this.setState({showModal: false})} style={{color: "white", fontSize: 16, width: 116, display: "block", margin: "0 auto", marginTop: 35, backgroundColor: "#25A8A0", borderRadius: 24, textAlign: "center", paddingTop: 8, paddingBottom: 8, cursor: "pointer"}}>确定</span>
                      </div> : null
              }
          </div>
        );
  }
}

export default App;
