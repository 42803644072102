import React from 'react';
import ReactDOM, {hydrate, render} from 'react-dom';
import './animation.css';
import './index.css';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';
import App from './App';
import AppV2 from "./Appv2";
import * as serviceWorker from './serviceWorker';
import Introduce from "./component/introduce/introduce";
import ServiceIntroduce from "./component/service_introduce/service_introduce";
import AppV3 from "./Appv3";
import PlaceOrder from "./component/place_order/place_order";
import TransformIntroduce from "./component/transform_introduce/transform_introduce";


export default class Hello extends React.Component {
    render() {
        return (
            <Router>
                <div>
                    <Route exact path="/" component={AppV2}/>
                    <Route path="/service/:id/:title?" component={ServiceIntroduce}/>
                    <Route path="/place" component={PlaceOrder}/>
                    <Route path="/company" component={Introduce}/>
                    <Route path="/transform/:name/:id?" component={TransformIntroduce}/>
                </div>
            </Router>
        )
    }
}
// ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<Hello/>, document.getElementById('root'));
let root = document.getElementById("root")
if (root.hasChildNodes()) {
    hydrate(<Hello/>, root);
    // console.log("hydrate")
} else {
    render(<Hello/>, root)
    // console.log("render")
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
