import React from "react";
import process_background from "../../assets/img/process/process_background.png";
import sign from "../../assets/img/process/摄图网_501130327_wx_商务签订合同（非企业商用）.png";
import check_car from "../../assets/img/process/图层 5.png";
import pickup_car from "../../assets/img/process/摄图网_401598876_wx_汽车交易（非企业商用）.png";
import deal_car from "../../assets/img/process/摄图网_500677970_wx_新车成交的车钥匙特写（非企业商用）.png";
import insurance_car from "../../assets/img/process/摄图网_501540484_wx_汽车保险保护爱车（非企业商用）.png";
import pay_car from "../../assets/img/process/摄图网_501583765_wx_手机消费移动支付POS机（非企业商用）.png";
import comment_car from "../../assets/img/process/图层 4.png";
import line from "../../assets/img/process/组 3 拷贝 5.png"
import call from "../../assets/img/process/摄图网_500133665_wx_商务人士机场打电话（非企业商用） (1).png";
import {View} from "antd-mobile";
import Text from "antd-mobile/es/text";
import ParticleFoot from '../foot/foot.js'

require('./content_process.css')

class ContentProcess extends React.Component {
    render() {
        return <div className='contentProcess'
                    style={{
                        width: "100%",
                        height: 1480,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 0,
                        textAlign: "center",
                        backgroundImage: `url(${process_background})`
                    }}>
            <div style={{width: "100%", height: 1123}}>
                <div style={{paddingTop: 85,}}>
                <span style={{
                    width: 144,
                    height: 36,
                    fontSize: 36,
                    fontFamily: "Microsoft YaHei",
                    fontWeight: 400,
                    color: "#1A1A1A",
                }}>服务流程</span>
                </div>
                <div>
            <span style={{
                width: 115,
                height: 17,
                fontSize: 16,
                fontFamily: "Microsoft YaHei",
                fontWeight: 400,
                color: "#4D4D4D",
            }}>Service Process</span>
                </div>
                <img src={line} style={{paddingTop: 25}}></img>
                <View style={{justifyContent: "center", paddingTop: 39}}>
                    <Text style={{
                        color: "#1A1A1A",
                        fontSize: 16,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                    }}>公司秉承着最专业、智能、高效、严谨、高客户体验的服务理念，</Text>
                </View>
                <View style={{justifyContent: "center", marginTop: 10}}>
                    <Text style={{
                        color: "#1A1A1A",
                        fontSize: 16,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                    }}>严格执行我们的专业服务流程：下单、签合同、验车、提车、运输、交车、付款、评价反馈。
                    </Text>
                </View>
                <View style={{marginTop: 10}}>
                    <Text style={{
                        color: "#1A1A1A",
                        fontSize: 16,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                    }}>每个环节做到标准、专业、细致、可靠。
                    </Text>
                </View>
                <div className="processTest" style={{
                    width: 1200,
                    height: 656,
                    // backgroundColor: "green",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 40,
                }}>
                    <div>
                        <div style={{float: "left",}}>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left"}}>
                                <div className="processBlock1"
                                     style={{
                                         width: 285,
                                         height: 200,
                                         float: "left",
                                     }}>
                                    <img src={call}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 302, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>1、下单
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>在线下单，方便快捷
                                        </Text>
                                    </View>
                                </div>
                            </div>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left", marginLeft: 17}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={sign}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>2、签约
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>电子合同，永久存档
                                        </Text>
                                    </View>
                                </div>
                            </div>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left", marginLeft: 17}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={check_car}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>3、验车
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>专业验车，避免纠纷
                                        </Text>
                                    </View>
                                </div>
                            </div>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left", marginLeft: 17}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={pickup_car}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>4、提车
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>自营团队，安全可靠
                                        </Text>
                                    </View>
                                </div>
                            </div>
                        </div>
                        <div style={{float: "left", marginTop: 20}}>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left"}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={insurance_car}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>5、运输
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>实时监控，在途可观
                                        </Text>
                                    </View>
                                </div>
                            </div>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left", marginLeft: 17}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={deal_car}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>6、交车
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>提前约定，准时交付
                                        </Text>
                                    </View>
                                </div>
                            </div>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left", marginLeft: 17}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={pay_car}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>7、付款
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>验车付款，订单完结
                                        </Text>
                                    </View>
                                </div>
                            </div>
                            <div className="processBlock"
                                 style={{width: 285, height: 317, float: "left", marginLeft: 17}}>
                                <div className="processBlock1"
                                     style={{width: 285, height: 200, float: "left"}}>
                                    <img src={comment_car}></img>
                                </div>
                                <div className="processBlock2"
                                     style={{width: 285, height: 117, backgroundColor: "white", float: "left"}}>
                                    <View style={{marginTop: 34,}}>
                                        <Text style={{
                                            color: "#1A1A1A",
                                            fontSize: 18,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: "bold",
                                        }}>8、评价
                                        </Text>
                                    </View>
                                    <View style={{justifyContent: "center"}}>
                                        <Text style={{
                                            color: "#4D4D4D",
                                            fontSize: 14,
                                            fontFamily: "Microsoft YaHei",
                                            fontWeight: 400,
                                        }}>客户回访，持续完善
                                        </Text>
                                    </View>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ParticleFoot></ParticleFoot>
        </div>
    }
}

export default ContentProcess