import React from "react";
import IMG_LOGO from "../../assets/img/logo/logo.png";
import {Link} from 'react-router-dom'
import {browserHistory} from 'react-router';

require('./head.css')

class Head extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <div style={{
            backgroundColor: "rgba(44,55,58, 0.5)",
            width: "100%",
            height: 88,
            position: "fixed",
            top: 0,
            zIndex: 99,
        }}>
            <div style={{
                width: "100%",
                position: "relative",
                height: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: 20,
                fontWeight: "bold",
                color: "white"
            }}>
                <Link to="/">
                    <img style={{marginTop: 13.5, position: "relative", float: "left", height: 63, marginLeft: "15%"}}
                         src={IMG_LOGO}/>
                </Link>
                <Link to="/">
                    <div className="navButton" style={{
                        float: "left",
                        cursor: "pointer",
                        width: 165,
                        marginLeft: "5%",
                        textAlign: "center",
                        height: "100%"
                    }}>
                        <span className="navButtonContent"
                              style={{verticalAlign: "middle", marginTop: 30, display: "block"}}>首页</span>
                        <div className="downLine" style={{
                            display: browserHistory.getCurrentLocation().pathname === '/' ? "block" : "none",
                            position: "relative",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 23,
                            height: 5,
                            backgroundColor: "#2EB2A6",
                            marginTop: 18
                        }}/>

                    </div>
                </Link>
                <Link to="/place">
                    <div className="navButton" style={{
                        float: "left",
                        cursor: "pointer",
                        width: 165,
                        marginLeft: "2%",
                        textAlign: "center",
                        height: "100%"
                    }}>
                        <span className="navButtonContent"
                              style={{
                                  verticalAlign: "middle",
                                  marginTop: 30,
                                  display: "block",
                              }}>在线下单</span>
                        <div className="downLine" style={{
                            display: browserHistory.getCurrentLocation().pathname === '/place' ? "block" : "none",
                            position: "relative",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 23,
                            height: 5,
                            backgroundColor: "#2EB2A6",
                            marginTop: 18
                        }}/>
                    </div>
                </Link>
                <Link to="/company">
                    <div className="navButton" style={{
                        float: "left",
                        cursor: "pointer",
                        width: 165,
                        marginLeft: "2%",
                        textAlign: "center",
                        height: "100%"
                    }}>
                        <span className="navButtonContent"
                              style={{
                                  verticalAlign: "middle",
                                  marginTop: 30,
                                  display: "block",
                              }}>公司简介</span>
                        <div className="downLine" style={{
                            display: browserHistory.getCurrentLocation().pathname === '/company' ? "block" : "none",
                            position: "relative",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 23,
                            height: 5,
                            backgroundColor: "#2EB2A6",
                            marginTop: 18
                        }}/>
                    </div>
                </Link>
                <div className="navButton" style={{
                    float: "left",
                    cursor: "pointer",
                    width: 165,
                    marginLeft: "2%",
                    textAlign: "center",
                    height: "100%"
                }}>
                        <span className="navButtonContent"
                              style={{
                                  verticalAlign: "middle",
                                  marginTop: 30,
                                  display: "block",
                              }}>服务介绍</span>
                    <div className="downLine" style={{
                        display: browserHistory.getCurrentLocation().pathname === '/service' ? "block" : "none",
                        position: "relative",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 23,
                        height: 5,
                        backgroundColor: "#2EB2A6",
                        marginTop: 18
                    }}/>
                    <div className="subButton" style={{
                        // position: "absolute",
                        width: 165,
                        backgroundColor: "rgba(33,44,47, 0.9)",
                        // top: 88,
                        zIndex: 99,
                        marginTop: browserHistory.getCurrentLocation().pathname === '/service' ? 5 : 27,
                        // left: "74%"
                    }}>
                        <Link to={{pathname: '/service/1', state: {id: 1}}}>
                            <div className="subMenu">服务范围</div>
                        </Link>
                        <div className="splitLine"/>
                        <Link to={{pathname: '/service/2', state: {id: 2}}}>
                            <div className="subMenu">服务流程</div>
                        </Link>
                        <div className="splitLine"/>
                        <Link to={{pathname: '/service/3', state: {id: 3}}}>
                            <div className="subMenu">服务优势</div>
                        </Link>
                        <div className="splitLine"/>
                        <Link to={{pathname: '/service/4', state: {id: 4}}}>
                            <div className="subMenu">服务案例</div>
                        </Link>
                        <div className="splitLine"/>
                    </div>
                </div>
                <div className="navButton" style={{
                    float: "left",
                    cursor: "pointer",
                    width: 165,
                    marginLeft: "2%",
                    textAlign: "center",
                    height: "100%"
                }}>
                       <span className="navButtonContent"
                             style={{
                                 verticalAlign: "middle",
                                 marginTop: 30,
                                 display: "block",
                             }}>了解托运</span>
                    <div className="downLine" style={{
                        display: browserHistory.getCurrentLocation().pathname === '/transform' ? "block" : "none",
                        position: "relative",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 23,
                        height: 5,
                        backgroundColor: "#2EB2A6",
                        marginTop: 18
                    }}/>
                    <div className="subButton" style={{
                        // position: "absolute",
                        width: 165,
                        backgroundColor: "rgba(33,44,47, 0.9)",
                        // top: 88,
                        zIndex: 99,
                        marginTop: browserHistory.getCurrentLocation().pathname === '/transform' ? 5 : 27
                        // left: "74%"
                    }}>
                        <Link to={{pathname: '/transform/qa', state: {id: 1, show: "qa"}}}>
                            <div className="subMenu">托运须知</div>
                        </Link>
                        <div className="splitLine"/>
                        <Link to={{pathname: '/transform/news', state: {id: 2, show: "news"}}}>
                            <div className="subMenu">最新资讯</div>
                        </Link>
                        <div className="splitLine"/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Head