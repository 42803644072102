import {postJsonData, api, getData} from '../request';
import {message} from "antd";

/**
 * 下单
 * @param originProvinceName 出发省份名字
 * @param originProvinceCode 出发省份code
 * @param originCityName 出发城市名字
 * @param originCityCode 出发城市code
 * @param deliverProvinceName 目的地省份名字
 * @param deliverProvinceCode 目的地省份code
 * @param deliverCityName 目的地城市名字
 * @param deliverCityCode 目的地城市code
 * @param originUserPhone 手机号
 * @param callBack 回调
 */
export function place_order(originProvinceName, originProvinceCode, originCityName, originCityCode, deliverProvinceName, deliverProvinceCode, deliverCityName, deliverCityCode, originUserPhone, callBack) {
    postJsonData(api.placeOrder, {
        // originProvinceName: originProvinceName,
        // originProvinceCode: originProvinceCode,
        // originCityName: originCityName,
        // originCityCode: originCityCode,
        // deliverProvinceName: deliverProvinceName,
        // deliverProvinceCode: deliverProvinceCode,
        // deliverCityName: deliverCityName,
        // deliverCityCode: deliverCityCode,
        // originUserPhone: originUserPhone,
        // channel: "web",

        originCode: `${originProvinceCode}/${originCityCode}`,
        originValue: `${originProvinceName}/${originCityName}`,
        destinationCode: `${deliverProvinceCode}/${deliverCityCode}`,
        destinationValue: `${deliverProvinceName}/${deliverCityName}`,
        // TODO 来源写死
        sourceCode: 'huozhu',
        sourceName: '货主端'
    }).then(res => {
        if (res.code === 0) {
            callBack(true, res.message);
        } else
            callBack(false, res.message);
    }, rej => {
        callBack(false);
    });
}


export function get_24h_order_info(callBack) {
    getData(api.get24HourOrderInfo)
        .then(res => {
            if (res.code === 0) {
                callBack(true, res.message, res.data)
            } else
                callBack(false, res.message)
        }, rej => {
            callBack(false)
        });
}

export function getVerifyCode(phoneNumber, callBack) {
  getData(api.getVerifyCode, { phoneNumber }).then(res => {
        if (res.code === 0) {
            callBack(true, res.message);
        } else
            callBack(false, res.message);
    }, rej => {
        callBack(false);
    });
}

export function place_order_withVerifyCode(phoneNumber, code, originProvinceName, originProvinceCode,
                                           originCityName, originCityCode, deliverProvinceName,
                                           deliverProvinceCode, deliverCityName, deliverCityCode,
                                           originUserPhone, callBack) {
    postJsonData(api.loginWithVerifyCode,
        {
            telPhone: phoneNumber,
            code: code
        }).then(res => {
        if (res.code === 0) {
            const { token } = res.data
            localStorage.setItem('Authorization', token)
            place_order(originProvinceName, originProvinceCode,
                originCityName, originCityCode, deliverProvinceName,
                deliverProvinceCode, deliverCityName, deliverCityCode, originUserPhone, callBack)
        } else {
            message.error(res.message ? res.message : "未知错误");
            callBack(false, res.message);
        }

    }, rej => {
        callBack(false);
    });
}
