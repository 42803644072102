import {postJsonData, api, getData} from '../request';
import {message} from "antd";

export function get_front_page_must_know2(url, callBack) {
    getData(url, {index: 0, size: 1000}).then(res => {
        if (res.code === 200) {
            let datas = res.data.data;
            callBack(true, "获取成功", datas);
        } else {
            callBack(false,)
        }
    })
}

export function get_front_page_must_know(callBack) {
    getData(api.must_know, {index: 0, size: 1000}).then(res => {
        if (res.code === 200) {
            let datas = res.data.data;
            datas = datas.slice(0, 5);
            let objs = [];
            datas.forEach(data => {
                let obj = {};
                obj.question = data.title;
                obj.answer = data.desc;
                obj.pageTitle = data.pageTitle;
                obj.meta = data.meta;
                objs.push(obj);
            })
            callBack(true, "获取成功", objs);
        } else {
            callBack(false,)
        }
    })
    // callBack(true, "获取成功",
    //     [
    //         {
    //             question: "问题1?",
    //             answer: "答案1",
    //         },
    //         {
    //             question: "问题2?",
    //             answer: "答案2",
    //         },
    //         {
    //             question: "问题3?",
    //             answer: "答案3",
    //         },
    //         {
    //             question: "问题4?",
    //             answer: "答案4",
    //         },
    //         {
    //             question: "问题5?",
    //             answer: "答案5",
    //         },
    //     ]
    // );
}
