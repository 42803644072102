import React from "react";
import process_background from "../../assets/img/process/process_background.png";
import line from "../../assets/img/process/组 3 拷贝 5.png";
import {View} from "antd-mobile";
import Text from "antd-mobile/es/text";
import TRUCK from "../../assets/img/servicescope/truck.png"
import SHADOW from "../../assets/img/servicescope/矩形 12 拷贝 2.png"
import ParticleFoot from '../foot/foot.js'
import BLACK_WHITE_3 from "../../assets/img/servicescope/黑白 3.png";
import BLACK_WHITE_4 from "../../assets/img/servicescope/黑白 4.png";
import BLACK_WHITE_5 from "../../assets/img/servicescope/黑白 5.png";
import BLACK_WHITE_6 from "../../assets/img/servicescope/黑白 6.png";
import ReactMarkdown from "react-markdown/with-html";

require('./service_scope.css')

class ServiceScope extends React.Component {

    render() {
        return <div className="ServiceScope" style={{
            width: "100%",
            height: 1480,
            marginTop: 0,
            textAlign: "center",
            backgroundImage: `url(${process_background})`
        }}>
            <div style={{width: "100%", height: 1123}}>
                <div style={{paddingTop: 85,}}>
                <span style={{
                    width: 144,
                    height: 36,
                    fontSize: 36,
                    fontFamily: "Microsoft YaHei",
                    fontWeight: 400,
                    color: "#1A1A1A",
                }}>服务范围</span>
                </div>
                <div>
                     <span style={{
                         width: 115,
                         height: 17,
                         fontSize: 16,
                         fontFamily: "Microsoft YaHei",
                         fontWeight: 400,
                         color: "#4D4D4D",
                     }}>Service Process</span>
                </div>
                <img src={line} style={{paddingTop: 25}}></img>
                <View style={{width: 977, marginLeft: "auto", marginRight: "auto", paddingTop: 39}}>
                    <Text style={{
                        color: "#1A1A1A",
                        fontSize: 16,
                        fontFamily: "Microsoft YaHei",
                        fontWeight: 400,
                        lineHeight: 2,
                    }}>本公司根据客户需求并根据车辆属性提供对应的专业定制化服务，以满足用户需求为首要目标。服务范围涵盖汽车运输、汽车检验、汽车仓储、同城提车、同城配送、通过多年的主机厂整车物流服务的经验积累，形成检验、仓储、计划、调度、装卸、运输、客服、交付、结算等服务体系，尤其注重车辆和司机细节管理。更在原有业务新车服务的基础上推出粒子运车品牌，开展二手车、私家车、自驾游车、租赁车、网约车、电动车、平台车、监管车、事故车、实验车、定制车等散车运输服务。</Text>
                </View>
                <div style={{
                    width: 1200,
                    height: 230,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 60,
                }}>
                    <div className="service_scope_card"
                         style={{
                             width: 285,
                             height: 230,
                             float: "left",
                             backgroundImage: `url("${BLACK_WHITE_3}")`,
                             backgroundSize: "contain",
                             position: "relative"
                         }}>

                        <img className="scope_shadow1" src={SHADOW} style={{
                            width: "100%",
                            height: "105%",
                            display: this.state.showShadow1 ? 'block' : 'none'
                        }}/>
                        <div style={{
                            width: 112,
                            height: 70,
                            position: "absolute",
                            left: 87,
                            top: 79
                        }}>
                            <img src={TRUCK}></img>
                            <div style={{
                                fontSize: 16,
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: 8,
                                color: "white"
                            }}>商品车运输
                            </div>
                        </div>
                    </div>
                    <div className="service_scope_card"
                         style={{
                             width: 285,
                             height: 230,
                             float: "left",
                             backgroundImage: `url("${BLACK_WHITE_4}")`,
                             backgroundSize: "contain",
                             marginLeft: 20,
                             position: "relative"
                         }} onMouseEnter={this.hover2}
                         onMouseLeave={this.hover2_leave}>
                        <img className="scope_shadow2" src={SHADOW} style={{
                            width: "100%",
                            height: "105%",
                            display: this.state.showShadow2 ? 'block' : 'none'
                        }}/>
                        <div style={{
                            width: 112,
                            height: 70,
                            position: "absolute",
                            left: 87,
                            top: 79
                        }}>
                            <img src={TRUCK}></img>
                            <div style={{
                                fontSize: 16,
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: 8,
                                color: "white"
                            }}>二手车运输
                            </div>
                        </div>
                    </div>
                    <div className="service_scope_card"
                         style={{
                             width: 285,
                             height: 230,
                             float: "left",
                             backgroundImage: `url("${BLACK_WHITE_5}")`,
                             backgroundSize: "contain",
                             marginLeft: 20,
                             position: "relative"
                         }} onMouseEnter={this.hover3}
                         onMouseLeave={this.hover3_leave}>
                        <img className="scope_shadow3" src={SHADOW} style={{
                            width: "100%", height: "105%",
                            display: this.state.showShadow3 ? 'block' : 'none'
                        }}/>
                        <div style={{
                            width: 112,
                            height: 70,
                            position: "absolute",
                            left: 87,
                            top: 79
                        }}>
                            <img src={TRUCK}></img>
                            <div style={{
                                fontSize: 16,
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: 8,
                                color: "white"
                            }}>高端车运输
                            </div>
                        </div>
                    </div>
                    <div className="service_scope_card"
                         style={{
                             width: 285,
                             height: 230,
                             float: "left",
                             backgroundImage: `url("${BLACK_WHITE_6}")`,
                             backgroundSize: "contain",
                             marginLeft: 20,
                             position: "relative"
                         }} onMouseEnter={this.hover4}
                         onMouseLeave={this.hover4_leave}>
                        <img className="scope_shadow4" src={SHADOW}
                             style={{
                                 width: "100%",
                                 height: "105%",
                                 display: this.state.showShadow4 ? 'block' : 'none'
                             }}/>
                        <div style={{
                            width: 112,
                            height: 70,
                            position: "absolute",
                            left: 87,
                            top: 79
                        }}>
                            <img src={TRUCK}></img>
                            <div style={{
                                fontSize: 16,
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: 8,
                                color: "white"
                            }}>私家车运输
                            </div>
                        </div>
                    </div>
                </div>
                <View className="service_scope_card_desc" style={{
                    width: 1200,
                    height: 206,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 10,
                }}>
                    <ReactMarkdown source={this.state.desc} escapeHtml={false}/>
                </View>
            </div>
            <ParticleFoot></ParticleFoot>
        </div>
    }

    constructor(props) {
        super(props);
        this.state = {
            showShadow1: true,
            showShadow2: false,
            showShadow3: false,
            showShadow4: false,
            desc: this.descInit,
        }

    }

    descInit = "<div style='text-align: left; font-size: 16px; margin-top: 30px;" +
        "margin-left: 20px;margin-right: 20px; display: inline-block;line-height: 30px;color: white'>商品车运输泛指新车运输，多为主机厂生产车辆之后，从主机厂场地运输到全国各地4s店。也有4s店之间调拨，汽贸公司之间调拨，以及外地买家运输等情况。" +
        "需要运输的新车大部分没有正式牌照，多数是临时牌照，少数连临时牌照也没有，且新车不能产生较多的公里数，所以一般不会接受地跑行为。\n因此我公司处理商品车运输有两种方案：</br>" +
        "方案一：若单一运程（始发地-目的地）运输车辆可拼成整板（7-8台），可安排板车到目的地装车，并直接送至目的地。\n\n" +
        "方案二：若运程不同，且同线路单台，可安排小板车将车提到装车场地，到达目的地物流场地后再用小板送到指定交车点。\n</div>";
    hover2 = () => {
        this.setState({
            showShadow1: false,
            showShadow2: true,
            showShadow3: false,
            showShadow4: false,
            desc: "<p style='text-align: left; font-size: 16px; margin-top: 30px;" +
                "margin-left: 20px;margin-right: 20px; display: inline-block;line-height: 30px;color: white'>二手车运输多为二手车异地交易产生的物流需求，可以接受地跑提车，" +
                "并大板装车运输到目的地。根据整体的距离长短及客户要求，我们会有相对应的解决方案。</br>" +
                "方案一：若运程距离较短，且线路较偏，则优先选择地跑送车。</br>" +
                "方案二：若运程距离比较长，则推荐客户主线或最优中转方案，以价格最低及时效最快为标准。</p>"
        })
    }
    hover2_leave = () => {
        this.setState({
            showShadow1: true,
            showShadow2: false,
            showShadow3: false,
            showShadow4: false,
            desc: this.descInit,
        })
    };
    hover3 = () => {
        this.setState({
            showShadow1: false,
            showShadow2: false,
            showShadow3: true,
            showShadow4: false,
            desc: "<p style='text-align: left; font-size: 16px; margin-top: 30px;" +
                "margin-left: 20px;margin-right: 20px; display: inline-block;line-height: 30px;color: white'>" +
                "高端车运输也叫豪车运输，此类车身价值比较高，我们并不建议选择大板车中转或地跑方式运输，更倾向于全程小板车门到门运输到目的地。可以更好的保障客户爱车的时效、安全。</p>"
        })
    };
    hover3_leave = () => {
        this.setState({
            showShadow1: true,
            showShadow2: false,
            showShadow3: false,
            showShadow4: false,
            desc: this.descInit,
        })
    };
    hover4 = () => {
        this.setState({
            showShadow1: false,
            showShadow2: false,
            showShadow3: false,
            showShadow4: true,
            desc: "<p style='text-align: left; font-size: 16px; margin-top: 30px;" +
                "margin-left: 20px;margin-right: 20px; display: inline-block;line-height: 30px;color: white'>" +
                "私家车运输一般面向个人用户，因出差、工作调动、旅游等原因导致汽车需要运输。那会对时效要求比较精确，" +
                "我们会针对客户的出发时间、需要到达时间给出具体方案，准确在提车段、运输段及末端配送端给出最适合的运输方案，" +
                "满足价格与时效的双要求。同时客户因其他原因延误收车的，我们可以配合客户负责车辆的短期保管工作。</p>"
        })
    };
    hover4_leave = () => {
        this.setState({
            showShadow1: true,
            showShadow2: false,
            showShadow3: false,
            showShadow4: false,
            desc: this.descInit,
        })
    }

}

export default ServiceScope