import * as React from "react";
import BACKGROUND2 from "../../assets/img/transfer_introduce/矩形 19 拷贝.png";
import line from "../../assets/img/process/组 3 拷贝 5.png";
import BACKGROUND from "../../assets/img/service_case/WechatIMG505.png";
import ParticleFoot from "../foot/foot";
import {get_front_page_cases, get_front_page_cases_2} from "../../action/case_action";
import api from "../../request/api";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

export default class ServiceCase extends React.Component {

    constructor(props) {
        super(props);
        // console.log(props);
        this.state =
            {
                list: [1, 2, 3, 4, 5, 6],
                caseData: [],
                show1: props.show,
                content: "121212",
                pageTitle: props.pageTitle,
                meta: props.meta,
                title: props.title,
                index: 0,
            };
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.show) {
            this.setState({
                pageTitle: api.defaultTitle,
                meta: '',
            })
        } else if (!this.props.show) {
            let item = this.state.caseData[this.state.index];
            this.setState({
                pageTitle: (this.state.pageTitle === undefined || this.state.pageTitle === '' || this.state.pageTitle === '粒子运车') ?
                    item.pageTitle : this.state.pageTitle,
                meta: (this.state.meta === undefined || this.state.pageTitle === '' || this.state.pageTitle === '粒子运车') ?
                    item.meta : this.state.meta,
            })
            // this.props.callback(`/service/4/` + item.title + '.html');
        }
        this.setState({
            show1: nextProps.show
        })

    }

    componentWillMount() {

        get_front_page_cases_2(api.baseUrl3 + "/service_case/list", (result, msg, datas) => {
                if (result && datas) {
                    {
                        let index = 0;
                        if (this.state.title) {

                            index = this.state.title.split('.')[0];
                            index = datas.length - index;
                            // for (let i = 0; i < datas.length; i++) {
                            //     if (datas[i].title===title){
                            //         index = i;
                            //     }
                            // }
                        }
                        this.setState({caseData: datas});
                        this.setState({
                            active: index,
                            content: datas[index].content,
                        })
                        if (this.state.show1) {
                            this.setState({
                                pageTitle: (this.state.pageTitle === undefined || this.state.pageTitle === '') ? datas[index].pageTitle : this.state.pageTitle,
                                meta: (this.state.meta === undefined || this.state.pageTitle === '') ? datas[index].meta : this.state.meta,
                            })
                        }
                        datas.forEach((item, index) => {
                            if (this.props.caseId && item.id === this.props.caseId) {
                                this.setState({
                                    active: index,
                                    content: item.content,
                                })
                            }
                        })
                    }
                }
            }
        );
    }

    handleClick = (index, number, that) => {
        // this.setState({
        //     active: index,
        //     content: number.content,
        //     pageTitle: number.pageTitle,
        //     meta: number.meta,
        //     index: index,
        // })
        // this.props.callback(`/service/4/` + number.title + '.html');
    };
    createGeneratedContentCase = () => (<div style={{
        width: 1200,
        height: 700,
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: 50,
    }}>
        <div style={{
            float: "left",
            width: 300,
            maxHeight: 550,
            backgroundColor: "white",
            overflowY: "auto",
            overflowX: "hidden",
        }}>
            <ul style={{
                listStyle: "none",
                marginLeft: "auto",
                marginRight: "auto",
                padding: 0,
                width: 280
            }}>
                {this.state.caseData.map((number, index) =>
                    <Link to={'/service/4/?index=' + (this.state.caseData.length - index)} target='_blank'>
                        <li className={`li-${index}`} style={this.state.active + '' === index + '' ? {
                            border: '1px solid rgba(46, 178, 166, 1)',
                            width: 285,
                            height: 160,
                            marginTop: 5,
                            cursor: "pointer",
                        } : {
                            backgroundColor: '#F5F5F5',
                            width: 285,
                            height: 160,
                            marginTop: 5,
                            cursor: "pointer",
                        }}>
                            <img src={number.image}
                                 style={{flex: 690, height: "100%", objectFit: "cover"}}/>
                        </li>
                    </Link>)
                }

            </ul>
        </div>
        <div style={{
            float: "right",
            width: 861,
            height: 680,
            backgroundColor: "white",
            overflowY: "auto",
        }}>
            <div className='editor-wrapper' dangerouslySetInnerHTML={{__html: this.state.content}}/>
        </div>
    </div>);

    render() {
        return <div style={{
            width: "100%",
            height: 1080,
            textAlign: "center",
            backgroundImage: `url("${BACKGROUND}")`
        }}>
            <Helmet>
                <meta name="description" content={this.state.meta}/>
                <meta name="keywords" content={this.state.meta}/>
                <title>{this.state.pageTitle}</title>
            </Helmet>
            <div style={{display: this.state.show1 ? 'block' : 'none'}}>
                <div style={{textAlign: "center", marginRight: "auto", marginLeft: "auto", paddingTop: 102}}>
                    <div style={{fontSize: 36, fontFamily: "Microsoft YaHei", fontWeight: 400}}>服务案例</div>
                    <div style={{fontSize: 16, fontFamily: "Microsoft YaHei", fontWeight: 400}}>Service cases
                    </div>
                    <img src={line} style={{marginTop: 25}}></img>
                </div>
                {
                    this.state.caseData.length > 0 ? this.createGeneratedContentCase() : <br/>
                }
            </div>
            <ParticleFoot></ParticleFoot>
        </div>
    }
}