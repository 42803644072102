import * as React from "react";
import Head from "../head/head";
import BACKGROUND from "../../assets/img/place/banner.png"
import BACKGROUND2 from "../../assets/img/place/黑白 1.png"
import ParticleFoot from "../foot/foot";
import line from "../../assets/img/process/组 3 拷贝 5.png";
import AddressChooser from "../address_chooser";
import IMG_ICON_ARROW_DOWN_GRAY from "../../assets/img/icon/icon_arrow_down_gray.png";
import BACKGROUND3 from "../../assets/img/place/图层 5.png"
import PHONEICON from "../../assets/img/place/24小时服务热线 (1).png"
import ONE from "../../assets/img/place/组 10.png"
import TWO from "../../assets/img/place/组 10 拷贝.png"
import THREE from "../../assets/img/place/组 10 拷贝 2.png"
import FOUR from "../../assets/img/place/组 10 拷贝 3.png"
import FIVE from "../../assets/img/place/组 10 拷贝 4.png"
import SIX from "../../assets/img/place/组 10 拷贝 5.png"
import SEVEN from "../../assets/img/place/组 10 拷贝 6.png"
import EIGHT from "../../assets/img/place/组 10 拷贝 7.png"
import NINE from "../../assets/img/place/矩形 6.png"
import ARROW from "../../assets/img/place/arrow.png"
import {Card, message} from 'antd';
import {white} from "color-name";
import {get_24h_order_info, getVerifyCode, place_order, place_order_withVerifyCode} from "../../action/order_action";
import Text from "antd-mobile/es/text";
import {Helmet} from "react-helmet";

require('./place_order.css')

class PlaceOrder extends React.Component {

    componentDidMount() {

        get_24h_order_info((result, msg, data) => {
            if (result) {
                if (data && data.length > 0) {
                    let set = new Set();
                    let leftData = [];
                    let rightData = [];
                    data.forEach((item, index) => {
                        if (!item.deliverCityName || !item.originCityName) {
                            return;
                        }
                        if (set.has(JSON.stringify(item))) {
                            return;
                        }
                        set.add(JSON.stringify(item))
                        item.time = this.getRandomTime();
                        (index % 2 === 0 ? leftData : rightData).push(item);
                    });
                    this.setState({realTimeOrderLeftData: leftData, realTimeOrderRightData: rightData});
                }
            }
        });
        this.setState({
            frontPageTimer: setInterval(() => {
                this.setState({currentFrontPage: this.state.currentFrontPage === 0 ? 1 : (this.state.currentFrontPage === 1 ? 2 : 0)});
            }, 4000)
        });

        this.setState({
            frontPageTimer: setInterval(() => {
                if (this.state.realTimeOrderLeftData && this.state.realTimeOrderLeftData.length > 0)
                    this.setState({
                        realTimeOrderLeftIndex: this.state.realTimeOrderLeftIndex + 1 >= this.state.realTimeOrderLeftData.length ? 0 : this.state.realTimeOrderLeftIndex + 1,
                    });
                if (this.state.realTimeOrderRightData && this.state.realTimeOrderRightData.length > 0)
                    this.setState({
                        realTimeOrderRightIndex: this.state.realTimeOrderRightIndex + 1 >= this.state.realTimeOrderRightData.length ? 0 : this.state.realTimeOrderRightIndex + 1,
                    });

            }, 3000)
        });
    }

    componentWillUnmount() {
        if (this.state.frontPageTimer != null) {
            clearInterval(this.state.frontPageTimer);
        }
        if (this.state.realTimeOrderTimer != null) {
            clearInterval(this.state.realTimeOrderTimer);
        }
        clearInterval(this.timer);
    }

    constructor(props) {
        super(props)
        this.state = {
            verifyCodeFlag: true,
            verifyCodeCount: 0,
            verifyCodeCountInit: 60,
            searchValueSender: "",
            searchValueReceive: "",
            receiveAddress: {},
            senderAddress: {},
            verifyCode: '',
            phone: "",
            showSenderAddressChooser: false,
            showReceiverAddressChooser: false,
            frontPageTimer: null,
            realTimeOrderTimer: null,
            realTimeOrderLeftData: [],
            realTimeOrderRightData: [],
            realTimeOrderLeftIndex: 0,
            realTimeOrderRightIndex: 0,
            pageTitle:"在线下单 - 深圳车粒子科技有限公司-汽车托运价格表,哪家好",
            meta:"在线下单"
        }
    }

    render() {
        return <div style={{
            maxWidth: 1920, width: "100%", marginLeft: "auto",
            marginRight: "auto"
        }}>
            <Helmet>
                <meta name="description" content={this.state.meta}/>
                <meta name="keywords" content={this.state.meta}/>
                <title>{this.state.pageTitle}</title>
            </Helmet>
            <div style={{
                width: "100%",
                height: "34vw",
                backgroundImage: `url(${BACKGROUND})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",

            }}>
                <Head></Head>
            </div>
            <div style={{
                width: "100%",
                height: 1063,
                backgroundRepeat: "no-repeat",
                backgroundImage: `url("${BACKGROUND2}")`,
                marginRight: "auto",
                marginLeft: "auto",
                textAlign: "center",
            }}>
                <div style={{paddingTop: 100,}}>
                <span style={{
                    width: 144,
                    height: 36,
                    fontSize: 36,
                    fontFamily: "Microsoft YaHei",
                    fontWeight: 400,
                    color: "#1A1A1A",
                }}>专属运输方案</span>
                </div>
                <div>
                     <span style={{
                         width: 115,
                         height: 17,
                         fontSize: 16,
                         fontFamily: "Microsoft YaHei",
                         fontWeight: 400,
                         color: "#4D4D4D",
                     }}>Exclusive Transportation Plan</span>
                </div>
                <img src={line} style={{paddingTop: 25}}></img>
                <div style={{width: 1400, height: 400, marginLeft: "auto", marginRight: "auto", marginTop: 50}}>
                    <div style={{width: 700, overFlow: "hidden", float: "left"}}>
                        <div style={{
                            width: 700,
                            position: "relative",
                            cursor: "pointer"
                        }}>
                            <label style={{fontSize: 22, fontWeight: 800, fontFamily: "MicroSoft YaHei"}}>始发地</label>
                            <label style={{
                                fontSize: 18,
                                fontWeight: 800,
                                fontFamily: "MicroSoft YaHei",
                                color: "red"
                            }}> *</label>
                            <input className="valueSender" value={this.state.searchValueSender}
                                   onChange={(e) => this.setState({searchValueSender: e.target.value})}
                                   onClick={(e) => this.setState({
                                       showSenderAddressChooser: !this.state.showSenderAddressChooser,
                                       showReceiverAddressChooser: false
                                   })}
                                   style={{
                                       display: "inline-block",
                                       // top: 13,
                                       // left: 42,
                                       color: "#000000",
                                       // position: "absolute",
                                       fontSize: 18,
                                       marginLeft: 20,
                                       // backgroundColor: "transparent",
                                       appearance: "none",
                                       outline: "none"
                                   }} type="text" placeholder="请选择始发城市"/>
                            <img src={IMG_ICON_ARROW_DOWN_GRAY}
                                 style={{position: "absolute", width: 12, top: 25, marginTop: -2, right: 105}}
                                 onClick={(e) => this.setState({
                                     showSenderAddressChooser: !this.state.showSenderAddressChooser,
                                     showReceiverAddressChooser: false
                                 })}/>
                            <div style={{
                                width: 1200,
                                marginLeft: "auto",
                                marginRight: "auto",
                                position: "relative",
                                top: 20
                            }}>
                                {
                                    this.state.showSenderAddressChooser ?
                                        <AddressChooser keyWord={this.state.searchValueSender}
                                                        handleData={(data) => this.setState({
                                                            senderAddress: data,
                                                            showSenderAddressChooser: false,
                                                            searchValueSender: data.name
                                                        })} style={{
                                            position: "absolute",
                                            zIndex: 99,
                                            top: -20,
                                            left: 197,
                                            color: "black"
                                        }}/> : null
                                }
                            </div>
                        </div>
                        <div style={{
                            width: 700,
                            marginTop: 20,
                            position: "relative",
                            cursor: "pointer"
                        }}>
                            <label style={{fontSize: 22, fontWeight: 800, fontFamily: "MicroSoft YaHei"}}>目的地</label>
                            <label style={{
                                fontSize: 18,
                                fontWeight: 800,
                                fontFamily: "MicroSoft YaHei",
                                color: "red"
                            }}> *</label>
                            <input className="valueReceive" value={this.state.searchValueReceive}
                                   onChange={(e) => this.setState({searchValueReceive: e.target.value})}
                                   onClick={(e) => this.setState({
                                       showReceiverAddressChooser: !this.state.showReceiverAddressChooser,
                                       showSenderAddressChooser: false
                                   })}
                                   style={{
                                       display: "inline-block",
                                       // top: 13,
                                       // left: 42,
                                       color: "#000000",
                                       // position: "absolute",
                                       fontSize: 18,
                                       marginLeft: 20,
                                       // backgroundColor: "transparent",
                                       appearance: "none",
                                       outline: "none"
                                   }} type="text" placeholder="请选择到达城市"/>
                            <img src={IMG_ICON_ARROW_DOWN_GRAY}
                                 style={{position: "absolute", width: 12, top: 25, marginTop: -2, right: 105}}
                                 onClick={(e) => this.setState({
                                     showReceiverAddressChooser: !this.state.showReceiverAddressChooser,
                                     showSenderAddressChooser: false
                                 })}/>
                            <div style={{
                                width: 1200,
                                marginLeft: "auto",
                                marginRight: "auto",
                                position: "relative",
                                top: 20
                            }}>
                                {this.state.showReceiverAddressChooser ?
                                    <AddressChooser keyWord={this.state.searchValueReceive}
                                                    handleData={(data) => {
                                                        this.setState({
                                                            receiveAddress: data,
                                                            showReceiverAddressChooser: false,
                                                            searchValueReceive: data.name
                                                        }, () => {
                                                            console.log(this.state.showReceiverAddressChooser)
                                                        });
                                                    }} style={{
                                        position: "absolute",
                                        zIndex: 99,
                                        top: -20,
                                        left: 197,
                                        color: "black"
                                    }}/> : null
                                }
                            </div>
                        </div>
                        <div style={{
                            width: 700,
                            marginTop: 20,
                            position: "relative",
                            cursor: "pointer"
                        }}>
                            <label style={{fontSize: 22, fontWeight: 800, fontFamily: "MicroSoft YaHei"}}>手机号</label>
                            <label style={{
                                fontSize: 18,
                                fontWeight: 800,
                                fontFamily: "MicroSoft YaHei",
                                color: "red"
                            }}> *</label>
                            <input className="valueReceive" value={this.state.phone} style={{
                                display: "inline-block",
                                color: "#000000",
                                fontSize: 18,
                                marginLeft: 20,
                                // backgroundColor: "transparent",
                                appearance: "none",
                                outline: "none"
                            }} type="text" placeholder="请输入手机号"
                                   onChange={(e) => this.setState({phone: e.target.value})}/>
                        </div>
                        <div style={{
                            width: 700,
                            marginTop: 20,
                            position: "relative",
                            // display: "inline-block"
                        }}>
                            <label style={{fontSize: 22, fontWeight: 800, fontFamily: "MicroSoft YaHei"}}>验证码</label>
                            <label style={{
                                fontSize: 18,
                                fontWeight: 800,
                                fontFamily: "MicroSoft YaHei",
                                color: "red"
                            }}> *</label>
                            <input className="yanzhengma" value={this.state.verifyCode} style={{
                                color: "#000000",
                                fontSize: 18,
                                marginLeft: 20,
                                // backgroundColor: "transparent",
                                appearance: "none",
                                outline: "none"
                            }} type="text" placeholder="请输入验证码"
                                   onChange={(e) => this.setState({verifyCode: e.target.value})}/>
                            <span style={{
                                display: 'inline-block',
                                marginLeft: 20,
                                fontSize: 18,
                                width: 130,
                                backgroundColor: this.state.verifyCodeFlag ? "#2EB2A6" : "gray",
                                color: "white",
                                height: 60,
                                lineHeight: "60px",
                                cursor: "pointer",
                            }} onClick={(e) => {
                                if (!this.state.verifyCodeFlag) {
                                    return;
                                }
                                if (!this.state.senderAddress.code || this.state.senderAddress.name !== this.state.searchValueSender) {
                                    message.error("请选择发车地!");
                                } else if (!this.state.receiveAddress.code || this.state.receiveAddress.name !== this.state.searchValueReceive) {
                                    message.error("请选择收车地!");
                                } else if (!this.state.phone) {
                                    message.error("请输入联系人!");
                                } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
                                    message.error("联系人请输入正确的手机号!");
                                } else {
                                    getVerifyCode(this.state.phone, (result, msg) => {
                                        if (result) {
                                            message.info("验证码已发送至您手机，请注意查收。")
                                            this.timer = setInterval(() => {
                                                if (this.state.verifyCodeCount === 0) {
                                                    if (this.state.verifyCodeFlag) {
                                                        this.setState({verifyCodeFlag: false, verifyCodeCount: 60});
                                                    } else {
                                                        this.setState({verifyCodeFlag: true, verifyCodeCount: 0});
                                                        clearInterval(this.timer)
                                                    }
                                                } else {
                                                    this.setState({
                                                        verifyCodeFlag: false,
                                                        verifyCodeCount: this.state.verifyCodeCount - 1
                                                    });
                                                }
                                            }, 1000)
                                        } else {
                                            message.info(msg);
                                        }
                                    })
                                }
                            }}>获取验证码{!this.state.verifyCodeFlag ? `(${this.state.verifyCodeCount})` : ""}
                        </span>
                        </div>
                        <div style={{
                            width: 410,
                            marginTop: 20,
                            marginLeft: 195,
                            fontSize: 24,
                            backgroundColor: "#25A8A0",
                            color: "white",
                            height: 60,
                            lineHeight: "60px",
                            cursor: "pointer",
                            borderRadius: 5,
                        }} onClick={(e) => {
                            if (!this.state.senderAddress.code || this.state.senderAddress.name !== this.state.searchValueSender) {
                                message.error("请选择发车地!");
                            } else if (!this.state.receiveAddress.code || this.state.receiveAddress.name !== this.state.searchValueReceive) {
                                message.error("请选择收车地!");
                            } else if (!this.state.phone) {
                                message.error("请输入联系人!");
                            } else if (!(/^1[3456789]\d{9}$/.test(this.state.phone))) {
                                message.error("联系人请输入正确的手机号!");
                            } else if (!this.state.verifyCode) {
                                message.error("请输入验证码");
                            } else {
                                place_order_withVerifyCode(this.state.phone, this.state.verifyCode,
                                    this.state.senderAddress.provinceName,
                                    this.state.senderAddress.provinceCode,
                                    this.state.senderAddress.name,
                                    this.state.senderAddress.code,
                                    this.state.receiveAddress.provinceName,
                                    this.state.receiveAddress.provinceCode, this.state.receiveAddress.name,
                                    this.state.receiveAddress.code, this.state.phone, (result, msg) => {
                                        if (result) {
                                            // message.info("请保持手机畅通，客服人员马上给您报价。")
                                            let leftData = this.state.realTimeOrderLeftData;
                                            let rightData = this.state.realTimeOrderRightData;
                                            // this.setState(PlaceOrder.STATE_STRUCT());
                                            this.setState({
                                                realTimeOrderLeftData: leftData,
                                                realTimeOrderRightData: rightData,
                                                senderAddress: {},
                                                receiveAddress: {},
                                                verifyCode: '',
                                                phone: '',
                                                showModal: true,
                                                searchValueSender: '',
                                                searchValueReceive: '',
                                                verifyCodeFlag: true,
                                                verifyCodeCount: 0,
                                            });
                                            clearInterval(this.timer)
                                        }
                                    });
                            }
                        }}>一键获取方案
                        </div>
                    </div>
                    <div style={{width: 700, overFlow: "hidden", float: "right"}}>
                        <div style={{width: 700, height: 60}}>
                            <div style={{width: 500, height: 27, position: "relative"}}>
                                <div style={{
                                    height: 27,
                                    width: 103,
                                    backgroundImage: `url("${NINE}")`,
                                    color: "white",
                                    float: "left"
                                }}>实时报价信息
                                </div>
                                <div style={{
                                    height: 21,
                                    color: "#4D4D4D",
                                    overflow: "hidden",
                                    fontSize: 16,
                                    fontWeight: 400,
                                    float: "left",
                                    position: "absolute",
                                    top: 3,
                                    left: 110,
                                    // display: this.state.realTimeOrderLeftData && this.state.realTimeOrderLeftData.length !== 0 ? "inline-block" : "none"
                                }}>
                                    <div className="transition"
                                         style={{marginTop: -(this.state.realTimeOrderLeftIndex * 21)}}/>
                                    {
                                        this.createOrder(this.state.realTimeOrderLeftData)
                                    }
                                </div>
                            </div>
                            <div style={{width: 500, height: 27, marginTop: 6, position: "relative"}}>
                                <div style={{
                                    height: 27,
                                    width: 103,
                                    backgroundImage: `url("${NINE}")`,
                                    color: "white",
                                }}>实时报价信息
                                </div>
                                <div style={{
                                    height: 21,
                                    color: "#4D4D4D",
                                    overflow: "hidden",
                                    fontSize: 16,
                                    fontWeight: 400,
                                    float: "left",
                                    position: "absolute",
                                    top: 3,
                                    left: 110,
                                    // display: this.state.realTimeOrderRightData && this.state.realTimeOrderRightData.length !== 0 ? "inline-block" : "none"
                                }}>
                                    <div className="transition"
                                         style={{marginTop: -(this.state.realTimeOrderRightIndex * 21)}}/>
                                    {
                                        this.createOrder(this.state.realTimeOrderRightData)
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{width: 700, marginTop: 15}}>
                            <div style={{width: 521, height: 306, backgroundImage: `url("${BACKGROUND3}")`}}>
                                <div style={{height: 70, backgroundColor: "#FF8002", position: "relative"}}>
                                    <div style={{
                                        width: 30,
                                        height: 30,
                                        backgroundImage: `url("${PHONEICON}")`,
                                        position: "absolute",
                                        left: 190,
                                        top: 20,
                                    }}></div>
                                    <div style={{
                                        width: 104,
                                        height: 30,
                                        fontSize: 24,
                                        fontWeight: 400,
                                        position: "absolute",
                                        left: 228,
                                        top: 20,
                                        color: "white",
                                    }}>客服热线
                                    </div>
                                </div>
                                <div style={{height: 236, position: "relative"}}>
                                    <div style={{
                                        position: "absolute",
                                        left: 65,
                                        top: 45,
                                        fontSize: 20,
                                        fontWeight: 400,
                                        color: "white",
                                        height: 20,
                                    }}>客服在线时间：08:00 - 22:00
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        left: 65,
                                        top: 90,
                                        fontSize: 20,
                                        fontWeight: 400,
                                        color: "white",
                                    }}>全国服务热线：
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        left: 205,
                                        top: 85,
                                        fontSize: 28,
                                        fontWeight: 400,
                                        color: "#FF8002",
                                    }}>400-603-8656
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        left: 186,
                                        bottom: 40,
                                        fontSize: 24,
                                        fontWeight: 400,
                                        color: "white",
                                        width: 150, height: 40,
                                        backgroundColor: "#25A8A0",
                                        borderRadius: 5,
                                        boxShadow: "0 1 16 0 rgba(0,0,0,0.08)",
                                        cursor: "pointer",
                                    }}
                                         onClick={(e) => window.open("http://p.qiao.baidu.com/cps/chat?siteId=15167097&userId=29640200&siteToken=294c619cc592ae15bda78083498283b9", "在线咨询", "width=800, height=600")}>立即咨询
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    width: 1000, height: 50, marginLeft: "auto", marginRight: "auto", marginTop: 80, fontSize: 16,
                    fontFamily: "Microsoft YaHei", textAlign: "left",
                }}>
                    公司秉承着最专业、智能、高效、严谨、高客户体验的服务理念，严格执行我们的专业服务流程：下单、签合同、验车、提车、运输、交车、付款、评价反馈。
                    每个环节做到标准、专业、细致、可靠。
                </div>
                <div style={{
                    width: 1300,
                    height: 110,
                    marginTop: 60,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${ONE}")`,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${TWO}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${THREE}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${FOUR}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${EIGHT}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${SEVEN}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${SIX}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                    <div style={{
                        width: 9,
                        height: 110,
                        float: "left",
                        marginLeft: 18,
                    }}>
                        <div style={{
                            width: 9,
                            height: 17,
                            backgroundImage: `url("${ARROW}")`,
                            marginTop: 45,
                        }}></div>
                    </div>
                    <div style={{
                        width: 110,
                        height: 110,
                        backgroundImage: `url("${FIVE}")`,
                        marginLeft: 20,
                        float: "left"
                    }}></div>
                </div>
            </div>
            <div style={{marginLeft: "auto", marginRight: "auto", width: "100%"}}>
                <ParticleFoot></ParticleFoot>
            </div>
            {this.state.showModal ?
                <div style={{
                    margin: "0 auto",
                    position: "fixed",
                    backgroundColor: "white",
                    borderRadius: 5,
                    width: 395,
                    zIndex: 101,
                    height: 225,
                    left: 0,
                    right: 0,
                    top: "50%",
                    marginTop: -112.5
                }}>
                            <span style={{
                                color: "#181A1A",
                                marginTop: 21,
                                display: "block",
                                textAlign: "center",
                                fontSize: 20
                            }}>提示</span>
                    <div style={{width: "100%", height: 1, background: "rgba(238,238,238,1)", marginTop: 21}}/>
                    <span style={{
                        color: "#181A1A",
                        fontSize: 18,
                        display: "block",
                        marginTop: 41,
                        textAlign: "center"
                    }}>请保持手机畅通，客服人员马上给您报价。</span>
                    <span onClick={(e) => this.setState({showModal: false})} style={{
                        color: "white",
                        fontSize: 16,
                        width: 116,
                        display: "block",
                        margin: "0 auto",
                        marginTop: 35,
                        backgroundColor: "#25A8A0",
                        borderRadius: 24,
                        textAlign: "center",
                        paddingTop: 8,
                        paddingBottom: 8,
                        cursor: "pointer"
                    }}>确定</span>
                </div> : null
            }
        </div>
    }

    createOrder = (data) => {
        if (data && data.length !== 0) {
            return data.map((item) => {
                return (<div className="transition"
                             style={{
                                 lineHeight: "21px",
                                 textAlign: 'left',
                                 width: 600,
                             }}>始发城市:&nbsp;{item.originCityName}&nbsp;&nbsp;&nbsp;&nbsp;到达城市:&nbsp;{item.deliverCityName}&nbsp;&nbsp;&nbsp;&nbsp;时间:&nbsp;{item.time} </div>)
            })
        } else
            return (<div/>)
    };
    getRandomTime = () => {
        let number = this.getRandomNumberByRange(1, 10);
        return number <= 5 ? this.getRandomNumberByRange(1, 23) + "小时前" : this.getRandomNumberByRange(1, 59) + "分钟前";
    };

    getRandomNumberByRange = (start, end) => {
        return Math.floor(Math.random() * (end - start) + start)
    };
};

export default PlaceOrder