import {api, getData} from "../request";


export function get_front_page_news_2(url, callBack) {
    getData(url, {index: 0, size: 1000}).then(res => {
        if (res.code === 200) {
            let datas = res.data.data;
            let objs = []
            datas.forEach(data => {
                let obj = {};
                obj.title = data.title;
                obj.desc = data.desc;
                obj.date = data.date;
                obj.image = data.cover;
                obj.content = data.content;
                obj.pageTitle = data.pageTitle;
                obj.meta = data.meta;
                objs.push(obj);
            })
            callBack(true, "获取成功", objs);
        } else {
            callBack(false,)
        }
    });
}

export function get_front_page_news(callBack) {
    getData(api.news_list, {index: 0, size: 1000}).then(res => {
        if (res.code === 200) {
            let datas = res.data.data;
            datas = datas.slice(0, 4);
            let objs = []
            datas.forEach(data => {
                let obj = {};
                obj.title = data.title;
                obj.desc = data.desc;
                obj.date = data.date;
                obj.image = data.cover;
                obj.pageTitle = data.pageTitle;
                obj.meta = data.meta;
                objs.push(obj);
            })
            callBack(true, "获取成功", objs);
        } else {
            callBack(false,)
        }
    });
    // callBack(true, "获取成功",
    //     [
    //         {
    //             image: 'https://car1.autoimg.cn/upload/spec/13452/1024x0_1_q95_autohomecar__20120723095341589264.jpg',
    //             date: '2020-07-16',
    //             title: '新闻1',
    //             desc: '内容1',
    //         },
    //         {
    //             image: 'https://car1.autoimg.cn/upload/spec/13452/1024x0_1_q95_autohomecar__20120723095341589264.jpg',
    //             date: '2020-07-15',
    //             title: '新闻2',
    //             desc: '内容2',
    //         },
    //         {
    //             image: 'https://car1.autoimg.cn/upload/spec/13452/1024x0_1_q95_autohomecar__20120723095341589264.jpg',
    //             date: '2020-07-14',
    //             title: '新闻3',
    //             desc: '内容3',
    //         },
    //         {
    //             image: 'https://car1.autoimg.cn/upload/spec/13452/1024x0_1_q95_autohomecar__20120723095341589264.jpg',
    //             date: '2020-07-13',
    //             title: '新闻4',
    //             desc: '内容4',
    //         },
    //     ]
    // );
}
